import { Timestamp, serverTimestamp } from 'firebase/firestore';
import { LabelsetService } from './labelset.service';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DatasetEntity } from 'src/app/models/dataset.model';
import { StudyEntity, StudyEntityEx } from '../models/study.model';

@Injectable({
  providedIn: 'root'
})
export class StudyService {

  constructor(
    private firestore: AngularFirestore,
    private labelsetService: LabelsetService
  ) {
  }

  getCustomerStudiesPath(cid: string): string {
    return `Customers/${cid}/Studies`;
  }

  async createStudy(
    cid: string, dataset: DatasetEntity,
    userId: string,
    title: string, description: string,
    classes: string[], isMultilabel: boolean
  ): Promise<string> {

    const study: StudyEntity = {
      title: title,
      description: description,
      datetime: <Timestamp>serverTimestamp(),
      status: "active",
      user_id: userId,
      type: "",
      dataset: this.firestore.doc(`Customers/${cid}/Datasets/${dataset.id}`).ref
    };

    if (classes) {
      this.validateClasses(classes);
      study.classes = classes;
      study.is_multilabel = isMultilabel;

      const labelsetId = await this.labelsetService.createLabelset(cid, dataset, classes, userId);
      study.labelset = this.firestore.doc(`Customers/${cid}/Labelsets/${labelsetId}`).ref;
    }

    return new Promise<string>((resolve, reject) => {
      this.firestore.collection(this.getCustomerStudiesPath(cid))
        .add(study)
        .then(docRef => {
          const studyId = docRef.id;
          console.log(`Created study ${studyId}`);
          resolve(studyId);
        }, err => reject(err));
    });
  }

  async modifyStudy(cid: string, studyId: string, studyEntity: StudyEntity): Promise<void> {
    if (studyEntity.classes) {
      this.validateClasses(studyEntity.classes);
      // TODO: update labelset classes?
    }
    const doc = this.firestore.doc(`Customers/${cid}/Studies/${studyId}`);
    return doc.update(studyEntity);
  }

  async changeStudyStatus(cid: string, studyId: string, newStatus: string) :Promise<void> {
    const doc = this.firestore.doc<StudyEntity>(`Customers/${cid}/Studies/${studyId}`);
    return doc.update({ status: newStatus });
  }

  getStudyObservable(cid: string, studyId: string): Observable<StudyEntity> {
    const doc = this.firestore.doc<StudyEntity>(`Customers/${cid}/Studies/${studyId}`);
    return doc.snapshotChanges().pipe(map(res => {
      const data = res.payload.data() as StudyEntity;
      const id = res.payload.id;
      return { id, ...data };
    }));
  }

  async getStudyOnce(cid: string, studyId: string): Promise<StudyEntity> {
    return new Promise((resolve) => {
      const subscription = this.getStudyObservable(cid, studyId).subscribe((studyEntity) => {
        subscription.unsubscribe();
        resolve(studyEntity);
      });
    });
  }

  getAllStudies(cid: string, type: string): Observable<StudyEntity[]> {
    console.log('Studies service: getting all studies cid: ', cid, 'type:', type);
    const studiesPath = this.getCustomerStudiesPath(cid);
    const results = this.firestore.collection<StudyEntity>(studiesPath, ref => ref.orderBy('datetime', 'desc'))
      .snapshotChanges()
      .pipe(map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as StudyEntity;
          const id = a.payload.doc.id;
          return {id, ...data};
        }).filter(data => {
          return data.status != 'deleted' &&
            (type === '*' || data.type.toLowerCase() === type.toLowerCase());
        });
      }));
    return results;
  }

  private validateClasses(classes: string[]) {
    for (let i = 0; i < classes.length; i++) {
      const c = classes[i];
      if (!c || c !== c.trim()) throw new Error(`Invalid class: [${c}]`);
    }
  }

  async isStudyModelReady(cid: string, studyId: string): Promise<boolean> {
    const collection = this.firestore.collection(`Customers/${cid}/Studies/${studyId}/Models`,
      ref => ref.where('status', '==', 'model_ready').limit(1));
    const snapshot = await collection.get().toPromise();
    return snapshot.docs.length > 0;
  }

  async deleteStudy(cid: string, studyId: string, userId: string): Promise<void> {
    console.log(`StudyService: DELETE study ${studyId} cid: ${cid} by user: ${userId}`);

    const studiesPath = this.getCustomerStudiesPath(cid);
    const doc = this.firestore.collection(studiesPath).doc(studyId);

    return doc.update({ status: 'deleted' });
  }

  getStudyInfo(studyId: string): StudyEntityEx {
    if (studyId !== 'study1') {
      return {};
    }
    return {
      id: studyId,
      name: 'Movie Genre Classification',
      description: 'This study aims to solve the movie genre classification problem.',
      owner: 'Company A',
      creator: 'Rakesh Yadav',
      creationDate: 'August 15, 2021',
      collaborators: {
        labellers: ["Rakesh", "Naoki", "Olivier"],
        modelDevelopers: ["Rakesh", "Naoki", "Olivier"],
        production: ["Brian"]
      },
      models: [
        {
          name: "DNN with GMU",
          creator: "Mizuki",
          description: "Attempt to solve the problem with a DNN",
          latestCreationDate: 'August 15, 2021',
          tags: ["PROD", "DNN", "TENSORFLOW"],
        },
        {
          name: "XG Boost Model",
          creator: "Mizuki",
          description: "Attempt to solve the problem with a DNN",
          latestCreationDate: 'August 15, 2021',
          tags: ["PROD", "DNN", "TENSORFLOW"],
        }
      ],
      datasets: [
        {
          name: "DNN with GMU",
          creator: "Mizuki",
          description: "Attempt to solve the problem with a DNN",
          latestCreationDate: 'August 15, 2021',
          tags: ["PROD", "DNN", "TENSORFLOW"],
        },
        {
          name: "XG Boost Model",
          creator: "Mizuki",
          description: "Attempt to solve the problem with a DNN",
          latestCreationDate: 'August 15, 2021',
          tags: ["PROD", "DNN", "TENSORFLOW"],
        }
      ],
      evaluationMetrics: {
        metrics: [
          {
            metricName: "ROC AUC",
            sliceKey: "Genre: Action",
            value: "0.91",
            latestCreationDate: 'August 15, 2021',
            tags: ["Images", "Text", "TENSORFLOW"],
          }
        ],
        confusionMatrix: ["A"],
        rocCurve: ["A"]
      },
      servings: [
        {
          servingModel: "ROC AUC",
          servingVersion: "Genre: Action",
          timePushed: 'August 15, 2021',
          previousVersion: "Genre: Action",
          monitoring: "Images",
        }
      ],
      trainingAlerts: [
        {
          errorCode: "Resources Exhausted",
          errorMessage: "Out of GPU quota falling back",
          errorLevel: "Warn",
        }
      ],
      activeServingAlerts: [
        {
          studyName: "Resources Exhausted",
          modelName: "Out of GPU quota falling back",
          errorCode: "Warn",
          errorMessage: "Test",
        }
      ],
      activeTrainingAlerts: [
        {
          studyName: "Resources Exhausted",
          modelName: "Out of GPU quota falling back",
          errorCode: "Resources Exhausted",
          errorLevel: "Warn",
          errorMessage: "Out of GPU quota falling back",
        }
      ],
      servingMonitoringAlerts: [
        {
          servingModel: "Resources Exhausted",
          servingVersion: "Out of GPU quota falling back",
          timePushed: "Resources Exhausted",
          previousServingVersion: "Warn",
        }
      ],
      trainingPipelineMonitoring: [
        {
          modelName: "DNN with GMU",
          currentTrainingVersion: "Version 56",
          currentStage: "MODEL_VALUATION",
          pipeline: "Kubeflow Pipeline",
        }
      ],
    };
  }
}
