import { ComponentRef, Injectable, ViewContainerRef } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Subscription, fromEvent } from 'rxjs';

import { CustomerEntity } from '../models/customer.model';
import { UserEntity } from '../models/user.model';
import { MyGraphConfig } from 'src/app/shared/mygraph/mygraph.component';
import { TableGraphConfig } from '../shared/table-graph/table-graph.component';
import { GraphSettings } from '../util/GraphSettings';
import { GenericGraphComponent, GraphConfig, InputParams } from '../shared/generic-graph/generic-graph.component';
import { getIfDefined, scrollToItemAnimation, updateState } from '../util/helpers';
import { UserService } from './user.service';
import { CustomerService } from './customer.service';
import { CustomerSettingService } from './customer-setting.service';
import { UserPreferencesService } from './user-preferences.service';
import { FormService } from './form.service';
import { DeeplinkService } from './deeplink.service';
import { BlockComponent } from '../shared/block/block.component';

const COMMON_OPTIONS_ID = '(common)';

export interface CreateGraphOptions {
  size?: 'small'|'regular';
  extraClass?: string; // style class to be added to component
  hiddenByDefault?: boolean; // if given this overrides the setting for showing/hiding this graph
  pinnedByDefault?: boolean; // if given this overrides the setting for showing/hiding graphs pinned to dashboard
  favoritedByDefault?: boolean; // Show graphs favorited by user and visibile only to the user & not the entire organization
  section?: string; // if given, section to which component is added
  container?: ViewContainerRef; // if section is not given you must give a container directly
  index?: number; // private use
  indexInSection?: number; // private use
}

interface CreateSectionOptions {
  container: ViewContainerRef;
}

interface StartPageOptions {
  sidenav?: MatSidenav;
  scrollToItemId?: string;
}

interface PageSection {
  title: string;
  container: ViewContainerRef;
  itemIds: string[]; // same ID should be found in allItems
}

interface PageItem {
  type: 'graph';
  componentRef: ComponentRef<GenericGraphComponent>;
  container: ViewContainerRef;
  section: string;
}

interface VisibleSection {
  title: string;
  graphConfigs: GraphConfig[];
}


@Injectable({
  providedIn: 'root'
})
export class PageService {

  private isReady = false;

  private user: UserEntity;
  private customer: CustomerEntity;
  private userSettings: any;
  private customerSettings: any;
  private commonOptions: any;

  private pageId: string;
  private sidenav: MatSidenav;
  private resizeSubscription: Subscription = null;
  private isSidenavOpen: boolean;

  private allItems: { [key: string]: PageItem; } = {}; // the items we created
  private allSections: { [key: string]: PageSection; } = {};
  private inputParams: InputParams = {};
  private cachedAllGraphs: GraphConfig[];
  private cachedVisibleSections: VisibleSection[];


  constructor(
    private deeplinkService: DeeplinkService,
    private userService: UserService,
    private customerService: CustomerService,
    private customerSettingService: CustomerSettingService,
    private userPrefService: UserPreferencesService,
    private formService: FormService,
  ) {
    this.userService.observeUser().subscribe((user) => this.onUserChange(user));
    this.deeplinkService.observeDynamicCid().subscribe((/*cid*/) => this.onUserChange(this.user));
  }

  async onUserChange(user: UserEntity) {
    this.isReady = false;
    this.user = user;
    if (!user) {
      return;
    }
    this.userService.dynamicRemap(user);
    this.customer = await this.customerService.getCustomer(user.cid);
    this.userSettings = await this.userPrefService.getSettings(user.id, 'dashboard');
    this.customerSettings = await this.customerSettingService.getSettings(user.cid, 'dashboard');
    this.commonOptions = this.customerSettings[COMMON_OPTIONS_ID] || {};
    this.isReady = true;
  }

  public async untilReady(): Promise<void> {
    if (this.isReady) return undefined;
    return new Promise(resolve => {
      const interval = setInterval(() => {
        if (this.isReady) {
          clearInterval(interval);
          resolve();
        }
      }, 300);
    });
  }

  public async startPage(pageId: string, options?: StartPageOptions): Promise<void> {
    if (this.resizeSubscription) {
      console.error('onDestroyPage must be called');
      this.resizeSubscription.unsubscribe(); // just to be nice
    }

    this.allItems = {}; // TODO: test if we don't leak DOM/etc
    this.allSections = {};
    this.inputParams = {};
    this.clearGraphListCache();

    this.pageId = pageId;
    this.sidenav = options?.sidenav;
    this.isSidenavOpen = true;
    this.deeplinkService.onPopState('*pageService', params => {
      if (params.itemId) {
        this.scrollBackToItem(params.itemId);
      }
    });

    await this.untilReady(); // we need various data members on this to be isReady

    if (this.sidenav) {
      setTimeout(() => this.autoOpenOrCloseDrawer());

      this.resizeSubscription = fromEvent(window, 'resize').subscribe(() => {
        this.autoOpenOrCloseDrawer();
      });
    }

    if (options?.scrollToItemId) {
      setTimeout(() => scrollToItemAnimation(options.scrollToItemId), 300);
    }
  }

  public onDestroyPage() {
    if (this.resizeSubscription) {
      this.resizeSubscription.unsubscribe();
      this.resizeSubscription = null;
    }
    this.deeplinkService.unregisterPopState('*pageService');
  }

  private autoOpenOrCloseDrawer() {
    if (!this.sidenav) return; // drawer was not created (e.g. placeholder displayed)
    const shouldOpen = window.innerWidth >= 1100;
    if (!this.isSidenavOpen) {
      if (shouldOpen) {
        this.isSidenavOpen = true;
        this.sidenav.open();
      }
    } else {
      if (!shouldOpen) {
        this.isSidenavOpen = false;
        this.sidenav.close();
      }
    }
  }

  private clearGraphListCache() {
    this.cachedVisibleSections = null;
    this.cachedAllGraphs = null;
  }


  public updateFilter(changes: InputParams) {
    const actualChanges = updateState(this.inputParams, changes);
    if (!actualChanges) return;

    this.iterateGraphConfigs((graphConfig) => {
      if (!graphConfig.settings.hidden) {
        graphConfig.settings.forceRefreshGraph();
      }
    });
  }

  // NB: we should not use this to modify inputParams; see for example updateFilter for that purpose
  public currentInputParams() {
    return this.inputParams;
  }

  /**
   * A section is a group of graphs (or other UI elements).
   * Section names will appear in the left side index (in the order of their creation).
   */
  public createSection(title: string, options: CreateSectionOptions) {
    if (!options.container) {
      console.error(`createSection(${title}) missing container`);
      return;
    }
    if (this.allSections[title]) {
      console.error(`dupe section "${title}"`);
      return;
    }
    const componentRef = options.container.createComponent(BlockComponent);

    this.allSections[title] = { title, container: componentRef.instance.vcRef, itemIds: [] };
  }

  /** Returns options defined at customer-level (all users for current CID) */
  public getCommonOptions(): any {
    return this.commonOptions;
  }

  public get visibleSections(): VisibleSection[] {
    if (this.cachedVisibleSections) {
      return this.cachedVisibleSections;
    }
    const results = [];
    for (const title in this.allSections) {
      const pageSection = this.allSections[title];
      let visibleSection = null;
      for (const itemId of pageSection.itemIds) {
        const item = this.allItems[itemId];
        if (item.type !== 'graph') continue;
        const graphConfig = item.componentRef.instance.config;
        if (graphConfig.settings.hidden) continue;
        if (!visibleSection) {
          visibleSection = { title, graphConfigs: [] };
          results.push(visibleSection);
        }
        visibleSection.graphConfigs.push(graphConfig);
      }
    }
    this.cachedVisibleSections = results;
    return this.cachedVisibleSections;
  }

  public forceRefreshIndex() {
    if (!this.cachedVisibleSections) return;
    this.cachedVisibleSections = this.cachedVisibleSections.concat();
  }

  public hideBrokenGraphFromIndex(graphId: string) {
    if (!this.cachedVisibleSections) return;
    const sections = this.cachedVisibleSections.concat();
    for (const visibleSection of sections) {
      const graphConfigs = visibleSection.graphConfigs;
      for (let i = 0; i < graphConfigs.length; i++) {
        if (graphConfigs[i].id === graphId) {
          graphConfigs.splice(i, 1);
          this.cachedVisibleSections = sections;
          return;
        }
      }
    }
  }

  public get allGraphs(): GraphConfig[]{
    if (this.cachedAllGraphs) {
      return this.cachedAllGraphs;
    }
    const results = [];
    for (const title in this.allSections) {
      const pageSection = this.allSections[title];
      for (const itemId of pageSection.itemIds) {
        const item = this.allItems[itemId];
        if (item.type !== 'graph') continue;
        const graphConfig = item.componentRef.instance.config;
        results.push(graphConfig);
      }
    }
    this.cachedAllGraphs = results;
    return this.cachedAllGraphs;
  }


  public createMyGraph(config: MyGraphConfig, options: CreateGraphOptions) {
    this.createGenericGraph(config, options);
  }

  public createTableGraph(config: TableGraphConfig, options: CreateGraphOptions) {
    this.createGenericGraph(config, options);
  }
  
  public createGenericGraph(config: GraphConfig, options: CreateGraphOptions) {
    if (!config) {
      console.error(`Missing config for graph`);
      return;
    }
    const pageSection = options.section ? this.allSections[options.section] : null;
    if (!options.section && !options.container) {
      console.error(`section or container must be given for ${config.title}`);
      return;
    } else if (options.section && !pageSection) {
      console.error(`invalid section ${options.section} for ${config.title}`);
      return;
    }
    if (!this.pageId) {
      console.error(`You must call startPage before creating graph ${config.title}`);
      return;
    }
    const id = config.id;
    if (this.allItems[id]) {
      console.error('Duplicate ID: ' + id);
      return;
    }

    const container = pageSection ? pageSection.container : options.container;
    const pageItem = this.allItems[id] = { type: 'graph', componentRef: null, container, section: options.section };
    if (pageSection) {
      if (options.indexInSection !== undefined) {
        pageSection.itemIds.splice(options.indexInSection, 0, id);
      } else {
        pageSection.itemIds.push(id);
      }
    }

    const componentRef = pageItem.componentRef = container.createComponent(GenericGraphComponent, { index: options.index });
    componentRef.location.nativeElement.id = id;

    if (options.extraClass) {
      componentRef.location.nativeElement.className = options.extraClass;
    }

    // work on a shallow-copy of config (at least for title and subtitle)
    const baseConfig = config;
    config = {...config};

    config.size = options.size || config.size || 'regular';

    if (!config.settings) {
      config.settings = new GraphSettings(
        config, 
        this.userSettings[id], this.customerSettings[id], 
        this.user, this.customerSettingService, this.userPrefService,
      );
    }
    config.settings.setForceRefresh(() => {
      // TODO: instead of 2 lines below, stop modifying title & subtitle in GenericGraph
      config.title = baseConfig.title;
      config.subtitle = baseConfig.subtitle;

      const index = container.indexOf(componentRef.hostView);
      const indexInSection = pageSection ? pageSection.itemIds.indexOf(id) : undefined;
      this.destroyItem(id);
      this.createGenericGraph(config, { index, indexInSection, ...options });
    });

    if (options.hiddenByDefault !== undefined) {
      config.settings.overrideDefault('hidden', options.hiddenByDefault);
    }
    if (options.pinnedByDefault !== undefined) {
      config.settings.overrideDefault('hidden', !config.settings.pinned);
    }
    if (options.favoritedByDefault !== undefined) {
      config.settings.overrideDefault('hidden', options.hiddenByDefault);
    }

    const component = componentRef.instance;
    component.config = config;
    component.user = this.user;
    component.inputParams = this.inputParams;
    
    this.applyGlobalOptions(config);

    this.clearGraphListCache();

    componentRef.changeDetectorRef.detectChanges();
  }


  private applyGlobalOptions(config: GraphConfig): boolean {
    // Add here any new options that can be applied globally to graphs
    return this.applyLogScale(config);
  }

  private applyLogScale(config: GraphConfig): boolean {
    if (config['yIsLog'] === undefined) return false; // not a log scale graph

    const isLogScale =
      getIfDefined(config.settings.useLogScale,
        getIfDefined(this.commonOptions.logScaleAllowed,
          true));

    return this.applyOption(config, 'yIsLog', isLogScale);
  }

  private applyOption(config: GraphConfig, optionName: string, value: any, defValue?: boolean): boolean {
    if (value === undefined) value = defValue;
    if (config[optionName] === value) {
      return false; // unchanged
    }
    config[optionName] = value;
    return true; // modified
  }

  private devHintForContainer(container: ViewContainerRef): string {
    const hostNode = container['_hostTNode'];
    if (hostNode?.localNames && hostNode.localNames[0]) {
      return hostNode.localNames[0];
    } else if (container.element?.nativeElement?.data) {
      return container.element.nativeElement.data;
    } else {
      return 'container';
    }
  }

  public scrollToItem(itemId: string) {
    scrollToItemAnimation(itemId);
  }

  private scrollBackToItem(itemId: string) {
    const element = document.getElementById(itemId);
    if (!element) return; // not found; just do nothing
    element.scrollIntoView();
  }

  public destroyItem(id: string) {
    const item = this.allItems[id];
    if (!item) return; // can safely ignore (e.g. not created yet)
    const { componentRef, container, section } = item;
    container.detach(container.indexOf(componentRef.hostView));
    this.allItems[id] = null;

    // Remove item from its section
    const pageSection = this.allSections[section];
    if (pageSection) {
      const pos = pageSection.itemIds.indexOf(id);
      if (pos !== -1) {
        pageSection.itemIds.splice(pos, 1);
      }
    }
  }

  public destroyItems() {
    for (const key in this.allItems) {
      this.destroyItem(key);
    }
  }

  public hideItem(id: string) {
    const item = this.allItems[id];
    if (!item) return; // can safely ignore (e.g. not created yet)
    item.componentRef.instance.isHidden = true;
  }

  public showItem(id: string) {
    const item = this.allItems[id];
    if (!item) {
      console.error('showItem invalid ID: ' + id);
      return;
    }
    item.componentRef.instance.isHidden = false;
  }

  public async showOptionForm() {
    const logScaleAllowed = this.commonOptions.logScaleAllowed === undefined || this.commonOptions.logScaleAllowed;

    const options = await this.formService.showForm(`Options Common to All in ${this.customer.name}`, [
      { name: 'logScaleAllowed', label: 'Use log scale for applicable graphs', type: 'boolean', init: logScaleAllowed },
      { name: 'perCapita', label: 'Use "per capita" stats', type: 'boolean', init: this.commonOptions.perCapita }
    ]);
    if (options) {
      const changes = updateState(this.commonOptions, options);
      if (!changes) return;

      this.customerSettingService.modifySettings(this.user.cid, 'dashboard', COMMON_OPTIONS_ID, changes);
  
      this.iterateGraphConfigs(graphConfig => this.applyGlobalOptions(graphConfig));  
    }
  }

  private iterateGraphConfigs(fn: (config: GraphConfig)=>any) {
    for (const itemId in this.allItems) {
      const pageItem = this.allItems[itemId];
      if (pageItem && pageItem.componentRef) {
        const graphConfig = pageItem.componentRef.instance.config;
        fn(graphConfig);
      }
    }
  }

}
