  <div *ngIf="!loading && parent?.placeholderText && !parent?.placeholderIsError" [class]="parent?.graphPlaceholderClass">
    <div class="text" [innerHTML]="parent.placeholderText"></div>
  </div>
  <div [hidden]="loading || !dataSource">
    <div class='frame'>
      <div class="graphTitle">
        <div *ngIf="config.title" class="title">{{config.title}} <div class="seperater" *ngIf="inputParams.startDate">|</div> <div class="date" *ngIf="inputParams.startDate">{{(inputParams.startDate | date)}} - {{inputParams.endDate | date}}</div></div>
        <div *ngIf="config.subtitle" class="text-gray-200 text-left text-sm">{{config.subtitle}}</div>
      </div>
      <div class="table-controls">
        <div *ngIf="isWorking" class="spinner"> <mat-spinner diameter="50"></mat-spinner> </div>

        <button *ngIf="config.isPivotTable && this.expandedColumns.size > 0"
          mat-icon-button (click)="exportGroupedData()">
          <mat-icon>{{ 'save_alt' }}</mat-icon>
        </button>
        <button *ngIf="config.isPivotTable"
          mat-icon-button (click)="exportUngroupedData()">
          <mat-icon>{{ 'cloud_download' }}</mat-icon>
        </button>
  
        <mat-form-field *ngIf="config.searchable" class="tableFilter">
          <input autocomplete="off" matInput (keyup)="onFilterKeyup($event.target.value)" placeholder="Search">
        </mat-form-field>
        <button *ngIf="config.showHorizontalScrollControls" mat-icon-button aria-label="Scrolls table to left" (click)="onLeftScroll()">
          <mat-icon>chevron_left</mat-icon>
        </button>
        <button *ngIf="config.showHorizontalScrollControls" mat-icon-button aria-label="Scrolls table to right" (click)="onRightScroll()">
          <mat-icon>chevron_right</mat-icon>
        </button>
      </div>
    </div>

  <ng-template #pivotCellTemplate let-element="element" let-displayedCol="displayedCol">
    <span class="pivot-regular-cell" *ngIf="element[displayedCol.columnKey]">
      <p *ngIf="displayedCol.useNumberPipe && !displayedCol.useIntegerPipe && !displayedCol.useTwoDecimalPipe && !displayedCol.isUrl && element[displayedCol.columnKey] != 'NULL'">
        <span class="table-cell-data">
          {{ displayedCol.prefix ? displayedCol.prefix : "" }}{{(element[displayedCol.columnKey]) | number : '1.2-4' }}{{ displayedCol.suffix ? displayedCol.suffix : "" }}
        </span>
      </p>
      <p *ngIf="!displayedCol.useNumberPipe && !displayedCol.useIntegerPipe && displayedCol.useTwoDecimalPipe && !displayedCol.isUrl && element[displayedCol.columnKey] != 'NULL'">
        <span class="table-cell-data">
          {{ displayedCol.prefix ? displayedCol.prefix : "" }}{{(element[displayedCol.columnKey]) | number : '2.2-2' }}{{ displayedCol.suffix ? displayedCol.suffix : "" }}
        </span>
      </p>
      <p *ngIf="displayedCol.useIntegerPipe && !displayedCol.useNumberPipe && !displayedCol.useTwoDecimalPipe && !displayedCol.isUrl && element[displayedCol.columnKey] != 'NULL' && element[displayedCol.columnKey]">
        <span class="table-cell-data">
          {{ displayedCol.prefix ? displayedCol.prefix : "" }}{{(element[displayedCol.columnKey]) | number }}{{ displayedCol.suffix ? displayedCol.suffix : "" }}
        </span>
      </p>
      <p *ngIf="!displayedCol.useIntegerPipe && !displayedCol.useNumberPipe && !displayedCol.useTwoDecimalPipe && !displayedCol.isUrl && element[displayedCol.columnKey] != 'NULL' && element[displayedCol.columnKey]">
        <span class="table-cell-data">
          {{ displayedCol.prefix ? displayedCol.prefix : "" }}{{element[displayedCol.columnKey]}}{{ displayedCol.suffix ? displayedCol.suffix : "" }}
        </span>
      </p>
      <p *ngIf="displayedCol.isUrl && !displayedCol.openinNewTab">
        <a class="button textLink" (click)="onClickUrl(element[displayedCol.columnKey].url)">{{element[displayedCol.columnKey].value}}</a>
      </p>
      <p *ngIf="displayedCol.isUrl && displayedCol.openinNewTab">
        <a class="button" href="{{element[displayedCol.columnKey].url}}">{{element[displayedCol.columnKey].value}}</a>
      </p>
      <p *ngIf="!displayedCol.useNumberPipe && !displayedCol.isUrl && element[displayedCol.columnKey] == 'NULL'">
        {{ "N/A" }}
      </p>
    </span>
  </ng-template>

  <ng-template #regularCellTemplate let-element="element" let-displayedCol="displayedCol">
    <p *ngIf="!displayedCol.useNumberPipe && displayedCol.useIntegerPipe && !displayedCol.useTwoDecimalPipe && !displayedCol.isUrl && element[displayedCol.columnKey] != 'NULL' && element[displayedCol.columnKey]">
      <span class="table-cell-data">
        {{((displayedCol.prefix && element[displayedCol.columnKey]) ? displayedCol.prefix : "")}}{{(checkValue(element[displayedCol.columnKey]) == 'number') ? (element[displayedCol.columnKey] | number) : element[displayedCol.columnKey]}}{{ displayedCol.suffix ? displayedCol.suffix : "" }}
      </span>
    </p>
    <p *ngIf="!displayedCol.useNumberPipe && !displayedCol.useIntegerPipe && displayedCol.useTwoDecimalPipe && !displayedCol.isUrl && element[displayedCol.columnKey] != 'NULL'">
      <span class="table-cell-data">
        {{ (displayedCol.prefix && element[displayedCol.columnKey]) ? displayedCol.prefix : "" }}{{(element[displayedCol.columnKey]) | number : '1.2-2' }}{{ displayedCol.suffix ? displayedCol.suffix : "" }}
      </span>
    </p> 
     <p *ngIf="displayedCol.useNumberPipe && !displayedCol.useIntegerPipe && !displayedCol.useTwoDecimalPipe && !displayedCol.isUrl && element[displayedCol.columnKey] != 'NULL'">
      <span class="table-cell-data">
        {{ (displayedCol.prefix && element[displayedCol.columnKey]) ? displayedCol.prefix : "" }}{{(element[displayedCol.columnKey]) | number : '1.2-4' }}{{ displayedCol.suffix ? displayedCol.suffix : "" }}
      </span>
    </p>
    <p *ngIf="!displayedCol.useNumberPipe && !displayedCol.useIntegerPipe && !displayedCol.useTwoDecimalPipe && !displayedCol.isUrl && element[displayedCol.columnKey] != 'NULL' && element[displayedCol.columnKey] ">
    <span class="table-cell-data" [matTooltip]="element.toolTip ? element.toolTip : ''">
        {{((displayedCol.prefix && element[displayedCol.columnKey]) ? displayedCol.prefix : "")}}{{element[displayedCol.columnKey]}}{{ displayedCol.suffix ? displayedCol.suffix : "" }}
      </span>
    </p>
    <p *ngIf="displayedCol.isUrl && !displayedCol.openinNewTab && element[displayedCol.columnKey]">
      <a class="button textLink" (click)="onClickUrl(element[displayedCol.columnKey].url)">{{element[displayedCol.columnKey].value}}</a>
    </p>
    <p [class]="displayedCol.hasImage && 'containerWithImg'" *ngIf="displayedCol.isUrl && displayedCol.openinNewTab && element[displayedCol.columnKey]">
      <img *ngIf="displayedCol.hasImage" class="display-img" src="{{element[displayedCol.columnKey].image}}"/>
      <a class="button" href="{{element[displayedCol.columnKey].url}}" target="_blank">{{element[displayedCol.columnKey].value}}</a>
    </p>
    <p *ngIf="!displayedCol.useNumberPipe && !displayedCol.useTwoDecimalPipe && !displayedCol.isUrl && (element[displayedCol.columnKey] == 'NULL' || !element[displayedCol.columnKey]) ">
      {{ "N/A" }}
    </p>
  </ng-template>

  <ng-template #statusCellTemplate let-element="element" let-displayedCol="displayedCol">
    <p *ngIf="!displayedCol.useNumberPipe && displayedCol.useTwoDecimalPipe && !displayedCol.isUrl && element[displayedCol.columnKey] && element[displayedCol.columnKey].value != 'NULL'">
      <span [class]="element[displayedCol.columnKey].status ? element[displayedCol.columnKey].status : 'table-cell-data'">
        {{ (displayedCol.prefix && element[displayedCol.columnKey]) ? displayedCol.prefix : "" }}{{(element[displayedCol.columnKey].value) | number : '1.2-2' }}{{ displayedCol.suffix ? displayedCol.suffix : "" }}
      </span>
    </p>
    <p *ngIf="displayedCol.useNumberPipe && !displayedCol.useTwoDecimalPipe && !displayedCol.isUrl && element[displayedCol.columnKey] && element[displayedCol.columnKey].value != 'NULL'">
      <span [class]="element[displayedCol.columnKey].status ? element[displayedCol.columnKey].status : 'table-cell-data'">
        {{ (displayedCol.prefix && element[displayedCol.columnKey]) ? displayedCol.prefix : "" }}{{(element[displayedCol.columnKey].value) | number : '1.2-4' }}{{ displayedCol.suffix ? displayedCol.suffix : "" }}
      </span>
    </p>
    <p *ngIf="!displayedCol.useNumberPipe && !displayedCol.useTwoDecimalPipe && !displayedCol.isUrl && displayedCol.useIntegerPipe && element[displayedCol.columnKey] && element[displayedCol.columnKey].value != 'NULL'">
      <span [class]="element[displayedCol.columnKey].status ? element[displayedCol.columnKey].status : 'table-cell-data'">
        {{ displayedCol.prefix && element[displayedCol.columnKey] ? displayedCol.prefix : "" }}{{ element[displayedCol.columnKey].prefix ? element[displayedCol.columnKey].prefix : "" }}{{ element[displayedCol.columnKey].value | number 
        }}{{ displayedCol.suffix ? displayedCol.suffix : "" }}{{ element[displayedCol.columnKey].suffix ? element[displayedCol.columnKey].suffix : "" }}
      </span>
    </p>
    <p *ngIf="!displayedCol.useNumberPipe && !displayedCol.useTwoDecimalPipe && !displayedCol.isUrl && !displayedCol.useIntegerPipe && element[displayedCol.columnKey] && element[displayedCol.columnKey].value != 'NULL'">
      <span [class]="element[displayedCol.columnKey].status ? element[displayedCol.columnKey].status : 'table-cell-data'">
        {{ displayedCol.prefix && element[displayedCol.columnKey] ? displayedCol.prefix : "" }}{{ element[displayedCol.columnKey].value }}{{ displayedCol.suffix ? displayedCol.suffix : "" }}
      </span>
    </p>
    <p *ngIf="displayedCol.isUrl && !displayedCol.openinNewTab && element[displayedCol.columnKey].value">
      <a class="button textLink" (click)="onClickUrl(element[displayedCol.columnKey].value.url)">{{element[displayedCol.columnKey].value.displayValue}}</a>
    </p>
    <p *ngIf="displayedCol.isUrl && displayedCol.openinNewTab && element[displayedCol.columnKey].value">
      <a class="button" href="{{element[displayedCol.columnKey].value.url}}">{{element[displayedCol.columnKey].value.displayValue}}</a>
    </p>
    <p *ngIf="!displayedCol.useNumberPipe && !displayedCol.useTwoDecimalPipe && !displayedCol.isUrl && !element[displayedCol.columnKey]">
      {{ "N/A" }}
    </p>
  </ng-template>

  <div class="tableGraph">
    <div *ngIf="showModal">
      <app-update-price-modal [selectedData]="selectedData" (closeModalResponse)="closeModal()"></app-update-price-modal>
    </div>
    <table *ngIf="dataSource" class="jarvis-table"
      [ngClass]="config.hasFixedColumns ? ' fixed-column-table' : '' || config.customClassName ? config.customClassName : ''"
      [style.text-align]="config.textAlign || 'none'"
      mat-table [dataSource]="dataSource" matSort>
      
      <ng-container *ngFor="let displayedCol of config.columns; let i = index" [matColumnDef]="displayedCol.columnKey">
        <th *matHeaderCellDef [class]="config.isPivotTable ? 'pivot-th' : displayedCol.size"
          [ngClass]="displayedCol.isFixedColumn ? ' fixed-column-header' : (displayedCol.isComparisonColumn ? ' table-comparison-column' : (displayedCol.firstScrollColumn ? ' first-scroll-column-header' : (displayedCol.centerHeader ? 'center-col-header' : '')))">
          <button *ngIf="config.isPivotTable && displayedCol.groupable"
            mat-icon-button (click)="expandedColumns.has(displayedCol.columnKey) ?  onColumnClose(i) : onColumnExpand(i)">
            <mat-icon>{{ expandedColumns.has(displayedCol.columnKey) ? 'remove_circle' : 'add_circle' }}</mat-icon>
          </button>
          <span [ngClass]="displayedCol.isFixedColumn ? 'mat-sort-header-container' : displayedCol.header" mat-sort-header>{{ displayedCol.header }}
            <mat-icon *ngIf="displayedCol.showDetailsIcon" class="show-details-icon" [matTooltip]="displayedCol.showDetailsIcon">info_outline</mat-icon>
          </span>
        </th>
        <td *matCellDef="let element" [class]="config.isPivotTable ? 'pivot-td' : displayedCol.size"
          [style.text-align]="displayedCol.align || config.textAlign || 'none'"
          [ngClass]="displayedCol.isFixedColumn? ' fixed-column' : (displayedCol.isComparisonColumn ? ' table-comparison-column' : (displayedCol.firstScrollColumn ? ' first-scroll-column' : ''))"> 
          <div *ngIf="!config.showStatusColors && !config.isPivotTable && !config.isComparisonTable">
            <ng-container
              *ngTemplateOutlet="regularCellTemplate; context:{ element: element, displayedCol: displayedCol }">
            </ng-container>
          </div>
          <div *ngIf="config.showStatusColors && !config.isPivotTable">
            <ng-container 
              *ngTemplateOutlet="statusCellTemplate; context:{ element: element, displayedCol: displayedCol }">
            </ng-container>
          </div>
          <div *ngIf="config.isPivotTable">
            <ng-container
              *ngTemplateOutlet="pivotCellTemplate; context:{ element: element, displayedCol: displayedCol }">
            </ng-container>
          </div>
        </td>
      </ng-container>
      <tr [ngClass]="config.rowHover ? 'hover:bg-gray-200 cursor-pointer w-full' : ''"  mat-header-row *matHeaderRowDef="config.displayedColumns"></tr>
      <tr [ngClass]="config.rowHover ? 'hover:bg-gray-200 cursor-pointer w-full' : ''" (click)="openModal(row)" mat-row *matRowDef="let row; columns: config.displayedColumns;"></tr>
    </table>
    <div>
      <mat-paginator [ngClass]="showPaginator ? 'jarvis-paginator' : 'hide-paginator'"
        [pageSizeOptions]="config.pageSizeOptions || [10, 100]"
        showFirstLastButtons
        aria-label="Select page">
      </mat-paginator>
    </div>
  </div>
</div>