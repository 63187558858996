import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { DatasetEntity } from 'src/app/models/dataset.model';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { StudyService } from 'src/app/services/study.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'study-form-new',
  templateUrl: './study-form-new.component.html',
  styleUrls: ['./study-form-new.component.scss']
})
export class StudyFormNewComponent implements OnInit {
  @Input() datasetEntity: DatasetEntity;
  @Output() closeEvent = new EventEmitter<boolean>();

  userId: string;
  customerId: string;
  studyForm: UntypedFormGroup;
  classes: string[];

  classesToEdit: string[] = null;

  constructor(
    private studyService: StudyService,
    private userService: UserService,
    private formBuilder: UntypedFormBuilder) { }

  async ngOnInit() {
    this.studyForm = this.formBuilder.group({
      dataset: [null, [Validators.nullValidator]],
      title: [null, [Validators.required]],
      description: [null, Validators.required],
      classes: ['', [Validators.nullValidator]],
      isMultilabel: [false, [Validators.nullValidator]]
    });

    this.classes = [];

    const user = await this.userService.getUser();
    this.userId = user.id;
    this.customerId = user.cid;
  }

  async submitStudy() {
    if (!this.studyForm.valid) {
      this.studyForm.controls.title.markAsTouched({ onlySelf: true });
      this.studyForm.controls.description.markAsTouched({ onlySelf: true });
      return;
    }
    await this.studyService.createStudy(
      this.customerId,
      this.datasetEntity,
      this.userId,
      this.studyForm.value.title,
      this.studyForm.value.description,
      this.classes,
      this.studyForm.value.isMultilabel
    );

    this.closeEvent.emit(true);
  }

  cancelForm() {
    this.closeEvent.emit(false);
  }

  editClasses(): void {
    this.classesToEdit = this.classes;
  }
  // NB: classes is null if no change was made
  refreshClasses(classes): void {
    this.classesToEdit = null; // destroys dialog
    if (classes !== null) {
      this.studyForm.patchValue({ classes: classes.join(', ') });
      this.classes = classes;
    }
  }
}
