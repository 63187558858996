<div style="width: 500px;" class="bg-white border-2 rounded-md shadow-lg p-4 m-2">
  <div>
    <div>{{ cardTitle || '--' }}</div>
    <div *ngIf="loading" class="p-2">
      <ngx-skeleton-loader count="2"></ngx-skeleton-loader>
    </div>
    <div *ngIf="!loading" class="font-bold text-xl" 
      [matTooltip]="primaryTooltipText"
    > 
      {{(prefix ? prefix : "")}}{{ value || '--' }}{{(suffix ? suffix : "")}}
    </div>
    <div class="flex space-x-2">
      <div *ngIf="!loading && showSubtitle && secondaryValue" class="stats-secondary-value rounded-sm"
        [ngClass]="getSecondaryValueStatusClass(secondaryValue)"
        [matTooltip]="secondaryTooltipText">
        {{ showSubtitleNumberSign ? getNumberSign(secondaryValue) : '' }}{{ secondaryValue || '--' }}{{ subtitle }}
      </div>
      <div *ngIf="!loading && showSubtitle && thirdValue" class="stats-secondary-value rounded-sm"
        [ngClass]="getThirdValueStatusClass(thirdValue)"
        [matTooltip]="thirdTooltipText">
        {{ showSubtitleNumberSign ? getNumberSign(secondaryValue) : '' }}{{ thirdValue || '--' }}{{ subtitle2 }}
      </div>
    </div>
  </div>
  <div *ngIf="!loading && showGraph && graphData" class="stats-graph">
    <plotly-plot 
      [data]="graphData"
      [config]="config"
      [layout]="layout">
    </plotly-plot>
  </div>
</div>
