import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-property-card',
  templateUrl: './property-card.component.html',
  styleUrls: ['./property-card.component.scss']
})
export class PropertyCardComponent implements OnInit {

  @Input() property: any;
  sleep: string;
  bathrooms: string;
  bedrooms: string;

  constructor() { }

  ngOnInit(): void {
    if (this.property && this.property.additional_fields) {
      const sleepsObject = this.findObjectWithSleeps(this.property.additional_fields);
      if (sleepsObject) {
        this.sleep = sleepsObject.value;
      }
    }
    this.bathrooms = this.property.bathrooms || (this.property.hospitality_listing_detail && this.property.hospitality_listing_detail.bathrooms);
    this.bedrooms = this.property.bedrooms || (this.property.hospitality_listing_detail && this.property.hospitality_listing_detail.bedrooms);
  }
  
  findObjectWithSleeps(additionalFields) {
    if (additionalFields && additionalFields.length) {
      for (let i = 0; i < additionalFields.length; i++) {
        if ("sleeps" === additionalFields[i].key) {
          return additionalFields[i];
        }
      }
      return null;
    }
  }
}

