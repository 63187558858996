<div class="navigation-bar">
  <div class="navbar-header">
    <img class="jarvisml-logo" routerLink="/" src="assets/aidaptive_logo.svg"/>
    <div class="partnership-line" *ngIf="isReady"></div>
    <div class="customer-logo" *ngIf="isReady">
      <div *ngIf="user.jarvisml_group" class="textLink" (click)="onClickCustomer()">
        {{ customerName  || this.user?.cid }}
      </div>
      <div *ngIf="!user.jarvisml_group">
        {{ customerName }}
      </div>
    </div>
  </div>

  <div class="navBox" *ngIf="user">
    <div class="items" [ngClass]="{'ready': isReady}">

    <div *ngIf="!isScreenWideEnough" class="nav-menu-list">
      <div class="menuItem">
        <a mat-button [matMenuTriggerFor]="mainIconMenu" (menuOpened)="menuOpened()">
          <mat-icon class="topMenuIcon">menu</mat-icon>
        </a>
        <mat-menu #mainIconMenu="matMenu" class="responsive-menu">
          <div style="min-width: 200px;" *ngFor="let a of actions" class="menuItem">
            <menu-action [def]="a" [isDeployed]="a.showSubmenu" [isResponsive]="true"
              (click)="onMenuClick($event, a.id)">
            </menu-action>
            <app-submenu *ngIf="a.submenu" class="submenuItem popup" [isVisible]="a.showSubmenu" [submenuData]="a.submenuActions"></app-submenu>
          </div>
        </mat-menu>
      </div>
    </div>

    <div class="nav-menu-list" *ngIf="isScreenWideEnough">
      <div *ngFor="let a of actions" class="menuItem" (mouseenter)="showSubMenu(a.id)">
        <menu-action [def]="a"></menu-action>
        <app-submenu *ngIf="a.submenu" class="submenuItem" [isVisible]="a.showSubmenu" [submenuData]="a.submenuActions"
          (mouseenter)="showSubMenu(a.id)"
          (mouseleave)="hideSubMenu(a.id)"
          (click)="hideSubMenu(a.id)">
        </app-submenu>
      </div>
    </div>

    </div>
  </div>
</div>

<generic-selector *ngIf="customerList" [items]="customerList" (closeEvent)="onSelectCustomer($event)">
</generic-selector>
