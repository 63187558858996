<div class='table-container'>
  <div *ngIf="shouldShow">
      <table mat-table [dataSource]="datasource" class="jarvis-table"
        (matSortChange)="onSortChange($event)" matSort
      >
          <ng-container matColumnDef="id">
              <th class="table-header-name" *matHeaderCellDef> Id </th>
              <td *matCellDef="let element"> {{element.id}} </td>
          </ng-container>

          <ng-container matColumnDef="filename">
              <th class="table-header-name" *matHeaderCellDef mat-sort-header> Name </th>
              <td *matCellDef="let element"> {{element.filename}} </td>
          </ng-container>

          <ng-container matColumnDef="status">
              <th class="table-header-name" *matHeaderCellDef mat-sort-header> Status </th>
              <td *matCellDef="let element"> {{element.status }} </td>
          </ng-container>

          <ng-container matColumnDef="datetime">
              <th class="table-header-name" *matHeaderCellDef mat-sort-header> Date </th>
              <td *matCellDef="let element">
                {{(element.datetime ? element.datetime.toDate() : 0) | date:'medium' }}
              </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"
            [ngClass]="{'highlight': selectedRowIndex == row.id}"
            (click)="highlight(row)">
          </tr>
      </table>

      <mat-paginator hidePageSize *ngIf="datasource"
        [length]="datasource.getRowCount()"
        [pageSize]="datasource.getPageSize()"
        (page)="datasource.fetch($event.pageIndex, $event.pageSize)">
      </mat-paginator>
  </div>
</div>
