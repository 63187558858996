import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { InputParams } from '../generic-graph/generic-graph.component';
import { BackendDataService } from 'src/app/services/backend-data.service';
import { TableGraphConfig } from '../table-graph/table-graph.component';
import { GenericGraphComponent } from '../generic-graph/generic-graph.component';
import { AidaptiveCustomerSettingService } from 'src/app/services/aidaptive-customer-setting.service';
import { UserService } from 'src/app/services/user.service';
import { UserEntity } from 'src/app/models/user.model';



@Component({
  selector: 'app-summary-table',
  templateUrl: './summary-table.component.html',
  styleUrls: ['./summary-table.component.scss']
})
export class SummaryTableComponent implements OnInit {

  @Input() config: TableGraphConfig;
  @Input() parent: GenericGraphComponent
  @Input() inputParams: InputParams;
  @Input() size: string;

  public rawData: any[];
  public footerText: string;
  public loading: boolean;
  filterConfig;
  errorFetchingData = false;
  noData = false;
  customer
  user: UserEntity
  nonGrowthPlanRows = ['Revenue per Visitor', 'Average Order Value', 'Revenue']

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private backendDataService: BackendDataService,
    private aidaptiveCustomerSettingsService: AidaptiveCustomerSettingService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.fetchSummaryData(this.config)
  }

  isVisible() {
    return this.config?.settings && !this.config.settings.hidden;
  }
  private async fetchSummaryData(config): Promise<void> {
    this.loading = true
    try {
      this.user = await this.userService.getUser();
      this.customer = await this.aidaptiveCustomerSettingsService.getAidaptiveCustomerSettings(this.user.cid);
      const graphUrl = config.url;

      // TODO: pass inputParams thru instead of filterConfig
      const filterConfig = {};

      this.filterConfig = filterConfig;
      // Some graph urls need customization before a request is made

      if (graphUrl) {
        this.rawData = await this.backendDataService.fetchJsonData(graphUrl, false, this.config.baseUrl);
      } else {
        console.error(`Empty url for ${config.title}`);
        this.rawData = [];
      }
      let filteredData = this.rawData;
      if (config.responseHandler) {
        const responseData = config.responseHandler(filteredData, this);
        if (responseData && responseData.length) {
          filteredData = responseData;
        }
      }
      if (!this.rawData || this.rawData.length < 1 || (typeof this.rawData === 'object' && Object.keys(this.rawData).length < 1)) {
        this.noData = true;
      }
      this.errorFetchingData = false;
    } catch (error) {
      console.log(error)
      this.errorFetchingData = true;
    }
    this.loading = false;
    this.changeDetectorRef.detectChanges();
  }

  // Todo Change Currency Format According to the Customer

  formatMoney(value: number, currency: string): string {
    let formattedValue;
    if (currency === "USD") {
      formattedValue = Math.abs(value).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })
      return (value < 0 ? '-$' : '$') + formattedValue;
    }
  }

  formatValue(value: number): string {
    return Math.abs(value).toLocaleString("en-US")
  }

  handleInfinityVariance(data) {
    if (isNaN(data.Variance) || !isFinite(data.Variance)) {
      return 'inf%';
    } else if (data.Variance) {
      return data.Variance + '%';
    } else {
      return "%"
    }
  }

  growthPlan(): boolean {
    return this.customer.internal_settings.customer_size === 'small' || this.customer.internal_settings.customer_size === 'medium' 
  }

}
