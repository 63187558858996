import { Component, OnInit, Input } from "@angular/core";
// Models
import { UserEntity } from "src/app/models/user.model";
import { CustomerEntity } from "src/app/models/customer.model";
// Services
import { UserService } from "src/app/services/user.service";
import { CustomerService } from "src/app/services/customer.service";
import { FormService } from "src/app/services/form.service";
import { PageService } from "src/app/services/page.service";
import { CustomerSettingService } from "src/app/services/customer-setting.service";
import { GenericFormChange } from "../generic-form/generic-form.component";

@Component({
  selector: "tab-visibility",
  templateUrl: "./tab-visibility.component.html",
  styleUrls: ["./tab-visibility.component.scss"],
})
export class TabVisibilityComponent implements OnInit {
  public user: UserEntity;
  public customer: CustomerEntity;
  public tabSettings: any;

  @Input() showIcon:boolean;

  constructor(
    private userService: UserService,
    private customerService: CustomerService,
    private formService: FormService,
    public pageService: PageService,
    private customerSettingService: CustomerSettingService
  ) {}

  async ngOnInit() {
    this.user = await this.userService.getUser();
    this.customer = await this.customerService.getCustomer(this.user.cid);
  }
  async showHideTabsForm() {
    this.tabSettings = await this.customerSettingService.getPreferences(this.user.cid);
    if (this.user.jarvisml_group) {
      const tabs = await this.formService.showForm("Show / Hide Tabs", [
        { name: "showInsightsTab", label: "Insights & Analytics", type: "boolean", init: true, disabled: true },
        { name: "showMetricsTab", label: "Metrics", type: "boolean", init: this.tabSettings?.showMetricsTab },
        { name: "showUpsellTab", label: "Sales Booster", type: "boolean", init: this.tabSettings?.showUpsellTab },
        { name: "showPowerPricingTab", label: "Power Pricing", type: "boolean", init: this.tabSettings?.showPowerPricingTab },
        { name: "showPowerPricingBetaLabel", label: "Power Pricing (Beta)", type: "boolean", init: this.tabSettings?.showPowerPricingBetaLabel },
        { name: "showDynamicExperienceTab", label: "Dynamic Experience", type: "boolean", init: this.tabSettings?.showDynamicExperienceTab },
        { name: "showRecommendationsTab", label: "Recommendations", type: "boolean", init: this.tabSettings?.showRecommendationsTab },
        { name: "showInstructionsTab", label: "Instructions", type: "boolean", init: this.tabSettings?.showInstructionsTab },
        { name: "showCustomerValueTab", label: "Customer Value", type: "boolean", init: this.tabSettings?.showCustomerValueTab },
        { name: "showDatasetsTab", label: "Datasets", type: "boolean", init: this.tabSettings?.showDatasetsTab},
        { name: "showStudiesTab", label: "Studies", type: "boolean", init: this.tabSettings?.showStudiesTab},
        { name: "showKnowledgeGraphTab", label: "Knowledge Graph", type: "boolean", disabled: true, init: true},
       
      ], {returnsChanges: true});
      if (tabs) {
        
        for(const { field } of Object.values<GenericFormChange>(tabs)){
          const changes = {};
          changes[field.name] = !field.init;
          
          await this.customerSettingService.modifyPreferences(this.user.cid, 'preferences', changes);
        }
        // TODO find better way to update tab items
        window.location.reload();
      }
    }
  }
}