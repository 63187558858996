<div class="darkDiv">
<div class="pageContent">

  <h1 class="pageHeader">{{title}}</h1>
  <button class="cancelButton" jarvis-button (click)="onSelect(-1)">Cancel</button>

  <div class="subtitle">{{subtitle}}</div>

  <div class="graphList">
    <div class="graphButton" *ngFor="let config of configs; let i=index;"
      (click)="onSelect(i)"
    >
      <generic-graph 
        [id]="config.id"
        [user]="user"
        [config]="config"
        [inputParams]="pageService.currentInputParams()"
        size="small">
      </generic-graph>
    </div>
  </div>
</div>

</div>
