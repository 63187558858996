import { Component, Input, OnInit } from '@angular/core';
import { CustomerSettingService } from 'src/app/services/customer-setting.service';
import { UserEntity } from 'src/app/models/user.model';
import { FormService } from 'src/app/services/form.service';
import { UserService } from 'src/app/services/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'modal-logger',
  templateUrl: './modal-logger.component.html',
  styleUrls: ['./modal-logger.component.scss'],
})
export class ModalLoggerComponent implements OnInit {
  @Input() user: UserEntity;

  public showRecommendationsFlag: boolean;

  constructor(private customerSettingService: CustomerSettingService, private formService: FormService, private userService: UserService, private _snackBar: MatSnackBar) {}

  async ngOnInit() {
    const flagSettings = await this.customerSettingService.getPreferences(this.user.cid);
    this.showRecommendationsFlag = flagSettings.showPersonalizedRecommendations;
  }

  toggleRecommendations() {
    this.setToggle();
    this.logForm();
  }

  async logForm() {
    const logData = await this.formService.showForm('Log History', [{ name: 'description', label: 'Reason for enabling / disabling Recommendations', type: 'multiline' }]);

    if (!logData || !logData.description) {
      this.setToggle();
    } else if (logData.description) {
      this.openSnackBar();
    }

    // TODO make generic - firestore subId -
    // TODO display log history
  }

  openSnackBar() {
    if (this.showRecommendationsFlag) {
      this._snackBar.open('Personalized Recommendations Enabled', 'Close');
    } else {
      this._snackBar.open('Personalized Recommendations Disabled', 'Close');
    }
  }

  setToggle() {
    this.showRecommendationsFlag = !this.showRecommendationsFlag;
  }
}
