import { Component, OnInit, Input, ViewEncapsulation, SimpleChanges, ViewChild, OnChanges } from '@angular/core';
import { BackendDataService } from 'src/app/services/backend-data.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { GenericGraphComponent, GraphConfig, InputParams } from '../generic-graph/generic-graph.component';
import { filterByDateRange, filterByMarketClient, filterByOrderStatus, sortByMonth } from 'src/app/util/helpers';
import { PlotlyComponent } from 'angular-plotly.js';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';


export interface MyGraphConfig extends GraphConfig {
  type: 'mygraph';
  graphType?: string; // e.g. 'bar' (default) or 'line'
  xTitle?: string;
  yTitle?: string;
  xIsLog?: boolean;
  yIsLog?: boolean;
  yRange?: [number,number];
  x?: string;               // name of "x" column in data
  y?: string;               // name of "y" column in data
  filter?: (row: any, cfg?: MyGraphConfig) => any;
  renderXFunc?: (row: any, cfg?: MyGraphConfig) => any;
  renderYFunc?: (row: any, cfg?: MyGraphConfig) => any;
  hoverFunc?: (row: any, cfg?: MyGraphConfig) => any;
  footer?: string;
  multicurveBy?: string;
  multicurve?: GraphCurveConfig[];
  marker?: string;
  markerLabel?: string;
  requestHandler?: (filterConfig: { [key: string]: any }, graph: MygraphComponent)=>string;
  responseHandler?: (graphData: any[], graph: MygraphComponent)=>any[];
}

export interface GraphCurveConfig {
  x?: string;
  y?: string;
  lineColor?: string;
  graphType?: string;
  columnType?: string;
  name?: string;
  filter?: (row: any, cfg?: GraphConfig) => any;
  renderXFunc?: (row: any, cfg?: GraphConfig) => any;
  renderYFunc?: (row: any, cfg?: GraphConfig) => any;
  hoverFunc?: (row: any, cfg?: GraphConfig) => any;
  yLow?, yHigh?: string; // used if graphType is 'area' (and then y is ignored)
  color?: string; // for area at the moment; default is rgba(0,100,80,0.2)
}

@Component({
  selector: 'mygraph',
  templateUrl: './mygraph.component.html',
  styleUrls: ['./mygraph.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MygraphComponent implements OnInit, OnChanges {


  @Input() config: MyGraphConfig;
  @Input() parent: GenericGraphComponent;
  @Input() inputParams: InputParams;
  @Input() size: string;

  @ViewChild(PlotlyComponent) plotComponent: PlotlyComponent;

  private rawData: any[];

  public footerText: string;

  public loading: boolean;

  public data: any[]; // data for plotly graph
  public layout: any; // layout for plotly graph
  public plotlyConfig: any;
  public renderedBreakpoints: any; //graph size based on screen size

  filtersForm: UntypedFormGroup;
  showFilters = false;
  filterConfig;

  public filterCollection: any;

  isInitialized = false;
  
  constructor(
    private backendDataService: BackendDataService,
    private formBuilder: UntypedFormBuilder,
    private bp: BreakpointObserver
  ) { }

  async ngOnInit() {
    this.bp.observe([
      Breakpoints.XSmall, //(max-width: 599.98px)
      Breakpoints.Small, //(min-width: 600px) and (max-width: 959.98px)
      Breakpoints.Medium, // (min-width: 960px) and (max-width: 1279.98px)
      Breakpoints.WebLandscape,  //(min-width: 1920px) 
    ]).subscribe(async res => {
      this.renderedBreakpoints = res.breakpoints;
      const graphLayoutCfg = await this.getGraphSizeByBp(res.breakpoints);
      if (this.layout){
        this.layout = Object.assign(this.layout, graphLayoutCfg);
      }
    });

    this.filtersForm = this.formBuilder.group({
      granularity: ['WEEK', [Validators.nullValidator]],
      customer_type: ['ALL', [Validators.nullValidator]],
      statuses: ['custom', [Validators.nullValidator]],
      feature_column: ['loan_type', [Validators.nullValidator]],
    });

    this.isInitialized = true;

    this.updateGraph();
  }

  // TODO: delete this if we confirm this is not used
  async ngOnChanges(_changes: SimpleChanges) {
    if (!this.isInitialized) {
      return; // NB: we get 1 "ngOnChanges" before "ngOnInit"
    }

    this.updateGraph();
  }

  private updateGraph() {
    this.loading = true;
    this.parent.showLoading(); // no used by skeleton so far; cannot harm
    this.data = null;

    setTimeout(() => this.fetchStatData(), 300);
  }

  public formatLabel(label: string,forTemplate = false) {
    const labelArr = label.split('_');
    const capitalizedLabelArr = labelArr.map(el => el.charAt(0).toUpperCase() + el.slice(1));
    return forTemplate ? capitalizedLabelArr.join(' ') : capitalizedLabelArr.join('');
  }

  private async fetchStatData(): Promise<void> {
    try {
      let graphUrl = this.config.url;
      // TODO: pass inputParams thru instead of filterConfig
      const filterConfig = {};
      if (this.inputParams.pageType) {
        filterConfig["selectedPageType"] = this.inputParams.pageType;
      }
      if (this.inputParams.filterObj) {
        Object.entries(this.inputParams.filterObj).forEach(([key, value]) => {
          filterConfig["selected"+this.formatLabel(key)] = value;
        });
      }
      if (this.inputParams.startDate) {
        filterConfig["startDate"] = this.inputParams.startDate;
      }
      if (this.inputParams.endDate) {
        filterConfig["endDate"] = this.inputParams.endDate;
      }
      this.filterConfig = filterConfig;
      // Some graph urls need customization before a request is made
      if (this.config.requestHandler) {
        const requestUrl = this.config.requestHandler(filterConfig, this);
        if (requestUrl) {
          graphUrl = requestUrl;
        }
      }

      if (graphUrl) {
        this.rawData = await this.backendDataService.fetchJsonData(graphUrl);
      } else {
        console.error(`Empty url for ${this.config.title}`);
        this.rawData = [];
      }

      let filteredData: any[];
      if (this.inputParams.startDate && this.inputParams.endDate) {
        filteredData = filterByDateRange(this.rawData, this.inputParams.startDate, this.inputParams.endDate);        
      } else {
        filteredData = this.rawData;
      }
      // Some graphs need processing after the response.
      if (this.config.responseHandler) {
        const responseData = this.config.responseHandler(filteredData, this);
        if (responseData && responseData.length) {
          filteredData = responseData;
        }
      }

      if (this.inputParams.status) {
        filteredData = filterByOrderStatus(filteredData, this.inputParams.status);
        filteredData = sortByMonth(filteredData);
      }

      // Filter by market and client
      if (this.inputParams.market || this.inputParams.client) {
        filteredData = filterByMarketClient(filteredData, this.inputParams.market, this.inputParams.client);
        filteredData = sortByMonth(filteredData);
      }

      if (filteredData.length > 0 || this.inputParams.market || this.inputParams.client || this.inputParams.status) {
        // Update the graph if filters are selected even if there is no data
        this.renderGraph(filteredData);
      }

      // NB: data is only defined if renderGraph has been called - which is not always done
      if (this.data?.length) {
        this.parent.showData();
      } else {
        this.data = null;
        this.parent.showNoData();
      }
    } catch (error) {
      this.parent.showFailure(error);
    }
    this.loading = false;
  }

  filterChange(event: any,type: string) {
    if(!this.inputParams.filterObj) this.inputParams.filterObj = {};
    this.inputParams.filterObj[type] = event.value;
    this.updateGraph();
  }

  private getGraphSizeByBp(bp):Promise<any>{
    return new Promise((resolve) => {
      let currentLayout = {
        showlegend: true,
        modebar: {
          orientation: 'h'
        },
        legend: {orientation: 'h'}
      };
      if (bp[Breakpoints.XSmall] || bp[Breakpoints.Small]) {
        currentLayout = {
          showlegend: false,
          modebar: {
            orientation: 'v'
          },
          legend: {orientation: 'h'}
        }
      }
      resolve(currentLayout)
    })
  }

  private async renderGraph(filteredData) {
    const cfg = this.config;

    this.data = this.buildPlotlyData(filteredData);
    if (this.data.length === 0) {
      return;
    }
    
    const subtitle = cfg.subtitle
      ? `<br><span style="font-size: 15px; color: #999">${cfg.subtitle.replace('\n','<br>')}</span>`
      : '';
    
    const graphLayoutCfg = await this.getGraphSizeByBp(this.renderedBreakpoints);

    this.layout = {
      autosize: true,
      title: cfg.title + subtitle,
      xaxis: { title: cfg.xTitle },
      yaxis: { title: cfg.yTitle },
    };

    
    if (graphLayoutCfg) {
      this.layout = Object.assign(this.layout, graphLayoutCfg);
    }

    this.plotlyConfig = {
      displaylogo: false
    };

    if (cfg.xIsLog) {
      this.layout.xaxis.type = 'log';
    }
    if (cfg.yIsLog) {
      this.layout.yaxis.type = 'log';
    }
    if (cfg.yRange) {
      this.layout.yaxis.range = cfg.yRange;
    }

    if (cfg.footer) {
      this.footerText = cfg.footer;
    }

    if (cfg.graphType === 'stack') {
      this.layout.barmode = 'stack';
    }
  }

  onPlotInit() {
    const yrange = this.plotComponent.plotlyInstance['layout'].yaxis.range;
    const yRangeStart = yrange[0];
    const yRangeEnd = yrange[1];

    this.plotComponent.plotEl.nativeElement.on('plotly_relayout', async (eventdata) => {
      const newYorigin = eventdata['yaxis.range[0]'];
      if (newYorigin < yRangeStart) {
        const Plotly = await this.plotComponent.plotly.getPlotly();
        Plotly.relayout(this.plotComponent.plotEl.nativeElement, {'yaxis.range[0]': yRangeStart, 'yaxis.range[1]': yRangeEnd});
      }
    });
  }

  private buildPlotlyData(filteredData) {
    const cfg = this.config; // shortcut
    const plotlyDataArray = [];

    if (cfg.multicurveBy) {
      this.buildMulticurveByData(cfg, filteredData, plotlyDataArray);
    } else if (cfg.multicurve) {
      cfg.multicurve.forEach(curveCfg => {
        this.buildOnePlotlyData(cfg, filteredData, curveCfg, plotlyDataArray);
      });
    } else {
      this.buildOnePlotlyData(cfg, filteredData, null, plotlyDataArray);
    }
    return plotlyDataArray;
  }

  private buildOnePlotlyData(cfg: MyGraphConfig, filteredData: any[], curveCfg: GraphCurveConfig, dataArray: any[]) {
    const graphType = (curveCfg && curveCfg.graphType) || cfg.graphType;
    if (graphType === 'area') {
      this.buildAreaCurveData(filteredData, cfg, curveCfg, dataArray);
      return;
    }

    const rows = filteredData;
    const xName = (curveCfg && curveCfg.x) || cfg.x;
    const yName = (curveCfg && curveCfg.y) || cfg.y;
    const columnType = curveCfg?.columnType;
    const filter = (curveCfg && curveCfg.filter) || cfg.filter;
    const renderXFunc = (curveCfg && curveCfg.renderXFunc) || cfg.renderXFunc;
    const renderYFunc = (curveCfg && curveCfg.renderYFunc) || cfg.renderYFunc;
    const hoverFunc = (curveCfg && curveCfg.hoverFunc) || cfg.hoverFunc;
    const name = (curveCfg && curveCfg.name) || undefined;

    const markerName = cfg.marker;
    const markerData = {
      x: [],
      y: [],
      name: cfg.markerLabel,
      type: 'scatter',
      mode: 'markers',
      marker: {
        color: 'rgb(255,0,0)',
        size: 14,
        line: {
          color: 'rgb(0,0,0)',
          width: 2
        }
      },
      showlegend: true,
      legend: {orientation: 'h'}
    };

    if (rows.length && columnType !== 'computed') {
      const row0 = rows[0];
      if (row0[xName] === undefined) {
        console.error(`No colum ${xName} in ${cfg.title} (${Object.keys(row0)})`);
      }
      if (row0[yName] === undefined) {
        console.error(`No colum ${yName} in ${cfg.title} (${Object.keys(row0)})`);
      }
    }

    const xs = [], ys = [], hovervals = [];
    for (let n = 0; n < rows.length; n++) {
      const row = rows[n];
      // Always filter out null or undefined Y values
      if (!row[yName] && row[yName] !== 0) {
        continue;
      }
      // NB: filter may modify row
      if (filter && filter(row, cfg) === false) {
        continue;
      }

      if (renderXFunc) {
        xs.push(renderXFunc(row));
      } else {
        xs.push(row[xName]);
      }

      if (renderYFunc) {
        ys.push(renderYFunc(row));
      } else {
        ys.push(row[yName]);
      }

      if (hoverFunc) {
        hovervals.push(hoverFunc(row));
      }

      if (markerName && row[markerName]) {
        const i = xs.length - 1;
        markerData.x.push(xs[i]);
        markerData.y.push(ys[i]);
      }
    }
    if (xs.length === 0) {
      return; // no data for this curve
    }

    const result: any = { x: xs, y: ys };

    if (graphType === 'stack') {
      result.type = 'bar';
    } else if (graphType === 'lineWithFill') {
      result.type = 'line';
      result.fill = 'tozeroy';
    } else {
      result.type = graphType || 'bar';
    }

    if (name) {
      result.name = name;
    } else {
      result.name = ''; // otherwise "trace 0" shows next to regular values when we have markerData
      result.showlegend = false;
    }

    if (hovervals.length) {
      result.text = hovervals;
      result.hovertemplate = '%{text}';
    }

    dataArray.push(result);

    if (markerName && markerData.x.length > 0) {
      dataArray.push(markerData);
    }
    if (cfg.multicurve && (dataArray.length === cfg.multicurve.length)) {
      for (let i = 0; i < cfg.multicurve.length; i++) {
        if (cfg.multicurve[i].lineColor) {
          // Set custom lineColor for each line
          dataArray[i].line = {
            color: cfg.multicurve[i].lineColor,
            width: 2
          };
        }
      }
    }
  }

  buildAreaCurveData(rows: any[], cfg: MyGraphConfig, curveCfg: GraphCurveConfig, dataArray: any[]) {
    const xName = curveCfg.x;
    const lowName = curveCfg.yLow;
    const highName = curveCfg.yHigh;
    const filter = curveCfg.filter;

    let levelName;
    let level = 0;
    if (curveCfg.name) {
      const varMatch = /{[a-zA-Z_-]+}/.exec(curveCfg.name);
      if (varMatch) {
        levelName = varMatch[0].slice(1, -1); // e.g. "confidence_level"
      }
    }

    const xsHigh = [], ysHigh = [], xsLow = [], ysLow = [];

    for (let n = 0; n < rows.length; n++) {
      const row = rows[n];
      const low = row[lowName];
      if (!low && low != 0) continue;
      const high = row[highName]
      if (!high && high != 0) continue;
      if (filter && filter(row, cfg) === false) {
        continue;
      }
      level = row[levelName];
      const x = row[xName];
      xsHigh.push(x);
      ysHigh.push(high);
      xsLow.push(x);
      ysLow.push(low);
    }

    const label = Number(level)
      ? curveCfg.name.replace(`{${levelName}}`,  (level * 100).toFixed(1))
      : '';
    dataArray.push({
      x: xsHigh.concat(xsLow.reverse()),
      y: ysHigh.concat(ysLow.reverse()),
      fill: "toself",
      fillcolor: curveCfg.color || "rgba(0,100,80,0.2)",
      line: { color: "transparent" },
      name: label,
      showlegend: label !== '',
      type: "scatter",
      legend: {orientation: 'h'}
    });
  }

  buildMulticurveByData(cfg: MyGraphConfig, filteredData: any[], dataArray: any[]) {
    const rows = filteredData;
    const xName = cfg.x;
    const yName = cfg.y;

    if (rows.length && rows[0][cfg.multicurveBy] === undefined) {
      console.error(`No colum "${cfg.multicurveBy}" in ${cfg.title} (${Object.keys(rows[0])})`);
    }
    const curveMap = {};
    for (let n = 0; n < rows.length; n++) {
      const row = rows[n];
      if (cfg.filter && cfg.filter(row, cfg) === false) {
        continue;
      }
      const curveName = row[cfg.multicurveBy];
      let curveXYs = curveMap[curveName];
      if (!curveXYs) curveXYs = curveMap[curveName] = [[],[],[]];

      // Check if the graph has custom render function for x & y
      if (cfg.renderXFunc) {
        const renderXVal = cfg.renderXFunc(row);
        curveXYs[0].push(renderXVal);
      } else {
        curveXYs[0].push(row[xName]);
      }

      if (cfg.renderYFunc) {
        const renderYVal = cfg.renderYFunc(row);
        curveXYs[1].push(renderYVal);
      } else {
        curveXYs[1].push(row[yName]);
      }
      
      // Check if the graph has a custom hover function & values
      if (cfg.hoverFunc) {
        const hoverVal = cfg.hoverFunc(row);
        curveXYs[2].push(hoverVal);
      }
    }
    Object.entries(curveMap).forEach(([curveName, xys]) => {
      const result: any = {
        type: 'line', x: xys[0], y: xys[1], name: curveName
      };

      if (xys[2] && xys[2].length) {
        // Check if hover vals are present
        // Set custom hover template
        result.text = xys[2];
        result.hovertemplate = '%{text}';
      }
      dataArray.push(result);
    });
  }
}
