import { Component, OnInit } from '@angular/core';
import { DeeplinkService } from '../services/deeplink.service';

@Component({
  selector: 'app-setup-completed',
  templateUrl: './setup-completed.component.html',
  styleUrls: ['./setup-completed.component.scss']
})
export class SetupCompletedComponent implements OnInit {

  constructor(
    private deeplinkService: DeeplinkService,
  ) { }

  async ngOnInit() {
  }

  navigateTo(url) {
    this.deeplinkService.navigateToUrl(url);
  }

}
