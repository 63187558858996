import { Component, Input } from '@angular/core';
import { DeeplinkService } from 'src/app/services/deeplink.service';

export interface MenuActionDefinition {
  id?: string;
  label?: string;
  link?: string; // either link or action must be provided
  externalLink?: string; // opens in new window/tab
  action?: () => void;
  condition?: () => boolean;
  submenu?: boolean;
  showSubmenu?: boolean;
  submenuActions?: MenuActionDefinition[];
}

@Component({
  selector: 'menu-action',
  templateUrl: './menu-action.component.html',
  styleUrls: ['./menu-action.component.scss']
})
export class MenuActionComponent {
  @Input() def: MenuActionDefinition;
  @Input() isDeployed = false;
  @Input() isResponsive = false;

  constructor(
    private deeplinkService: DeeplinkService,
  ) {
  }

  navigate(simpleLink: string) {
    return this.deeplinkService.navigateToUrl(simpleLink);
  }
}
