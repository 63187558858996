<mat-nav-list *ngFor="let section of sections">

  <mat-divider></mat-divider>
  <h3 matSubheader>{{section.title}}</h3>

  <div *ngFor="let cfg of section.graphConfigs">
    <div class="hover:bg-blue-600 hover:text-white m-2 p-2 cursor-pointer rounded-md" [ngClass]="cfg.hasIssue ? 'bg-red-500 text-white' : ''"
      (click)="pageService.scrollToItem(cfg.id)">
      <h4 class="hover:bg-blue-600 hover:text-white" matLine>{{cfg.title}}</h4>
    </div>
  </div>
</mat-nav-list>