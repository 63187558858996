import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';

import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { UserEntity } from '../models/user.model';
import { getAllDocs, getOneDoc } from '../util/helpers';

const testUser1 = 'vFO9vWetCVd1S3nK0KEhWZTWVlq1';
const testUser2 = 'kzdTvO6gwuNXLyiTp3cdYtpWXUj1';


@Injectable({
  providedIn: 'root'
})
export class UserService {

  private superuserCid = '';
  private originalCid = '';

  constructor(
    private firestore: AngularFirestore,
    private afAuth: AngularFireAuth,
  ) {
  }

  setSuperuserCid(cid: string): string {
    this.superuserCid = cid;
    return this.originalCid;
  }

  public dynamicRemap(user: UserEntity) {
    const canRemap = !!user.jarvisml_group;
    if (canRemap) {
      user.cid = this.superuserCid || this.originalCid;
    }
  }

  async signOut() : Promise<void> {
    this.superuserCid = this.originalCid = '';
    return this.afAuth.signOut();
  }

  async getIdToken(): Promise<string> {
    return new Promise((resolve) => {
      const authSubscription = this.afAuth.user.subscribe(async userInfo => {
        authSubscription.unsubscribe();
        if (!userInfo) return resolve(null);
        const token = await userInfo.getIdToken(/*forceRefresh=*/false);
        resolve(token);
      });
    });
  }

  // Most common way to "auth" + get user
  async getUser(): Promise<UserEntity> {
    return new Promise((resolve, reject) => {
      const authSubscription = this.afAuth.user.subscribe(userInfo => {
        authSubscription.unsubscribe();
        if (userInfo === null) {
          reject('Not logged in');
          return; // not logged in yet - we wait
        }
        const subscription = this.getUserObservable(userInfo.uid).subscribe((userEntity) => {
          subscription.unsubscribe();
          this.dynamicRemap(userEntity);
          resolve(userEntity);
        });
      });
    });
  }
  
  /**
   * @deprecated: use observeUser() instead
   */
  getUserObservable(userId: string): Observable<UserEntity> {
    const results = this.firestore.doc<UserEntity>(`Users/${userId}`)
      .snapshotChanges()
      .pipe(map(actions => {
        const data = actions.payload.data() as UserEntity;
        if (!data) {
          console.warn(`User ${userId} not setup yet - normal if account was just created`);
          return {id: actions.payload.id};
        }
        this.originalCid = data.cid;
        return {id: actions.payload.id, ...data};
      }));
    return results;
  }

  // Call this only if you need to subscribe to changes in current user.
  // ** Most of the time you don't **
  // Notifies with a null UserEntity if no one is logged in
  observeUser(): Observable<UserEntity> {
    return new Observable((subscriber) => {
      let userSub: Subscription;
      let userId: string;

      this.afAuth.user.subscribe(userInfo => {
        // If logged out or different user we unsubscribe from user changes (will resubscribe if user changed)
        if (userId && (userInfo === null || userInfo.uid !== userId)) {
          userSub.unsubscribe();
          userId = undefined;
        }

        if (userInfo === null) {
          // Not logged in - or logged out
          subscriber.next(null);
        } else if (userInfo.uid !== userId) {
          // New user logged in
          userId = userInfo.uid;
          userSub = this.getUserObservable(userInfo.uid).subscribe(user => {
            // NB: When the change is a CID remap, not waiting causes a harmless but noisy error
            // because Firestore rule rejects the access to new CID customer (if our CID is not-yet-updated there)
            setTimeout(() => {
              subscriber.next(user);
            }, 500);
          });
        }
      });
    });
  }

  /**
   * @returns true`when user is granted specified level
   */
  isUserGranted(user: UserEntity, level: string): boolean {
    if (!user.enabled) {
      return false;
    }
    switch (level) {
      case 'admin':
        return user.role === 'admin' || user.role === 'owner';
      case 'viewer':
        return true;
      default:
        return false;
    }
  }

  async modifyUser(userId: string, user: UserEntity): Promise<void> {
    const doc = this.firestore.doc<UserEntity>(`Users/${userId}`);
    return doc.update(user);
  }

  // User-admin level only
  async getAllUsers(cid: string, asJarvis: boolean): Promise<UserEntity[]> {
    const collection = this.firestore.collection<UserEntity>('Users',
      (ref) => ref.where('cid', '==', cid));
    const allDocs = await getAllDocs(collection);
    if (cid === 'A001') {
      return allDocs; // in JarvisML CID we can see/edit everyone
    } else {
      // outside JarvisML filter-out JarvisML folks from the list unless requested otherwise
      return allDocs.filter(doc => asJarvis || !doc.jarvisml_group);
    }
  }

  //--- DEV stuff below


  async getUnmappedUsers(): Promise<UserEntity[]> {
    const userCollection = this.firestore.collection<UserEntity>('Users', ref => ref.where('cid', '==', ''));
    return getAllDocs(userCollection);
  }

  async verifyFails(fn, message) {
    try {
      await fn();
      throw new Error(`Did not fail with "${message}"`);
    } catch (error) {
      if (error.message !== message) throw error;
    }
  }
  async verifyNotAllowed(fn) {
    return this.verifyFails(fn, 'Missing or insufficient permissions.');
  }

  // Returns non-empty string if error
  async testAdminAccess(): Promise<string> {
    try {
      const myself = await this.getUser();
      if (myself.cid !== 'A001') throw new Error('Cannot test if your CID is not A001');

      let userCollection = this.firestore.collection<UserEntity>('Users', ref => ref.where('cid', '==', myself.cid));
      let userDocs = await getAllDocs(userCollection);
      userDocs.forEach(u => { if (u.cid !== myself.cid) throw new Error('Got CID=' + u.cid); });
      if (userDocs.length === 0) throw new Error('Got 0 users with CID ' + myself.cid);

      userCollection = this.firestore.collection<UserEntity>('Users', ref => ref.where('cid', '==', ''));
      userDocs = await getAllDocs(userCollection);
      if (userDocs.length === 0) throw new Error('Got 0 users with blank CID');

      userCollection = this.firestore.collection<UserEntity>('Users', ref => ref.where('cid', '==', 'A002'));
      await this.verifyNotAllowed(() => getAllDocs(userCollection));

      userCollection = this.firestore.collection<UserEntity>('Users');
      await this.verifyNotAllowed(() => getAllDocs(userCollection));

      // Cannot even list collection on my own ID or email
      userCollection = this.firestore.collection<UserEntity>('Users', ref => ref.where('id', '==', myself.id));
      await this.verifyNotAllowed(() => getAllDocs(userCollection));
      userCollection = this.firestore.collection<UserEntity>('Users', ref => ref.where('email', '==', myself.email));
      await this.verifyNotAllowed(() => getAllDocs(userCollection));

      const doc = this.firestore.doc<UserEntity>(`Users/${myself.id}`);
      const doc1 = this.firestore.doc<UserEntity>(`Users/${testUser1}`);
      const doc2 = this.firestore.doc<UserEntity>(`Users/${testUser2}`);
      const u1 = await getOneDoc(doc1);
      if (u1.cid !== 'A001') throw new Error('Test user 1 not properly defined');
      // Cannot read test user 2 from another CID
      await this.verifyNotAllowed(() => getOneDoc(doc2));

      // Can edit user in my CID
      doc1.update({ enabled: false });
      // Cannot map someone in my CID to another CID
      await this.verifyNotAllowed(() => doc1.update({ cid: 'A002' }));
      // Cannot edit user in another CID
      await this.verifyNotAllowed(() => doc2.update({ enabled: true }));
      // Can edit my own user
      doc.update({ enabled: true });

      // Cannot make someone else superuser
      await this.verifyNotAllowed(() => doc1.update({ jarvisml_group: 'platform' }));
      await this.verifyNotAllowed(() => doc2.update({ jarvisml_group: 'platform' }));

      // Cannot delete user in any CID
      await this.verifyNotAllowed(() => doc1.delete());
      await this.verifyNotAllowed(() => doc2.delete());
      // Cannot delete my own user
      await this.verifyNotAllowed(async () => await doc.delete());

      if (myself.jarvisml_group) {
        // I can remap myself
        await doc.update({ cid: 'A002' });
        await doc.update({ cid: 'A001' });
        // Cannot modify my jarvisml_group
        await this.verifyNotAllowed(() => doc.update({ jarvisml_group: 'napoleon' }));
      } else {
        // Cannot set my jarvisml_group
        await this.verifyNotAllowed(() => doc.update({ jarvisml_group: 'napoleon' }));
        // Cannot remap myself (except to unmap)
        // TODO: fix noisy console error created by below (not sure how yet)
        await this.verifyNotAllowed(async () => await doc.update({ cid: 'A002' }));
      }

      return '';
    } catch (err) {
      return err.message;
    }
  }

  async testViewerAccess(): Promise<string> {
    try {
      const myself = await this.getUser();
      if (myself.cid !== 'A001') throw new Error('Cannot test if your CID is not A001');

      let userCollection = this.firestore.collection<UserEntity>('Users', ref => ref.where('cid', '==', myself.cid));
      await this.verifyNotAllowed(() => getAllDocs(userCollection));

      userCollection = this.firestore.collection<UserEntity>('Users', ref => ref.where('cid', '==', ''));
      await this.verifyNotAllowed(() => getAllDocs(userCollection));

      userCollection = this.firestore.collection<UserEntity>('Users');
      await this.verifyNotAllowed(() => getAllDocs(userCollection));

      // Cannot even list collection on my own ID
      userCollection = this.firestore.collection<UserEntity>('Users', ref => ref.where('id', '==', myself.id));
      await this.verifyNotAllowed(() => getAllDocs(userCollection));

      // Cannot edit user
      const doc = this.firestore.doc<UserEntity>(`Users/${myself.id}`);
      const doc1 = this.firestore.doc<UserEntity>(`Users/${testUser1}`);
      const doc2 = this.firestore.doc<UserEntity>(`Users/${testUser2}`);
      await this.verifyNotAllowed(() => doc1.update({ enabled: true }));
      await this.verifyNotAllowed(() => doc2.update({ enabled: true }));

      if (myself.jarvisml_group) {
        await doc.update({ enabled: false });
        await doc.update({ enabled: true });
      } else {
        // Cannot edit myself
        await this.verifyNotAllowed(() => doc.update({ enabled: false }));
      }

      // Cannot set my jarvisml_group
      await this.verifyNotAllowed(() => doc.update({ jarvisml_group: 'napoleon' }));
      // Cannot set someone else's jarvisml_group
      await this.verifyNotAllowed(() => doc1.update({ jarvisml_group: 'platform' }));
      await this.verifyNotAllowed(() => doc2.update({ jarvisml_group: 'platform' }));

      // Cannot delete user
      await this.verifyNotAllowed(() => doc1.delete());
      await this.verifyNotAllowed(() => doc2.delete());
      // Cannot delete my own user
      await this.verifyNotAllowed(() => doc.delete());
      
      return '';
    } catch (err) {
      return err.message;
    }
  }

  async migrateUsers(): Promise<string> {
    let count = 0;
    // Below only allowed with temporary migration Firestore rule for superusers
    const currentUsers = await getAllDocs(this.firestore.collection<UserEntity>(`Users`));

    for (const user of currentUsers) {
      const userId = user.id;

      let changeCount = 0;

      // old "superuser=true" becomes "jarvisml_group='default'" -- JarvisML folks only
      if (user['superuser']) {
        changeCount++;
        user.jarvisml_group = 'default';
        delete user['superuser'];
      }

      delete user['id']; // not a real attribute

      // Comment out below for dev tests on 1 record:
      // if (userId !== 'knQazVpWbjUGdrryeumkDZGXvj82') continue;

      if (changeCount > 0) {
        //await targetDoc.set(userEntity, { merge: true });
        await this.firestore.doc<UserEntity>(`Users/${userId}`).set(user);
        count++;
      }
    }
    return `Migrated ${count} users`;
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function copyProp(srcDoc: any, name: string, targetDoc: any): number {
  if (srcDoc[name] === undefined || targetDoc[name] === srcDoc[name])
    return 0; // no update

  targetDoc[name] = srcDoc[name];
  return 1; // updated
}

// Can also be used to rename a property (if srcDoc === targetDoc)
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function moveProp(srcDoc: any, name: string, targetDoc: any, newName: string = name): number {
  if (srcDoc[name] === undefined)
    return 0; // no update

  targetDoc[newName] = srcDoc[name];  
  delete srcDoc[name];
  return 1; // updated
}