import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';

import { RecommendationsRoutingModule } from './recommendations-routing.module';
import { HomeRecommendationsComponent } from './home-recommendations/home-recommendations.component';

@NgModule({
  declarations: [
    HomeRecommendationsComponent
  ],
  imports: [
    CommonModule,
    RecommendationsRoutingModule,
    SharedModule
  ],
  exports:[
    HomeRecommendationsComponent
  ]
})
export class RecommendationsModule {}
