import { Component, Input, HostBinding } from '@angular/core';

import { ButtonStyle } from './button-style.enum';
import { ButtonSize } from './button-size.enum';
import { ButtonShape } from './button-shape.enum';
import { ButtonColor } from './button-color.enum';

@Component({
  selector: 'div[jarvis-button],span[jarvis-button],a[jarvis-button],input[jarvis-button],button[jarvis-button]',
  template: '<ng-content></ng-content>',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
  @Input()
  public style: ButtonStyle = ButtonStyle.SOLID;

  @Input()
  public size: ButtonSize = ButtonSize.MEDIUM;

  @Input()
  public shape: ButtonShape = ButtonShape.PILLED;

  @Input()
  public color: ButtonColor; // e.g. pass color="button-color-modest"

  constructor() {
    this.style = ButtonStyle.SOLID;
    this.size = ButtonSize.MEDIUM;
    this.shape = ButtonShape.SQUARED;
    this.color = ButtonColor.IMPORTANT; // NB: this could be MODEST
  }

  @HostBinding('class')
  get classes(): string {
    return `${this.style} ${this.size} ${this.shape} ${this.color}`
  }
}