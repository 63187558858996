<mat-sidenav-container class="sidenav-container">
  <mat-sidenav-content class="recommendations-sidenav-content">
    <div *ngIf="user?.jarvisml_group == 'platform'" class="admin-options">
      <button mat-button [matMenuTriggerFor]="menu">
        <mat-icon>settings</mat-icon>
      </button>
      <mat-menu #menu>
        <graph-visibility [user]="user" [config]="pageService.allGraphs"></graph-visibility>
      </mat-menu>
    </div>
    <div *ngIf="missingRecommendationsSetup" class="warningMessage">
      <div class="text">
        Your account is not set up for Recommendations.<br>
        Please contact us to see how this can get done.
      </div>
    </div>
    <div *ngIf="missingSetup" class="warningMessage">
      <div class="text">
        You don't have any dashboards. Please contact us to see how we can setup.
      </div>
    </div>
    <div>
      <div class="section-container -col p-5">
        <ng-container #sectionsContainer></ng-container>
      </div>
    </div>
    <company-links></company-links>
  </mat-sidenav-content>
</mat-sidenav-container>

