import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'data-insight-card',
  templateUrl: './data-insight-card.component.html',
  styleUrls: ['./data-insight-card.component.scss']
})
export class DataInsightCardComponent implements OnInit {

  @Input() title?: string;
  @Input() value?: string;

  constructor() { }

  ngOnInit(): void {
    // console.log(this.data);
  }
}
