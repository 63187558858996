<div class="w-full px-6 my-6">   
    <table class="w-full bg-white border border-gray-300">
        <thead>
            <tr>
                <th class="px-6 py-3 bg-gray-200 border-b text-left">Tab</th>
                <th class="px-6 py-3 bg-gray-200 border-b text-center">Show Internally</th>
                <th class="px-6 py-3 bg-gray-200 border-b text-center">Show Externally</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let category of data | keyvalue">
                <td class="px-6 py-2 border-b capitalize font-semibold text-left">{{ category.key }}</td>
                <td class="px-6 py-2 border-b text-center">
                    <label class="inline-flex items-center">
                        <input type="checkbox" class="form-checkbox text-blue-500" [disabled]="category.value?.disabled"
                            [(ngModel)]="category.value.showInternally" />
                    </label>
                </td>
                <td class="px-6 py-2 border-b text-center">
                    <label class="inline-flex items-center">
                        <input type="checkbox" class="form-checkbox text-blue-500" [disabled]="category.value?.disabled"
                            [(ngModel)]="category.value.showExternally" />
                    </label>
                </td>
            </tr>
        </tbody>
    </table>
    
</div>
<div class="m-6 flex justify-start" (click)="toggleVisibility()">
    <div class="px-8 py-2 bg-blue-500 text-white hover:bg-blue-600 cursor-pointer">
        {{ noSettingsAvailable ? "Initialize Tabs" : "Apply Changes"}}
    </div>
</div>
