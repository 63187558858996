import { Injectable } from '@angular/core';
import { FieldDescription, GenericFormOptions } from '../shared/generic-form/generic-form.component';
import { KeyboardService } from './keyboard.service';

@Injectable({
  providedIn: 'root'
})
export class FormService {

  currentForm = -1;
  formDatas: any[];
  formData: any;

  constructor(
    private keyboardService: KeyboardService,
  ) {
  }

  initRoot(formData: any, formData2: any) {
    if (this.formDatas) {
      console.error('dupe form.initRoot');
    }
    this.formDatas = [formData, formData2];
  }

  showForm(title: string, fields: FieldDescription[], options?: GenericFormOptions): Promise<{[key:string]:any}> {
    if (this.currentForm === this.formDatas.length - 1) throw new Error('too many forms');

    this.keyboardService.enterDialog(title);
    this.currentForm++;
    const formData = this.formDatas[this.currentForm];

    return new Promise(resolve => {
      formData.title = title;
      formData.fields = fields;
      formData.options = options;
      formData.close = (results: any[]) => {
        if (!formData.title) return; // close called twice?
        this.keyboardService.leaveDialog(formData.title);
        formData.title = ''; // hides the form
        resolve(results);
        this.currentForm--;
      };
    });
  }

  // Hides the current form
  hideForm() {
    if (this.currentForm >= 0 && this.formDatas && this.formDatas[this.currentForm]) {
      const formData = this.formDatas[this.currentForm];
      if (formData && formData.close) {
        formData.close(null);
      }
    }
  }
  
  
}
