import { Injectable } from '@angular/core';
import { KeyboardService } from './keyboard.service';

@Injectable({
  providedIn: 'root'
})
export class MsgboxService {

  msgboxData: any;

  constructor(
    private keyboardService: KeyboardService,
  ) {
  }

  initRoot(msgboxData: any) {
    if (this.msgboxData) {
      console.error('dupe msgbox.initRoot'); // probably not a good thing
    }
    this.msgboxData = msgboxData;  
  }

  showMsg(text: string, options?: any): Promise<void> {
    this.keyboardService.enterDialog(text);
    return new Promise(resolve => {
      this.msgboxData.question = text;
      this.msgboxData.choices = ['OK'];
      this.msgboxData.extraClass = options?.class || '';
      this.msgboxData.close = () => {
        this.keyboardService.leaveDialog(this.msgboxData.question);
        this.msgboxData.question = ''; // hides the box
        resolve();
      };
    });
  }

  // Promise will return the index of the chosen button
  showQuestion(question: string, choices: string[] = ['Yes', 'No'], options?: any): Promise<number> {
    this.keyboardService.enterDialog(question);
    return new Promise(resolve => {
      this.msgboxData.question = question;
      this.msgboxData.choices = choices;
      this.msgboxData.extraClass = options?.class || '';
      this.msgboxData.close = (choiceIndex: number) => {
        this.keyboardService.leaveDialog(this.msgboxData.question);
        this.msgboxData.question = ''; // hides the box
        resolve(choiceIndex);
      };
    });
  }
}
