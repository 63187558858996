import { Component, ElementRef, EventEmitter, Input, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AidaptiveCustomerPageService } from 'src/app/services/aidaptive-customer-page.service';
import { AidaptiveCustomerSettingService } from 'src/app/services/aidaptive-customer-setting.service';
import { DeeplinkService } from 'src/app/services/deeplink.service';
import { UserInfoService } from 'src/app/services/user-info.service';
import { UserService } from 'src/app/services/user.service';
import { SUPPORT_EMAIL, fetchSurveyFieldObject } from 'src/app/util/helpers';

@Component({
  selector: 'app-add-customer-form',
  templateUrl: './add-customer-form.component.html',
  styleUrls: ['./add-customer-form.component.scss']
})
export class AddCustomerFormComponent implements OnInit {
  @Output() closeEvent = new EventEmitter<boolean>();
  @Input() @Optional() parent: string;
  @Input() @Optional() externalIntegration: string;
  @Input() @Optional() redirectUrl: string;

  @ViewChild('newCustomerFormRef') newCustomerFormRef: ElementRef<HTMLElement>;

  domainPattern = /^(?:(https?:\/\/)([\da-z.-]+)\.([a-z.]{2,6})[/\w .-]*\/?)|(?:^$)$/i;
  nonAutomatedIntegrations = new Set(['custom', 'shopify'])
  public newCustomerForm;

  defaultValue = 'hospitality';
  showCustomerForm = false;
  settingsErrorMsg = '';
  externalSystemList: any;
  verticalNameType;
  selectedVertical: string;
  stepNames = ['Step 1', 'Step 2'];
  currentStep = 1;
  stepWidth = '50%'; //for progress styling
  selectedIntegration: string;
  integrationsScehma: any;
  apiInfoFields;
  isLoading: boolean;
  isFormLoading = true;
  createdCustomer;
  checkbox = false;
  success = false;
  blurredField = new Set();
  showRequiredError = false;
  showContactTooltip: boolean;
  surveyInfofields;
  totalSteps = 2;
  loggedInUserData;
  shopifyFlow: boolean;
  uid: string;
  selectedTooltip: string;
  helperTimer: any;
  activeStep = 1;
  formHeading = 'Connect';

  public supportEmail = SUPPORT_EMAIL;

  constructor(
    private fb: UntypedFormBuilder,
    public aidaptiveCustomerSettingService: AidaptiveCustomerSettingService,
    private customerPageService: AidaptiveCustomerPageService,
    private deeplinkService: DeeplinkService,
    public userInfoService: UserInfoService,
    private userService: UserService,
  ) { }

  async ngOnInit() {
    this.userService.observeUser().subscribe(user => this.setLoggedInUser(user));
    if (this.externalIntegration) {
      this.totalSteps = 2;
      this.currentStep = 1;
      this.activeStep = 3;
      await this.renderSurveyFields('customer');
      this.isFormLoading = false;
      return;
    } else if (this.parent === 'external') {
      this.stepNames = [...this.stepNames, 'Step 3', 'Step 4' ];
      this.stepWidth = '25%';
      this.totalSteps = 4;
    } 

    this.integrationsScehma = await this.customerPageService.getExternalIntegrationSchemma();
    this.aidaptiveCustomerSettingService.getExternalSystemSchema()
      .subscribe(
        (data) => {
          this.verticalNameType = data.map(item => ({
            value: item.vertical,
            display: item.vertical.charAt(0).toUpperCase() + item.vertical.slice(1)
          }));
          this.externalSystemList = data.reduce((result, item) => {
            result[item.vertical] = item.external_systems;
            return result;
          }, {});
          this.newCustomerForm = this.fb.group({
            name: ['', [Validators.required, Validators.pattern(/^(?!.*\/).*$/)]],
            domain: ['', [Validators.pattern(this.domainPattern), Validators.required]],
            vertical: ['', [Validators.required]],
            external_system: ['', [Validators.required]],
            google_account: ['', [Validators.email]]
          });

          this.newCustomerForm.get('vertical')?.valueChanges.subscribe(vertical => {
            this.selectedVertical = vertical;
            this.newCustomerForm.get('external_system')?.setValue('');
          });
          this.newCustomerForm.get('vertical')?.updateValueAndValidity();
          this.isFormLoading = false;
        },
        (error) => {
          console.error('Error fetching data:', error);
        }
      );
  }

  setLoggedInUser(user) {
    this.loggedInUserData = user;
  }

  async closeAddCustomerForm() {
    if (this.parent === 'external') {
      this.closeEvent.emit(this.success);  
    } else {
      if (this.success) {
        this.deeplinkService.switchCid(this.createdCustomer.cid, '/settings#tab#api-integrations');
      } else {
        this.closeEvent.emit(false);
      }
    }
  }

  setErrorMsg(msg = null) {
    if (msg) {
      this.settingsErrorMsg = msg;
      return;
    }
    this.settingsErrorMsg = 'Something went wrong, Please try again later.';
  }

  //2nd step submit
  async addApiInfo() {
    if (!this.newCustomerForm.valid) {
      this.markFormGroupTouched(this.newCustomerForm);
      return;
    }
    this.isLoading = true;
    this.settingsErrorMsg = '';
    const customerForm = this.newCustomerForm.value;
    const configBody = [];
    const newIntegrationObj = {}
    newIntegrationObj["external_system"] = this.selectedIntegration;
    newIntegrationObj[this.selectedIntegration] = customerForm;
    configBody.push(newIntegrationObj);

    try {
      await this.customerPageService.addIntegrationConfigs({
        aidaptive_customer_name: this.createdCustomer.name,
        cid: this.createdCustomer.cid,
        vertical: this.selectedVertical,
        external_integrations: configBody
      });
      this.isLoading = false;
      if (this.parent === 'external') {
        this.currentStep = this.activeStep = 3;
        this.callLinkUser();
        this.renderSurveyFields('customer');
        return;
      }
      this.success = true;
    } catch (err) {
      this.isLoading = false;
      this.setErrorMsg(err.message);
    }

  }

  async callLinkUser() {
    await this.aidaptiveCustomerSettingService.userLink({
      userId: this.loggedInUserData.id, integration: this.selectedIntegration
    })
  }

  setSurveyFormControl() {
    const stepControlsConfig: { [key: string]: any } = {};
    this.surveyInfofields.forEach((field) => {
      stepControlsConfig[field.name] = [
        { value: field.name === 'email' ? this.loggedInUserData.email : '', disabled: field.name === 'email' },
        this.getFormValidations(field),
      ];
    });
  
    this.newCustomerForm = this.fb.group({
      ...stepControlsConfig,
    });
  }

  async renderSurveyFields (type) {
    const surveyFieldsData = type === 'customer' ? await this.userInfoService.getCustomerSchema() : await this.userInfoService.getUserSchema();
    this.surveyInfofields = await fetchSurveyFieldObject(surveyFieldsData, this.selectedVertical);
    this.formHeading = type === 'customer' ? 'Tell us about your company' : 'Tell us about you'
    this.setSurveyFormControl();
  }
  
  generateRequiredFields(selectedIntegration) {
    if (this.nonAutomatedIntegrations.has(selectedIntegration)) {
      return;
    }
    const transformedProperties = [];
    const integrationKey = this.getDefinitionKeyName(selectedIntegration);
    const properties = this.integrationsScehma?.definitions?.[integrationKey]?.properties;
    const requiredKeys = this.integrationsScehma?.definitions?.[integrationKey]?.required;
    if (properties) {
      for (const key in properties) {
        const property = properties[key];
        const name = key;
        const type = property.type === 'string' ? 'text' : property.type === 'integer' ? 'number' : property.type;
        const format = property?.format;
        const example = Array.isArray(property?.examples) && property?.examples[0];
        const hint = property?.description;
        const tooltip = property?.tooltip;
        const label = property?.title || key
          .replace(/_/g, ' ')
          .replace(/\b\w/g, l => l.toUpperCase())
          .replace("Api", "API")
          .replace("Url", "URL");
        const isRequired = requiredKeys.includes(key);
        transformedProperties.push({ name, type, label, format, required: isRequired, example, tooltip, hint });
      }

      const stepControlsConfig: { [key: string]: any } = {};
      transformedProperties.forEach((field) => {
        stepControlsConfig[field.name] = ['',
          this.getFormValidations(field),
        ];
      });
      this.newCustomerForm = this.fb.group({
        ...stepControlsConfig,
      });
      this.apiInfoFields = transformedProperties;
    }
    return transformedProperties;
  }

  getDefinitionKeyName(key) {
    const definition = this.integrationsScehma?.properties?.[key];
    if (definition && definition["$ref"]) {
      const title = definition["$ref"].split("/").pop();
      const withoutSpaces = title.replace(/\s/g, ''); // Remove spaces
      return withoutSpaces;
    }
    return null;
  }

  //1st step submit
  async createNewCustomer() {
    if (!this.newCustomerForm.valid) {
      this.markFormGroupTouched(this.newCustomerForm);
      return;
    }
    this.isLoading = true;
    this.settingsErrorMsg = '';
    const customerForm = this.newCustomerForm.value;
    const aidaptiveCustomerSetting = {
      aidaptive_customer_name: customerForm.name,
      billing_strategy: 'enterprise',
      vertical: customerForm.vertical,
      external_system: customerForm.external_system,
      google_account: customerForm.google_account
    };
    if (this.loggedInUserData.id && this.parent === 'external') {
      aidaptiveCustomerSetting['uid'] = this.loggedInUserData.id;
    }
    if (customerForm.vertical === 'ecommerce') {
      aidaptiveCustomerSetting['store'] = {};
      aidaptiveCustomerSetting['store']['base_url'] = customerForm.domain;
    } else {
      aidaptiveCustomerSetting['hostname'] = customerForm.domain;
    }
    this.selectedIntegration = customerForm.external_system;
    try {
      const response = await this.aidaptiveCustomerSettingService.addAidaptiveCustomerSettings(aidaptiveCustomerSetting);
      this.generateRequiredFields(customerForm.external_system);
      this.isLoading = false;
      this.currentStep = this.activeStep = 2;
      if (this.nonAutomatedIntegrations.has(customerForm.external_system)) {
        this.totalSteps = 2;
        this.success = true;
      }
      this.createdCustomer = response;
      this.newCustomerFormRef.nativeElement.scrollTop = 0;
      this.blurredField.clear();
    } catch (err) {
      this.isLoading = false;
      this.setErrorMsg(err.message);
    }
  }

  //used to display error in case someclicks next/submit with empty fields
  markFormGroupTouched(formGroup: UntypedFormGroup) {
    Object.values(formGroup.controls).forEach(control => {
      control.markAsTouched();
  
      if (control instanceof UntypedFormGroup) {
        this.markFormGroupTouched(control);
      }
    });
    const fieldNames = this.getInvalidFieldNames(formGroup);
    fieldNames.forEach(fieldName => {
      this.blurredField.add(fieldName)
    });
  }

  getValidationPattern(format) {
    if(!format) {
      return;
    }
    return format === 'uri' ? this.domainPattern : ''; //change into a switch when we have more options
  }

  getFormValidations(field) {
    const validations = [];
    if (field.required) {
      validations.push(Validators.required);
    }
    const validationPattern = this.getValidationPattern(field.format);
    if (validationPattern) {
      validations.push(Validators.pattern(validationPattern));
    }
    return validations;
  }

  getIntegrationLabel(selectedIntegration, selectedVertical) {
    const integration = this.externalSystemList[selectedVertical].find(list => list.value === selectedIntegration);
    return integration?.display;
  }

  onBlur(field) {
    this.blurredField.add(field);
  }

  onFocus(field) {
    this.blurredField.delete(field);
  }

  getInvalidFieldNames(formGroup: UntypedFormGroup): string[] {
    return Object.keys(formGroup.controls).filter(controlName => formGroup.get(controlName)?.invalid);
  }

  toggleSupportTooltip(click, visible = false) {
    if (click) {
      this.showContactTooltip = !this.showContactTooltip;
      return;
    }
    this.showContactTooltip = visible;
  }

  toggleFieldHelper(selectedTooltip: string, visible = false) {
    if (visible) {
      clearTimeout(this.helperTimer);
      this.selectedTooltip = selectedTooltip;
    } else {
      this.helperTimer = setTimeout(() => {
        this.selectedTooltip = '';
      }, 180);
    }
  }

  handleFormSubmit(activeStep) {
    if(activeStep === 1) {
      this.createNewCustomer();
    } else if(activeStep === 2) {
      this.addApiInfo()
    } else if(activeStep === 3) {
      this.addCustomerSurveyInfo();
    } else if(activeStep === 4) {
      this.addUserSurveyInfo();
    }
  }

  removeEmptyValues(obj) {
    for (const key in obj) {
      if (obj[key] === '') {
        delete obj[key];
      }
    }
    return obj;
  }

  //3rd step submit
  async addCustomerSurveyInfo() {
    if (!this.newCustomerForm.valid) {
      this.markFormGroupTouched(this.newCustomerForm);
      return;
    }
    this.isLoading = true;
    this.settingsErrorMsg = '';
    const customerForm = this.newCustomerForm.value;
    customerForm.cid = this.createdCustomer?.cid || this.loggedInUserData?.cid;
    customerForm.email = this.loggedInUserData.email;

    try {
      await this.userInfoService.addCustomerSurvey(this.removeEmptyValues(customerForm));
      this.isLoading = false;
      if (this.externalIntegration) {
        this.currentStep = 2;
        this.activeStep = 4;
      } else {
        this.currentStep = this.activeStep = 4;
      }
      this.renderSurveyFields('user');
      return;
    } catch (err) {
      this.isLoading = false;
      this.setErrorMsg(err.message);
    }
  }

  //4th step
  async addUserSurveyInfo() {
    if (!this.newCustomerForm.valid) {
      this.markFormGroupTouched(this.newCustomerForm);
      return;
    }
    this.isLoading = true;
    this.settingsErrorMsg = '';
    const customerForm = this.newCustomerForm.value;

    try {
      await this.userInfoService.updateUserSurvey(this.removeEmptyValues(customerForm));
      this.isLoading = false;
      this.exitOnboarding();
      return;
    } catch (err) {
      this.isLoading = false;
      this.setErrorMsg(err.message);
    }
  }

  exitOnboarding() {
    if (this.redirectUrl) {
      window.location.href = this.redirectUrl;
    }
    this.closeEvent.emit(true);
  }
}
