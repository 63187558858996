import { GraphConfig } from "src/app/shared/generic-graph/generic-graph.component";
import { UserEntity } from "../models/user.model";
import { CustomerSettingService } from "../services/customer-setting.service";
import { UserPreferencesService } from "../services/user-preferences.service";
import { FieldDescription } from "../shared/generic-form/generic-form.component";
import { updateState } from "./helpers";

export class GraphSettings {

  private graphId: string;

  //--- CUSTOMER level (jarvisml people only)

  public readonly hidden: boolean; // defaults to true for new graphs/customers
  public readonly modelType: string;
  public readonly title: string;
  public readonly subtitle: string;
  public readonly intro: string;
  public readonly outro: string;
  public readonly pinned: boolean; // defaults to false for new graphs/customers
  public readonly favorited: boolean; // defaults to false for new graphs/customers

  //--- USER level
  
  public readonly useLogScale;


  constructor(
    private myGraphConfig: GraphConfig,
    initUserSettings: any,
    initCustomerSettings: any,
    private user: UserEntity,
    private customerSettingService: CustomerSettingService,
    private userPrefService: UserPreferencesService,
    public forceRefreshGraph?: ()=>void
  ) {
    this.graphId = myGraphConfig.id;

    initCustomerSettings = initCustomerSettings || {};
    this.hidden = !(initCustomerSettings.hidden === false); // null or undefined => hidden==true
    this.pinned = initCustomerSettings.pinned ? true : false; // null or undefined => pinned==false
    this.favorited = initCustomerSettings.favorited ? true : false; // null or undefined => favorited==false
    this.modelType = initCustomerSettings.model_type;
    this.title = initCustomerSettings.title;
    this.subtitle = initCustomerSettings.subtitle;
    this.intro = initCustomerSettings.intro;
    this.outro = initCustomerSettings.outro;

    initUserSettings = initUserSettings || {};
    this.useLogScale = initUserSettings.useLogScale; // can be undefined
  }

  public setForceRefresh(forceRefreshGraph: ()=>void) {
    this.forceRefreshGraph = forceRefreshGraph;
  }

  getCustomerSettingList(jarvisml_group: string): FieldDescription[] {
    const list: FieldDescription[] = [];
    if (jarvisml_group === 'platform') {
      list.push({ name: 'hidden', label: 'Hide for this customer', type: 'boolean', init: this.hidden });
      list.push({ name: 'intro', label: 'Intro', type: 'multiline', init: this.intro });
      list.push({ name: 'outro', label: 'Outro', type: 'multiline', init: this.outro });
    }
    if (jarvisml_group) {
      list.push({ name: 'title', label: 'Title', type: 'string', init: this.title });
      list.push({ name: 'subtitle', label: 'Subtitle', type: 'string', init: this.subtitle });
    }
    return list;
  }

  getUserSettingList(): FieldDescription[] {
    const cfg = this.myGraphConfig;
    switch (cfg.type) {
      case 'mygraph':
        const list = [];
        if (cfg['yIsLog'] !== undefined) {
          list.push({ name: 'useLogScale', label: 'Use log scale for this graph', type: 'boolean', init: cfg['yIsLog'] });
        }
        return list;
      default:
        return [];
    }
  }

  public overrideDefault(propertyName: string, value: any) {
    this[propertyName] = value;
  }

  public changeForCustomer(propertyName: string, value: any) {
    const map = { [propertyName]: value };
    this.multiChangeForCustomer(map);
  }

  public multiChangeForCustomer(map: any) {
    const changes = updateState(this, map);
    if (!changes) return;
    this.customerSettingService.modifySettings(this.user.cid, 'dashboard', this.graphId, changes);
    this.forceRefreshGraph();
  }

  public changeForUser(propertyName: string, value: any) {
    const map = { [propertyName]: value };
    this.multiChangeForUser(map);
  }

  public multiChangeForUser(map: any) {
    const changes = updateState(this, map);
    if (!changes) return;
    this.userPrefService.modifySettings(this.user.id, 'dashboard', this.graphId, changes);
    this.forceRefreshGraph();
  }

}
