<div class="darkDiv" (click)="onClickOutside()">
  <div class="form-frame" (click)="onClickInside($event)">

    <div *ngIf="title" class="title">{{title}}</div>

    <input matInput type="text"
      [matAutocomplete]="auto"
      (keyup)="onKeyup($event)"
      (input)="onFilterChange($event)"
      (focus)="$event.target.select()"/>
    <mat-autocomplete #auto="matAutocomplete"
      panelWidth="auto"
      (optionSelected)="onSelectionChange($event)">
      <mat-option *ngFor="let item of filteredItems" [value]="item.display" [id]="item.value">
        {{ item.display }}
      </mat-option>
    </mat-autocomplete>

  </div>
</div>
