import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle'; 
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatRadioModule } from '@angular/material/radio';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepperModule } from '@angular/material/stepper';

import { ClipboardModule } from '@angular/cdk/clipboard';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { LayoutModule } from '@angular/cdk/layout';

import { PlotlyViaWindowModule } from 'angular-plotly.js';

import { BlockComponent } from './block/block.component';
import { ButtonComponent } from './button/button.component';
import { ChipComponent } from './chip/chip.component';
import { CompanyLinksComponent } from './company-links/company-links.component';
import { DeleteButtonComponent } from './delete-button/delete-button.component';
import { EditClassesComponent } from './edit-classes/edit-classes.component';
import { FeedbackButtonComponent } from './feedback-button/feedback-button.component';
import { FileDetailComponent } from './file-detail/file-detail.component';
import { FileListComponent } from './file-list/file-list.component';
import { FilterSelectorComponent } from './filter-selector/filter-selector.component';
import { MsgBoxComponent } from './msg-box/msg-box.component';
import { NavigationBarComponent } from './navigation-bar/navigation-bar.component';
import { PageIndexComponent } from './page-index/page-index.component';
import { StudyFormNewComponent } from './study-form-new/study-form-new.component';
import { StudyFormEditComponent } from './study-form-edit/study-form-edit.component';
import { ShellComponent } from './shell/shell.component';
import { SafeHtmlPipe } from './safe-html/safe-html.pipe';
import { UploadButtonComponent } from './upload-button/upload-button.component';
import { ImageGraphComponent } from './image-graph/image-graph.component';
import { ImagePreviewComponent } from './image-preview/image-preview.component';
import { GenericFormComponent } from './generic-form/generic-form.component';
import { MygraphComponent } from './mygraph/mygraph.component';
import { StatsCardComponent } from './stats-card/stats-card.component';
import { StatsCardSmallComponent } from './stats-card-small/stats-card-small.component';
import { DataInsightCardComponent } from './data-insight-card/data-insight-card.component';
import { TableGraphComponent } from 'src/app/shared/table-graph/table-graph.component';
import { TemplateCardComponent } from './template-card/template-card.component';
import { GenericSelectorComponent } from './generic-selector/generic-selector.component';
import { GraphPickerComponent } from './graph-picker/graph-picker.component';
import { GenericGraphComponent } from 'src/app/shared/generic-graph/generic-graph.component';
import { GoogleSigninDirective } from './google-signin.directive';
import { MenuActionComponent } from './menu-action/menu-action.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { GraphVisibilityComponent } from './graph-visibility/graph-visibility.component';
import { ModalLoggerComponent } from './modal-logger/modal-logger.component';
import { MarkdownModule } from 'ngx-markdown';
import { TabVisibilityComponent } from './tab-visibility/tab-visibility.component';
import { ShowErrorComponent } from './show-error/show-error.component';
import { PropertyCardComponent } from './property-card/property-card.component';
import { ProductCardComponent } from './product-card/product-card.component';
import { ToastComponent } from './toast/toast.component';
import { JarvisChartComponent } from './jarvis-chart/jarvis-chart.component';
import { SummaryTableComponent } from './summary-table/summary-table.component';
import { JarvisGridViewComponent } from './jarvis-grid-view/jarvis-grid-image-graph.component';
import { UpdatePriceModalComponent } from './update-price-modal/update-price-modal.component';
import { AddCustomerFormComponent } from './add-customer-form/add-customer-form.component';
import { SubmenuComponent } from './submenu/submenu.component';
import { AppPasswordRulesComponent } from './app-password-rules/app-password-rules.component';
import { HospitalityListingCardComponent } from './hospitality-listing-card/hospitality-listing-card.component';


const components = [
  BlockComponent,
  ButtonComponent,
  ChipComponent,
  CompanyLinksComponent,
  GenericSelectorComponent,
  DeleteButtonComponent,
  EditClassesComponent,
  FeedbackButtonComponent,
  GenericFormComponent,
  GenericGraphComponent,
  GoogleSigninDirective,
  GraphPickerComponent,
  MygraphComponent,
  ImageGraphComponent,
  FileListComponent,
  FileDetailComponent,
  FilterSelectorComponent,
  ImagePreviewComponent,
  StudyFormEditComponent,
  StudyFormNewComponent,
  TableGraphComponent,
  MenuActionComponent,
  ModalLoggerComponent,
  MsgBoxComponent,
  NavigationBarComponent,
  PageIndexComponent,
  ShellComponent,
  ShowErrorComponent,
  SafeHtmlPipe,
  SpinnerComponent,
  StatsCardComponent,
  StatsCardSmallComponent,
  DataInsightCardComponent,
  TabVisibilityComponent,
  TemplateCardComponent,
  UploadButtonComponent,
  GraphVisibilityComponent,
  PropertyCardComponent,
  ProductCardComponent,
  ToastComponent,
  JarvisChartComponent,
  AddCustomerFormComponent,
  SubmenuComponent,
  AppPasswordRulesComponent,
  HospitalityListingCardComponent
];

const modules = [
  CommonModule,
  ClipboardModule,
  DragDropModule,
  LayoutModule,
  PlotlyViaWindowModule,
  HttpClientModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  MatToolbarModule,
  MatIconModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatExpansionModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatSelectModule,
  MatSidenavModule,
  MatListModule,
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,
  MatMenuModule,
  MatCardModule,
  MatCheckboxModule,
  MatFormFieldModule,
  MatInputModule,
  MatRadioModule,
  MatSnackBarModule,
  MatTooltipModule,
  MatProgressSpinnerModule,
  MatAutocompleteModule,
  MatChipsModule,
  NgxSkeletonLoaderModule,
  MatSlideToggleModule,
  MarkdownModule,
  MatStepperModule,
];

@NgModule({
  declarations: [
    ...components,
    SummaryTableComponent,
    JarvisGridViewComponent,
    UpdatePriceModalComponent,
  ],
  imports: [
    ...modules,
  ],
  exports: [
    ...components,
    ...modules,
  ],
})
export class SharedModule {}
