import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';

import { DeeplinkService } from 'src/app/services/deeplink.service';
import { PageService } from 'src/app/services/page.service';
import { UserService } from 'src/app/services/user.service';
import { CustomerService } from 'src/app/services/customer.service';
import { UserEntity } from 'src/app/models/user.model';
import { CustomerEntity } from 'src/app/models/customer.model';
import { MyGraphConfig } from 'src/app/shared/mygraph/mygraph.component';
import { ImageGraphConfig } from 'src/app/shared/image-graph/image-graph.component';
import { TableGraphConfig } from 'src/app/shared/table-graph/table-graph.component';


@Component({
  selector: 'app-home-recommendations',
  templateUrl: './home-recommendations.component.html',
  styleUrls: ['./home-recommendations.component.scss']
})
export class HomeRecommendationsComponent implements OnInit {

  @ViewChild('sectionsContainer', { read: ViewContainerRef })
  set sectionsContainer(container: ViewContainerRef) {
    this.graphContainer = container;
  }
  private graphContainer: ViewContainerRef;

  public missingSetup = false;
  public missingRecommendationsSetup = false;
  public user: UserEntity;
  public customer: CustomerEntity;

  urlCommonParams: string;


  constructor(
    public pageService: PageService,
    private deeplinkService: DeeplinkService,
    private userService: UserService,
    private customerService: CustomerService,
  ) {
    this.deeplinkService.parseRoute('/recommendations');
  }

  private addSection(title: string): string {
    this.pageService.createSection(title, { container: this.graphContainer });
    return title;
  }

  private addConfig(sectionTitle: string,
    cfg: MyGraphConfig|ImageGraphConfig|TableGraphConfig
  ) {
    this.pageService.createGenericGraph(cfg, { section: sectionTitle });
  }

  async ngOnInit() {    
    const user = this.user = await this.userService.getUser();
    const customer = this.customer = await this.customerService.getCustomer(user.cid);

    if (!customer.business_id) {
      this.missingSetup = true;
      return;
    }
  
    await this.pageService.startPage('recommendations', {});

    this.createGraphs(customer);
  }

  createGraphs(customer: CustomerEntity) {
    this.urlCommonParams = `cid=${this.user.cid}`;

    const recommendationsSection = this.addSection('Recommendations');

    // TODO: Make twiddy generic and remove business_id = 12
    if ((customer.verticalName && customer.verticalName === "hospitality")) {
      this.createGraphsHospitality(recommendationsSection);
    } else {
      // Recommendations not available yet for this customer
      this.missingRecommendationsSetup = true;
    }
  }

  private async createGraphsHospitality(recommendationsSection: string) {
    // Set currency based on customer
    const currencyPrefix = this.customer.currencyPrefix ? this.customer.currencyPrefix : "$" ;

    this.addConfig(recommendationsSection, {
      type: 'table',
      id: 'hospitalityPropertyRecommendationsTable', 
      title: 'Predicted 365 day Revenue and Recommendations per Guest',
      url: `/hospitality_ltv_recos/ltv_and_recos?${this.urlCommonParams}`,
      customClassName: "full-content-height-table full-content-width-table",
      columns: [
        {columnKey: "customer_id", header: "Customer ID", size: "xxlarge-field"},
        {columnKey: "ltv", header: "Life Time Value", size: "large-field", prefix: currencyPrefix, useTwoDecimalPipe: true},
        {columnKey: "first_recommendation", header:"1st Recommendation", size: "large-field"},
        {columnKey: "second_recommendation", header:"2nd Recommendation", size: "large-field"},
        {columnKey: "third_recommendation", header:"3rd Recommendation", size: "large-field"},
        {columnKey: "fourth_recommendation", header:"4th Recommendation", size: "large-field"},
        {columnKey: "fifth_recommendation", header:"5th Recommendation", size: "large-field"},
        {columnKey: "sixth_recommendation", header:"6th Recommendation", size: "large-field"},
        {columnKey: "seventh_recommendation", header:"7th Recommendation", size: "large-field"},
        {columnKey: "eighth_recommendation", header:"8th Recommendation", size: "large-field"},
        {columnKey: "nineth_recommendation", header:"9th Recommendation", size: "large-field"},
        {columnKey: "tenth_recommendation", header:"10th Recommendation", size: "large-field"},
      ],
      searchable: true,
      showExportDataBtn: true,
      displayedColumns: [
        "customer_id", "ltv", "first_recommendation", "second_recommendation", "third_recommendation", "fourth_recommendation","fifth_recommendation","sixth_recommendation",
        "seventh_recommendation", "eighth_recommendation", "nineth_recommendation", "tenth_recommendation"
      ],
    });

    this.addConfig(recommendationsSection, {
      type: 'table',
      id: 'customerRecommendationsPerProperty',
      title: 'Customer Recommendations Per Property',
      url: `/hospitality_recommendations/customer_recommendations?${this.urlCommonParams}`,
      columns: [
        {columnKey:"listing_id", header: "Listing Id", size: "large-field"},
        {columnKey:"first_recommendation", header: "Customer 1", size: "large-field"},
        {columnKey:"second_recommendation", header: "Customer 2", size: "large-field"},
        {columnKey:"third_recommendation", header: "Customer 3", size: "large-field"},
      ],
      searchable: true,
      showExportDataBtn: true,
      displayedColumns: ["listing_id", "first_recommendation", "second_recommendation", "third_recommendation"],
    });
  }
}
