<div *ngIf="isVisible()" [class]="size == 'small' ? 'genericGraphSmall' : 'genericGraph'">
  <div class="intro" *ngIf="config && config.settings.intro && size != 'small'" [innerHTML]="getIntroHtml()"></div>
  

  <div class="graphAndEditBtn">
    <ng-container *ngIf="loading && (config.type !== 'chart' && config.type !== 'summaryTable'  && config.type !== ' grid' )  ">
      <div class="skeleton-wrapper">
        <ngx-skeleton-loader *ngFor="let s of skeleton" [theme]="s"></ngx-skeleton-loader>
      </div>
    </ng-container>
    <div class="flex-grow " [ngClass]="config.type === 'mygraph' && !placeholderText ? 'full-width-graph' : 'specificGraph'">
      <mygraph *ngIf="config.type === 'mygraph'" [config]="config" [parent]="this" [inputParams]="inputParams"
        [size]="size">
      </mygraph>
      <app-summary-table   *ngIf="config.type === 'summaryTable'" [config]="config" [parent]="this" [inputParams]="inputParams"></app-summary-table>
      <app-jarvis-chart *ngIf="config.type === 'chart'" [config]="config" [parent]="this" [inputParams]="inputParams">
      </app-jarvis-chart>
      <image-graph *ngIf="config.type === 'image'" [config]="config" [parent]="this" [inputParams]="inputParams">
      </image-graph>
      <table-graph *ngIf="config.type === 'table'" [config]="config" [parent]="this" [inputParams]="inputParams">
      </table-graph>
      <app-jarvis-grid-image-graph *ngIf="config.type === ' grid'" [config]="config" [parent]="this" [inputParams]="inputParams"></app-jarvis-grid-image-graph>
    </div>

    <div *ngIf="placeholderText && placeholderIsError" class="graphError">
      <div class="text" [innerHTML]="placeholderText"></div>
    </div>

    <div class="buttonBar mx-4">
      <button *ngIf="size != 'small' && canEdit()" mat-icon-button class="editButton" (click)="editAction()">
        <mat-icon>more_horiz</mat-icon>
      </button>
  
      <button *ngIf="hasHelp()" mat-icon-button (click)="showHelp()">
        <mat-icon>help_outline</mat-icon>
      </button>

      <button *ngIf="config.showPinButton && !config.settings?.pinned" mat-icon-button (click)="onPinClick()">
        <mat-icon>push_pin</mat-icon>
      </button>

      <button *ngIf="config.showFavoriteButton && !config.settings?.favorited" mat-icon-button
        (click)="onUserFavorited()">
        <mat-icon>stars</mat-icon>
      </button>

      <button *ngIf="config.showPinButton && config.settings?.pinned" mat-icon-button (click)="onRemovePinClick()">
        <mat-icon>remove_circle</mat-icon>
      </button>


      <feedback-button *ngIf="size != 'small'" [interfaceId]="config.id"></feedback-button>

      <button *ngIf="hasDeepLink() && size != 'small'" mat-icon-button (click)="copyDeepLink()">
        <mat-icon>link</mat-icon>
      </button>

      <button *ngIf="canExport() && config.showExportDataBtn" mat-icon-button (click)="exportTableData()">
        <mat-icon>file_download</mat-icon>
      </button>
    </div>

  </div>

  <div class="outro" *ngIf="config && config.settings.outro && size != 'small'" [innerHTML]="getOutroHtml()"></div>
</div>
