import { Component, OnInit, Input, SimpleChanges, ViewChild, OnChanges } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { BackendDataService } from 'src/app/services/backend-data.service';
import { DeeplinkService } from 'src/app/services/deeplink.service';
import { GenericGraphComponent, GraphConfig, InputParams } from '../generic-graph/generic-graph.component';
import { filterByDateRange, generateCumulativeRows } from 'src/app/util/helpers';


export interface TableGraphConfig extends GraphConfig {
  type: 'table';
  columns: ColumnDef[];
  searchable: boolean;
  displayedColumns: string[];
  isPivotTable?: boolean;
  showStatusColors?: boolean;
  textAlign?: string; // center, start or end (default is start); see also `ColumnDef.align`
  hasFixedColumns?: boolean; // Used for keeping one column position constant
  showHorizontalScrollControls?: boolean;
  searchColumns?: string[]; // Only search certain columns
  requestHandler?: (filterConfig: { [key: string]: any }, graph: TableGraphComponent)=>string;
  responseHandler?: (table: TableGraphComponent, tableData: any[])=>any[];
  searchHandler?: any; // Custom search handler for some tables
  rawDataHandler?: any; // Format rawData response received from backend
  pageSizeOptions?: number[];
  customClassName?: string;
  dropdownKeys?: string[];
  isComparisonTable?: boolean;
  rowHover?: boolean
  useNewEndPoint?: boolean
  baseUrl?: string
}

export interface ColumnDef {
  align?: string; // center, start or end (default is set by textAlign at table level)
  alwaysGroup?: boolean;
  columnKey: string;
  groupable?: boolean;
  header: string;
  isDynamicColumn?: boolean;
  isFixedColumn?: boolean;
  isComparisonColumn?: boolean;
  firstScrollColumn?: boolean;
  isUrl?: boolean;
  openinNewTab?: boolean;
  hasImage?: boolean;
  prefix?: string;
  suffix?: string;
  size: 'xxxlarge-field'|'xxlarge-field'|'xlarge-field'|'large-field'|
        'medium-field'|'small-field'|'mini-field'|
        'half-field'|'fill-remaining-field';
  useIntegerPipe?: boolean;
  useNumberPipe?: boolean;
  useTwoDecimalPipe?: boolean;
  useBoldRow?: boolean;
  centerHeader? : boolean;
  showDetailsIcon?: string; //Show info-icon/tooltip for table headers
  showModal?: boolean
}


@Component({
  selector: 'table-graph',
  templateUrl: './table-graph.component.html',
  styleUrls: ['./table-graph.component.scss']
})
export class TableGraphComponent implements OnInit, OnChanges {

  @Input() config: TableGraphConfig;
  @Input() parent: GenericGraphComponent;
  @Input() inputParams: InputParams;

  public tableData: any[];
  public dataSource: MatTableDataSource<any[]>;
  public loading: boolean;
  
  @ViewChild(MatSort, { read: MatSort })
  set sorter(sort: MatSort) {
    this.sort = sort;
    if (sort && this.dataSource) {
      this.dataSource.sort = sort;
    }
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  
  sort: MatSort;
  showPaginator: boolean;

  currentFilter = '';
  applyFilterTimeout: any;
  isWorking: boolean;

  expandedColumns = new Set();
  isInitialized = false;


  constructor(
    private deeplinkService: DeeplinkService,
    private backendDataService: BackendDataService
  ) { }

  async ngOnInit() {
    this.isInitialized = true;

    this.updateTable();
  }

  // TODO: delete this if we confirm this is not used; what about modifyTableColumns?
  async ngOnChanges(_changes: SimpleChanges) {
    if (!this.isInitialized) {
      return; // NB: we get 1 "ngOnChanges" before "ngOnInit"
    }

    if (this.config?.isPivotTable) {
      this.modifyTableColumns();
    }

    this.updateTable();
  }

  checkValue(val: any): any { return typeof val }

  onFilterKeyup(filter: string) {
    this.isWorking = true; // shows spinner
    // Average human typing speed is around 1 character per 300ms
    // We wait longer so search has more chances to start when user is done typing
    clearTimeout(this.applyFilterTimeout); // if a key was typed earlier, we forget about it and wait again
    this.applyFilterTimeout = setTimeout(() => {
      this.applyFilter(filter);
      this.isWorking = false; // NB: this does not remove the spinner from UI until heavy changes are completed; this is what we want
    }, 600);
  }

  // Filter all table columns by query
  applyFilter(filterValue: string) {
    const query = filterValue.toLowerCase();
    if (query === this.currentFilter) return;
    this.currentFilter = query;

    let filteredData = [];
    
    if (query.length) {
      for (const item of this.tableData) {
        if (this.config.searchColumns) {
          for (const columnKey of this.config.searchColumns) {
            const columnVal = item[columnKey];
            const itemVal = (typeof columnVal === "string") ? columnVal.toLowerCase() : (columnVal.displayValue ? columnVal.displayValue.toLowerCase() : String(columnVal));
            if (itemVal.includes(query)) {
              filteredData.push(item);
              break;
            }
          }
        } else {
          for (const value of Object.values(item)) {
            const itemVal = (typeof value === "string") ? value.toLowerCase() : String(value);
            if (itemVal.includes(query)) {
              filteredData.push(item);
              break;
            }
          }
        }      
      }
    } else {
      filteredData = this.tableData;
    }

    // If we have a custom search handler
    if (this.config.searchHandler) {
      filteredData = this.config.searchHandler(filteredData, query);
    }

    // From my (olivier) tests, clearing data first is more efficient than (1) replace data + (2) reset paginator (or in reverse order)
    this.dataSource.data = []; // NB: paginator.firstPage() is not needed since removing all data does it
    this.dataSource.data = filteredData;
  }

  onLeftScroll() {
    // TODO: Improve this
    const tableGraphDiv = document.getElementsByClassName('jarvis-table');
    const tableDiv =  (tableGraphDiv.length > 1) ? tableGraphDiv[0] : tableGraphDiv[1];
    tableDiv.scrollLeft -= 846.5;
  }

  onRightScroll() {
    // TODO: [PLAT-377] Improve this
    const tableGraphDiv = document.getElementsByClassName('jarvis-table');
    const tableDiv =  (tableGraphDiv.length > 1) ? tableGraphDiv[0] : tableGraphDiv[1];
    tableDiv.scrollLeft += 846.5;
  }

  exportGroupedData() {
    let csvContent = "data:text/csv;charset=utf-8,";

    const dynamicColumns = this.getDynamicColumns(true);
    const columnGroupingMap = this.getColumnGroupingMap();

    const groupOrder = [];
    const expandedColumns = Array.from(this.expandedColumns);
    for (const expandedCol of expandedColumns) {
      columnGroupingMap[String(expandedCol)] = true;
    }

    for (const columnType of this.config.displayedColumns) {
      if (dynamicColumns.indexOf(String(columnType)) > -1) {
        groupOrder.push(columnType);
      }
    }
    const result = this.groupTableDataByYear(dynamicColumns, groupOrder, columnGroupingMap);

    const displayedColumns = this.config.displayedColumns;
    displayedColumns.forEach(function(columnName) {
      csvContent += columnName + ",";
    });
    csvContent += "\r\n";

    result.forEach(function(tableRow) {
      displayedColumns.forEach(function(columnKey) {
        csvContent += tableRow[columnKey] + ",";
      });
      csvContent += "\r\n";
    });
    
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", this.config.title ? (this.config.title + ".csv") : "TableData.csv");
    document.body.appendChild(link); // Required for FF

    link.click();
  }

  exportUngroupedData() {
    let csvContent = "data:text/csv;charset=utf-8,";
    const displayedColumns = this.config.displayedColumns;
    displayedColumns.forEach(function(columnName) {
      csvContent += columnName + ",";
    });
    csvContent += "\r\n";

    this.tableData.forEach(function(tableRow) {
      displayedColumns.forEach(function(columnKey) {
        csvContent += tableRow[columnKey]?.value ? tableRow[columnKey]?.value + "," : tableRow[columnKey] + ",";
      });
      csvContent += "\r\n";
    });
    
    const encodedUri = encodeURI(csvContent).replace(/#/g, '%23');
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", this.config.title ? (this.config.title + "All.csv") : "TableDataAll.csv");
    document.body.appendChild(link); // Required for FF

    link.click();
  }

  getColumnGroupingMap() {
    const columnGroupingMap = {};

    for (const column of this.config.columns) {
      if (column.alwaysGroup) {
        columnGroupingMap[column.columnKey] = true;
      } else if (column.isDynamicColumn) {
        columnGroupingMap[column.columnKey] = false;
      }
    }
    return columnGroupingMap;
  }

  getDynamicColumns(alwaysGroupFields: boolean): string[] {
    const filteredColumns = this.config.columns.filter(function (column) {
      if (column.isDynamicColumn || column.alwaysGroup == alwaysGroupFields) {
        return column.columnKey;
      }
    });
    const dynamicColumns = filteredColumns.map(function (column) {
      return column.columnKey;
    });
    return dynamicColumns;
  }

  onColumnExpand(index) {
    // Group list of items in the order based on the columns present
    const dynamicColumns = this.getDynamicColumns(true);
    const columnGroupingMap = this.getColumnGroupingMap();
    const groupOrder = [];
    const columnKey = this.config.columns[index].columnKey;
    if (this.expandedColumns.has(columnKey)) {
      this.expandedColumns.delete(columnKey);
    } else if (columnKey != "year") {
      // Don't add year as the results are grouped using year by default
      this.expandedColumns.add(columnKey);
      columnGroupingMap[columnKey] = true;
    }
    const expandedColumns = Array.from(this.expandedColumns);
    for (const expandedCol of expandedColumns) {
      columnGroupingMap[String(expandedCol)] = true;
    }
    for (const columnType of this.config.displayedColumns) {
      if (dynamicColumns.indexOf(String(columnType)) > -1) {
        groupOrder.push(columnType);
      }
    }
    const result = this.groupTableDataByYear(dynamicColumns, groupOrder, columnGroupingMap);
    this.dataSource = new MatTableDataSource(result);
    this.setPaginator(result.length);
  }

  onColumnClose(index) {
    const groupOrder = [];
    const columnGroupingMap = this.getColumnGroupingMap();
    const dynamicColumns = this.getDynamicColumns(true);
    const columnKey = this.config.columns[index].columnKey;
    if (this.expandedColumns.has(columnKey)) {
      this.expandedColumns.delete(columnKey);
    } else if (columnKey != "year") {
      this.expandedColumns.delete(columnKey);
      columnGroupingMap[columnKey] = false;
    }
    const expandedColumns = Array.from(this.expandedColumns);
    for (const expandedCol of expandedColumns) {
      columnGroupingMap[String(expandedCol)] = false;
      
    }
    for (const columnType of this.config.displayedColumns) {
      if (dynamicColumns.indexOf(String(columnType)) > -1) {
        groupOrder.pop();
      }
      const result = this.groupTableDataByYear(dynamicColumns, groupOrder, columnGroupingMap);
      this.dataSource = new MatTableDataSource(result)
    }
  }
  
  private groupTableDataByYear(dynamicColumns: any[], groupOrder: string[], columnGroupingMap: any) {
    let result = [];
    if (groupOrder.length > 0) {
      const modifiedData = this.groupByYear(this.tableData);
      // Update table
      for (const element of modifiedData) {
        const dataRows = this.groupByColumn(element.values, groupOrder, columnGroupingMap);
        const firstRow = dataRows.shift();
        const firstItem = {
          year: element.year
        };
        Object.assign(firstItem, firstRow);
        result.push(firstItem);
        for (const row of dataRows) {
          const dataItem = {
            year: ""
          };
          Object.assign(dataItem, row);
          result.push(dataItem);
        }
      }
    } else {
      // Group by year
      result = generateCumulativeRows(this.tableData, dynamicColumns);
    }
    return result;
  }

  private groupByYear(rawData: any[]) {
    const yearMap = {};

    rawData.forEach((item) => {
      const newItem = Object.assign({}, item);
      // If Group by overall is selected year may not be send by backend so group as All
      const yearNumber = newItem.year ? newItem.year : "All";
      delete newItem.year;
      let result = [];
      if (yearMap[yearNumber]) {
        result = yearMap[yearNumber];
      }
      result.push(newItem);
      yearMap[yearNumber] = result; 
    });

    const modifiedData = [];
    for (const yearItem in yearMap) {
      modifiedData.push({
        year: yearItem,
        values: yearMap[yearItem]
      });
    }
    return modifiedData;
  }

  private groupByColumn(rawData: any[], groupOrder: string[], columnGroupingMap: any) {
    const modifiedData = [];

    if (this.inputParams.featureTypes?.date_part === "OVERALL") {
      // Backend sends response as Total so change it to All when group overall is selected
      const firstItem = rawData.shift();
      firstItem["date_part"] = "All";
      rawData.forEach((item) => {
        item["date_part"] = "";
      });
      rawData.unshift(firstItem);
    }
    if (!groupOrder.length) {
      return rawData;
    }    
    // Remove the groupitem as it's already grouped
    const nextGroupOrder = [...groupOrder];
    const groupItem = nextGroupOrder.shift();
    const columnValuesMap = {};
    rawData.forEach((item) => {
      if (!item[groupItem] || item[groupItem] == null || !columnGroupingMap[groupItem]) {
        columnValuesMap["All"] = [];
      } else if (item[groupItem] && columnGroupingMap[groupItem]) {
        columnValuesMap[item[groupItem]] = [];
      }
    });
    rawData.forEach((item) => {
      let columnValue = item[groupItem];
      if (!columnValue) {
        columnValue = "All";
      }
      delete item[groupItem];
      // Push the item if grouping is needed or if it is cumulative row
      if (columnGroupingMap[groupItem]) {
        columnValuesMap[columnValue].push(item);
      } else if (columnValue === "All" && !columnGroupingMap[groupItem]) {
        // Grouping not allowed for this column so add the cumulative row
        columnValuesMap[columnValue].push(item);
      }
    });

    // Sum values if there are no records with 'All' row
    if (columnValuesMap["All"] && columnValuesMap["All"].length === 0) {
      const tempData = rawData;
      const firstItem = tempData.shift();
      tempData.forEach((item) => {
        // Individually add values of each column
        for (const columnKey in item) {
          firstItem[columnKey] += item[columnKey];
        }
      });
      columnValuesMap["All"].push(firstItem);
    }
    
    // Iterate map and flatten the object
    for (const columnItem in columnValuesMap) {
      const firstItem = {};
      firstItem[groupItem] = columnItem;
      
      const dataRows = this.groupByColumn(columnValuesMap[columnItem], nextGroupOrder, columnGroupingMap);
      const firstRow = dataRows.shift();
      Object.assign(firstItem, firstRow);
      modifiedData.push(firstItem);

      for (const row of dataRows) {
        const dataItem = {};
        dataItem[groupItem] = "";
        Object.assign(dataItem, row);
        modifiedData.push(dataItem);
      }
    }
    return modifiedData;
  }
 
  private updateTable() {
    this.loading = true;
    this.parent.showLoading();
    this.dataSource = null;

    setTimeout(() => this.fetchTableData(), 300);
  }

  private async fetchTableData(): Promise<void> {
    try {
      let tableUrl = this.config.url;

      // TODO: pass inputParams thru instead of filterConfig
      const filterConfig = {};
      
      if(this.inputParams.days) {
        filterConfig["selectedDays"] = this.inputParams.days;
      }
      if(this.inputParams.pageType) {
        filterConfig["selectedPageType"] = this.inputParams.pageType;
      }
      if(this.inputParams.featureTypes) {
        filterConfig["featureTypes"] = this.inputParams.featureTypes; // when all is cleaned up we can just copy the whole object
      }
      if(this.inputParams.startDate) {
        filterConfig["startDate"] = this.inputParams.startDate;
      }
      if(this.inputParams.endDate) {
        filterConfig["endDate"] = this.inputParams.endDate;
      }

      // Some tables require processing before request is made
      if (this.config.requestHandler) {
        const requestUrl = this.config.requestHandler(filterConfig, this);
        if(requestUrl){
          tableUrl = requestUrl;
        }
      }

      let tableData;
      if (tableUrl) {
        tableData = await this.backendDataService.fetchJsonData(tableUrl, this.config.useNewEndPoint, this.config.baseUrl); // if useNewEndPoint is not there in the config, it will use the old backendBaseUrl
      } else {
        console.error(`Empty url for ${this.config.title}`);
        tableData = [];
      }
      if (this.inputParams.startDate && this.inputParams.endDate) {
        this.tableData = filterByDateRange(tableData, this.inputParams.startDate, this.inputParams.endDate);
      } else {
        this.tableData = tableData;
      }

      // Some tables require processing on raw data
      if (this.config.rawDataHandler) {
        this.tableData = this.config.rawDataHandler(tableData);
      }

      let modifiedData = this.tableData;

      // Some tables require processing after response is received
      if (this.config.responseHandler) {
        modifiedData = this.config.responseHandler(this, modifiedData);
      }

      if (tableData.length > 0) {
        this.dataSource = new MatTableDataSource(modifiedData);
        this.setPaginator(tableData.length);
        this.parent.showData();
      } else {
        this.parent.showNoData();
      }
    } catch (error) {
      this.parent.showFailure(error);
    }
    this.loading = false;
  }

  displayedCol: any; 
  selectedData: any;
  showModal = false;

  openModal(data) {
    if(this.config.rowHover){
      this.selectedData = data;
      this.showModal = true;
    }
  }
  
  closeModal(){
    this.showModal = false;
    this.selectedData = {}
  }

  private setPaginator(totalRowCount: number) {
    const pageSize = this.config.pageSizeOptions ? this.config.pageSizeOptions[0] : 10;
    if (totalRowCount > pageSize) {
      this.dataSource.paginator = this.paginator;
      this.showPaginator = true;
    } else {
      this.dataSource.paginator = null;
      this.showPaginator = false;
    }
  }

  private modifyTableColumns() {
    if(!this.config.dropdownKeys) {
      return;
    }

    const dynamicColumns = this.getDynamicColumns(false);
    const displayedColumns = this.config.displayedColumns;
    
    this.config.dropdownKeys.forEach((element, index) => {
      const itemIndex = displayedColumns.indexOf(dynamicColumns[index]);
      if (this.inputParams.featureTypes[element] && this.inputParams.featureTypes[element].length) {
        // Show column in table
        if (itemIndex < 0) {
          displayedColumns.splice(2, 0, dynamicColumns[index]);
        }
      } else {
        // Remove if the column is already present and no options are selected inside dropdown
        if (itemIndex >= 0) {
          displayedColumns.splice(itemIndex, 1);
        }
      }
    });
    this.expandedColumns.clear();
    this.config.displayedColumns = displayedColumns;
  }

  public onClickUrl(url: string) {
    if (!url) return console.warn('missing URL');
    this.deeplinkService.navigateToUrl(url);
  }
}
