<div class="flex h-full">
    <div class="flex flex-row w-full">
        <div
            class='hidden lg:flex flex-col items-center justify-center bg-yellow-300 lg:p-8 xl:p-12 w-1/2'>
            <div class='space-y-5'>
                <h1 class="lg:text-5xl xl:text-7xl xl:leading-snug font-extrabold text-center">You're all set</h1>
                <div class="flex justify-center">
                    <svg class="gear-icon" fill="#000000" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
                        width="12rem" height="12rem" viewBox="0 0 579.16 579.16"
                        xml:space="preserve">
                    <g>
                        <g>
                            <path d="M569.979,353.841h9.181v-9.18v-107.1v-9.18h-9.181H497.1c-4.287-14.669-10.128-28.859-17.433-42.338l51.46-51.46
                                l6.49-6.49l-6.49-6.49l-75.731-75.732l-6.49-6.494l-6.491,6.494l-51.649,51.649c-12.781-6.848-26.182-12.399-39.985-16.561V9.182
                                v-9.18h-9.18H234.5h-9.18v9.18v71.781c-13.109,3.954-25.839,9.146-37.993,15.49l-50.582-50.582l-6.49-6.494l-6.49,6.494
                                L48.033,121.6l-6.49,6.49l6.49,6.491l49.483,49.483c-7.316,13.155-13.225,26.98-17.631,41.255H9.18H0v9.18v107.1v9.181h9.18H79
                                c4.495,15.379,10.704,30.232,18.522,44.309l-49.489,49.489l-6.49,6.49l6.49,6.49l75.732,75.732l6.49,6.493l6.49-6.493
                                l50.579-50.579c13.06,6.833,26.821,12.316,41.056,16.358v70.909v9.181h9.18H344.66h9.18v-9.181v-72.724
                                c12.727-4.033,25.102-9.263,36.931-15.608l51.645,51.64l6.49,6.494l6.49-6.494l75.731-75.731l6.49-6.49l-6.49-6.49l-51.46-51.46
                                c6.814-12.567,12.356-25.731,16.542-39.278h73.771V353.841z M418.1,289.582c0,71.711-58.339,130.05-130.05,130.05
                                S158,361.292,158,289.582s58.339-130.05,130.05-130.05S418.1,217.871,418.1,289.582z"/>
                        </g>
                    </g>
                    </svg>
                </div>
                <h1 class="lg:text-3xl xl:text-5xl xl:leading-snug font-extrabold text-center">Our machines are at work</h1>
                <div class="lg:text-xl xl:text-2xl xl:leading-snug text-center">You can expect a confirmation email once your data is finished processing, usually within 12 hours</div>
            </div>
        </div>
        <div class="flex flex-1 flex-col items-center justify-center px-10 relative bg-gray-100">
            <div class="carousel-container m-16">
                <img class="w-96 h-auto" src="assets/aidaptive_logo.svg" />
            </div>
            <div class="action-btn-container w-full justify-evenly flex">
                <a jarvis-button color="button-color-modest" href="https://aidaptive.com/blog/" target="_blank" type="button"> Blog </a>
                <a jarvis-button color="button-color-modest" href="https://aidaptive.com/" target="_blank"  type="button"> Homepage </a>
                <button jarvis-button color="button-color-modest" (click)="navigateTo('/settings#tab#journey')" type="button"> View Progress </button>
            </div>
        </div>
    </div>
</div>