import { Component, Input } from '@angular/core';

import { UserEntity } from 'src/app/models/user.model';
import { FormService } from 'src/app/services/form.service';
import { FieldDescription, GenericFormChange } from '../generic-form/generic-form.component';
import { GraphConfig } from '../generic-graph/generic-graph.component';

@Component({
  selector: 'graph-visibility',
  templateUrl: './graph-visibility.component.html',
  styleUrls: ['./graph-visibility.component.scss']
})
export class GraphVisibilityComponent {
  @Input() config: GraphConfig[];
  @Input() user: UserEntity;


  constructor(
    private formService: FormService,
  ) {}

  async showUnhideForm() {
    const formArr: FieldDescription[] = [];
    for (const graphData of this.config) {
      formArr.push({
        name: graphData.id,
        label: graphData.title,
        type: 'boolean',
        init: !graphData.settings.hidden,
        data: graphData
      });
    }
    const changes = await this.formService.showForm(`Edit Graphs Visibility`, formArr, { returnsChanges: true });
    if (changes) { // NB: changes is null if user cancelled
      for (const { value, field } of Object.values<GenericFormChange>(changes)) {
        field.data.settings.changeForCustomer('hidden', !value);
      }
    }
  }
}