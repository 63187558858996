import { AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';

import humanizeDuration from 'humanize-duration';
import { GraphCurveConfig } from '../shared/mygraph/mygraph.component';
import { FieldDescription } from '../shared/generic-form/generic-form.component';
import { Timestamp } from 'firebase/firestore';

export const SUPPORT_EMAIL = 'support@aidaptive.com';
export const COMPANY_NAME = 'Aidaptive';

/**
 * Get data for all documents in a collection
 * @param collection - a Firestore collection
 * @returns an array of all docs in collection
 */
export async function getAllDocs<T>(collection: AngularFirestoreCollection<T>): Promise<T[]> {
  const snapshot = await collection.get().toPromise();
  return snapshot.docs.map(doc => (<T>{ id: doc.id, ...doc.data() }));
}

/**
 * Get a Firestore document's data
 * @param doc - a Firestore doc ref (returned by the `doc` method)
 * @returns a T or null if document does not exist
 */
export async function getOneDoc<T>(doc: AngularFirestoreDocument<T>): Promise<T|null> {
  const snapshot = await doc.get().toPromise();
  return snapshot.exists
    ? { id: snapshot.id, ...snapshot.data() }
    : null;
}

export function getIfDefined(value: any, defValue: any) {
  if (value !== undefined) {
    return value;
  } else {
    return defValue;
  }
}

export function hasCookie(name: string): boolean {
  return document.cookie.startsWith(`${name}=`)
      || document.cookie.indexOf(`; ${name}=`) > 0;
}


export function scrollToItem(itemId: string, options?: { animated: boolean }): boolean {
  const element = document.getElementById(itemId);
  if (!element) return false; // not found; just do nothing

  element.scrollIntoView({
    behavior: options?.animated ? "smooth" : undefined,
    block: "start",
    inline: "nearest"
  });

  return true;
}

export function scrollToItemAnimation(itemId: string): boolean {
  return scrollToItem(itemId, { animated: true });
}

/**
 * Opens an external site in a new window or tab
 * (window or tab is up to the user's browser preferences)
 *
 * @param url - e.g. "https://site.com"
 */
export function openExternalUrl(url: string): void {
  window.open(url, '_blank', 'noopener,noreferrer');
}

export function justWait(delayInMs: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(() => resolve(null), delayInMs);
  });
}

/**
 * Applies changes to an object and returns the applied changes if any
 *
 * @param state - to be updated
 * @param map - new values
 * @returns {any} - a map of applied changes or null if nothing changed
 */
export function updateState(state: any, map: any): any {
  const changes = {};
  let count = 0;
  for (const key in map) {
    const value = map[key];
    if (state[key] === value) continue;
    count++;
    changes[key] = value;
    state[key] = value;
  }
  return count > 0 ? changes : null;
}


function deduceDateColumn(rawData: any[], possibleDateColumns: string[]): string {
  if (!rawData.length) return '';

  let columnName = '';
  const SAMPLE = 3;
  const middle = Math.floor((rawData.length - SAMPLE) / 2);

  columnName = findOrCheckDateColumnOnSample(rawData, 0, SAMPLE, possibleDateColumns, columnName);
  columnName = findOrCheckDateColumnOnSample(rawData, middle, middle + SAMPLE, possibleDateColumns, columnName);
  columnName = findOrCheckDateColumnOnSample(rawData, rawData.length - SAMPLE, rawData.length, possibleDateColumns, columnName);
  return columnName;
}

// NB: rawData[to] is not included (like in String.substring for example)
function findOrCheckDateColumnOnSample(rawData: any[], from: number, to: number, possibleDateColumns: string[], currentDateColumn: string): string {
  from = Math.max(0, Math.min(rawData.length - 1, from));
  to = Math.max(0, from, Math.min(rawData.length, to));
  for (let i = from; i < to; i++) {
    currentDateColumn = findOrCheckDateColumn(rawData[i], possibleDateColumns, currentDateColumn);
  }
  return currentDateColumn;
}

function findOrCheckDateColumn(row: Record<string, any>, possibleDateColumns: string[], currentDateColumn: string): string {
  if (currentDateColumn) {
    if (isNaN(new Date(row[currentDateColumn]).getTime())) {
      console.error(`Unexpected value for "${currentDateColumn}" column: ${row[currentDateColumn]}`);
    }
    return currentDateColumn;
  } else {
    for (const dateColumn of possibleDateColumns) {
      if (!row[dateColumn]) continue;
      if (isNaN(new Date(row[dateColumn]).getTime())) {
        console.error(`Unexpected value for "${dateColumn}" column: ${row[dateColumn]}`);
      } else {
        return dateColumn;
      }
    }
    return '';
  }
}

const POSSIBLE_DATE_COLUMNS = [
  'date',
  'year_month',
  'registration_date',
  'prediction_date',
  'month' // TODO: backend should use year_month for this
];

// NB: backend must send a "complete" first record (rawData[0])
// which we use to decide which field we filter on (e.g. "date")
export function filterByDateRange(rawData: any[], start: Date, end: Date): any[] {
  const dateColumn = deduceDateColumn(rawData, POSSIBLE_DATE_COLUMNS);
  if (!dateColumn) {
    return rawData; // no filtering
  }

  const startDate = start.getTime();
  const endDate = end.getTime();

  return rawData.filter(item => {
    const itemDate = new Date(item[dateColumn]).getTime();
    return itemDate >= startDate && itemDate <= endDate;
  });
}


export function filterByMarketClient(rawData: any[], marketName: string, clientName: string): any[] {
  let filteredData = [];

  if (!marketName && !clientName) {
    return rawData;
  }

  rawData.forEach(item => {
    if (marketName && !clientName && item.market === marketName) {
      filteredData.push(item);
    } else if (!marketName && clientName && item.client === clientName) {
      filteredData.push(item);
    } else if (item.market === marketName && item.client === clientName) {
      filteredData.push(item);
    }
  });

  // Aggregate count by date
  const resultMap = {};
  if ((marketName && !clientName) || (!marketName && clientName)) {
    filteredData.forEach(item => {
      if (resultMap[item.date]) {
        const val = resultMap[item.date];
        val["7_day_predicted_package_count"] += item["7_day_predicted_package_count"];
        val["14_day_predicted_package_count"] += item["14_day_predicted_package_count"];
        val["21_day_predicted_package_count"] += item["21_day_predicted_package_count"];
        val["actual_package_count"] += item["actual_package_count"];
        resultMap[item.date] = val;
      } else {
        resultMap[item.date] = item;
      }
    });

    filteredData = Object.values(resultMap);
  }

  return filteredData;
}

export function filterByOrderStatus(rawData: any[], selectedStatus: string): any[] {
  const filteredData = [];

  rawData.forEach(item => {
    for (const property in item) {
      if (property !== "month") {
        const statusObj = item[property];
        if (!statusObj[selectedStatus]) {
          statusObj[selectedStatus] = 0.0;
        }
        // If there is a status then show that as a total
        statusObj["non_completed_total"] = statusObj[selectedStatus];
        item[property] = statusObj;
        // Push to array
        filteredData.push(item);
      }
    }
  });

  return filteredData;
}

export function filterByCustomerType(rawData: any[]): any {
  const existingCustomerData = [];
  const newCustomerData = [];

  rawData.forEach(item => {
    if (item.existing_customer) {
      existingCustomerData.push(item);
    } else {
      newCustomerData.push(item);
    }
  });

  return {
    existingCustomer: existingCustomerData,
    newCustomer: newCustomerData
  };
}

export function extractDropdownlist(rawData: any[], keyName: string): any[] {
  const filteredData = [];
  // TODO: Compare speed between ES6 filter & below code
  // rawData.filter((v,i,a)=>a.findIndex(t=>(t[keyName] === v[keyName]))===i)

  const uniqueItems = new Set()
  
  rawData.forEach(item => {
    if (!item[keyName]) {
      // If key is not present it means the rawData doesn't need filtering
      return [];
    }
    uniqueItems.add(item[keyName]);
  });

  uniqueItems.forEach(item => {
    filteredData.push({
      value: item,
      viewValue: item
    });
  });

  return filteredData;
}

export function cleanFeatureColumnData(rawData: any[]) {
  const stageMap = {
    "stage1_mean": {
      stage_name: "Stage 1"
    },
    "stage2_mean": {
      stage_name: "Stage 2"
    },
    "stage3_mean": {
      stage_name: "Stage 3"
    },
    "stage4_mean": {
      stage_name: "Stage 4"
    },
    "stage5_mean": {
      stage_name: "Stage 5"
    },
    "stage6_mean": {
      stage_name: "Stage 6"
    },
  };

  rawData.forEach(item => {
    for (const property in item) {
      if (property === "stage1_mean" || property === "stage2_mean" || property === "stage3_mean"
        || property === "stage4_mean" || property === "stage5_mean" || property === "stage6_mean") {
        stageMap[property][String(item["feature_column"])] = item[property];
      }
    }
  });
  
  return Object.values(stageMap);
}

export function generateMulticurveData(rawData: any[]): GraphCurveConfig[] {
  const multicurveData: GraphCurveConfig[] = [];

  rawData.forEach(item => {
    multicurveData.push(
      { x: 'stage_name', y: String(item["feature_column"]), name: String(item["feature_column"]) }
    );
  });
  return multicurveData;
}

export function generateQueryParamsFromArray(rawData: string[], paramName) {
  let result = "";

  rawData.forEach((item, index) => {
    result = result.concat(paramName + "=" + item);
    if (index < rawData.length - 1) {
      result = result.concat("&");
    }
  });
  return result;
}

export function generateRowsGroupedByUnit(rawData: any[]) {
  const unitMap = {};
  rawData.forEach((item) => {
    let unitObj = {};
    if (unitMap[item.unit_number.displayValue]) {
      unitObj = unitMap[item.unit_number.displayValue];
    }
    const arrivalDate = item.arrival_date ? item.arrival_date : item.arrival_week + "_" + item.arrival_year;
    const actualPriceKey = "actual_rental_fee_" + arrivalDate;
    const suggestedPriceKey = "suggested_rental_fee_" + arrivalDate;

    unitObj["unit_number"] = {};
    unitObj["unit_number"]["value"] = item.unit_number;
    unitObj["unit_number"]["status"] = "status-cell-default";
    unitObj[actualPriceKey] = {};
    unitObj[actualPriceKey]["value"] = item.actual_rental_fee ? item.actual_rental_fee : 0;
    unitObj[suggestedPriceKey] = {};
    unitObj[suggestedPriceKey]["value"] = item.suggested_rental_fee ? item.suggested_rental_fee : 0;
    
    unitObj[actualPriceKey]["status"] = "status-cell-default";
    unitObj[suggestedPriceKey]["status"] = (unitObj[suggestedPriceKey]["value"] > unitObj[actualPriceKey]["value"]) ? "status-cell-positive" : "status-cell-negative";
    unitMap[item.unit_number.displayValue] = unitObj;
  });
  return Object.values(unitMap);
}

export function getDateFromWeekNum(w, y) {
  const roughDate = new Date(y, 0, 1 + (w - 1) * 7);
  const dow = roughDate.getDay();
  const weekStartDate = roughDate;
  if (dow <= 4) {
    weekStartDate.setDate(roughDate.getDate() - roughDate.getDay() + 1);
  } else {
    weekStartDate.setDate(roughDate.getDate() + 8 - roughDate.getDay());
  }
  return weekStartDate;
}

export function generateCumulativeRows(rawData: string[], columns: string[]) {
  const result = [];

  rawData.forEach((item) => {
    let isCumulativeRow = false;
    for (const columnName of columns) {
      if (!item[columnName] || item[columnName] == null || item[columnName] === "All") {
        isCumulativeRow = true;
      } else {
        isCumulativeRow = false;
        break;
      }
    }

    if (isCumulativeRow) {
      for (const columnName of columns) {
        item[columnName] = "All";
      }
      result.push(item);
    }
  });
  return result;
}

export function generateOverallCumulativeRows(rawData: string[], columns: string[]) {
  const result = [];
  rawData.forEach((item) => {
    let isCumulativeRow = false;
    for (const columnName of columns) {
      if (!item[columnName] || item[columnName] == null || item[columnName] === "All" && item["date_part"] === "Total") {
        isCumulativeRow = true;
      } else {
        isCumulativeRow = false;
        break;
      }
    }

    if (isCumulativeRow) {
      for (const columnName of columns) {
        item[columnName] = "All";
      }
      // Set year as All
      item["year"] = "All";
      item["date_part"] = "All";
      result.push(item);
    } 
  });
  return result;
}

export function generateFeatureValues(rawData: string[], columnName: string) {
  const result = new Set();

  rawData.forEach((item) => {
    if (!item[columnName] || item[columnName] == null) {
      result.add("All");
    } else if (item[columnName]) {
      result.add(item[columnName]);
    }
  });
  return Array.from(result);
}

/**
 * Helper for sorting tables
 * @param a - first value
 * @param b - second value
 * @param direction - +1 for ascending, -1 for descending
 * @returns {number} -1, 0, +1 like a standard sorting function 
 */
export function strCompare(a: string, b: string, direction: number): number {
  return (a || '').localeCompare((b || '')) * direction;
}

/**
 * Helper for sorting tables
 * @param a - first value
 * @param b - second value
 * @param direction - +1 for ascending, -1 for descending
 * @returns {number} -1, 0, +1 like a standard sorting function 
 */
export function timestampCompare(a: Timestamp, b: Timestamp, direction: number): number {
  const aSec = a ? a.seconds : 0;
  const bSec = b ? b.seconds : 0;
  if (aSec === bSec) return 0;
  return aSec < bSec ? -direction : direction;
}

export function sortByMonth(arr: any[]): any[] {
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  arr.sort(function(a, b) {
    if (a.month && b.month) {
      return months.indexOf(a.month)
        - months.indexOf(b.month);
    } else if (a.months && b.months) {
      // Some backend apis return months instead of month as key
      return months.indexOf(a.months)
        - months.indexOf(b.months);
    }
    return 0;
  });
  return arr;
}

export function sinceString(datetime: Timestamp, humanizeIfLessThanSec: number = 24 * 3600): string {
  const now = Date.now();
  const durationSec = Math.ceil((now - datetime.toMillis()) / 1000);
  if (durationSec <= humanizeIfLessThanSec) {
    return humanizeDuration(durationSec * 1000, { largest: 1 }) + ' ago';
  } else {
    return datetime.toDate().toLocaleString();
  }
}

export function formatDatetimeForUi(date: Date): string {
  return date.toLocaleString();
}

export function formatDateForUi(date: Date): string {
  return date.toLocaleDateString();
}

/* Formats date as YYYY-MM-DD */
export function formatDateForBackend(date: Date): string {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  
  return `${year}-${month}-${day}`;

}

export function formatStripeAmount(amount: number, currency: string): string {
  const ccy = currency.toUpperCase();
  if (ccy !== 'USD') {
    // not sure about other currencies
    return `${ccy} ${amount}`;
  }
  // Stripe uses "number of cents" for USD amounts
  return `${ccy} ${(amount / 100).toFixed(2)}`;
}

export function capitalize(str: string): string {
  return str[0].toUpperCase() + str.substring(1);
}

export function  convertToTitleCase(str){
  return str.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map(x => x.charAt(0).toUpperCase() + x.slice(1))
    .join(' ');
}
// TODO: this is copied from Shopify app block (see jarvisml-product-rec.js)
export function makeSizedImageUrl(url: string, wantedSize: number) {
  if (!url) {
    // If the url is not present return empty string
    return "";
  }
  const pos = url.lastIndexOf('.');
  if (pos < 0) {
    return url; // no extension? Should not happen
  }
  const ending = url.substring(pos + 1);
  if (!/^[a-zA-Z]{3,4}(\?v=[0-9]+)?$/.exec(ending)) {
    return url; // not the expected ending, like "png" or "jpeg?v=333232"
  }
  let beginning = url.substring(0, pos);
  const underscore = beginning.lastIndexOf('_');
  if (underscore >= 0 && /^[0-9]*x[0-9]*$/.exec(beginning.substring(underscore + 1))) {
    beginning = beginning.substring(0, underscore);
  }

  return `${beginning}_${wantedSize}x${wantedSize}.${ending}`;
}

export function fetchSurveyFieldObject(inputObj: any, selectedVertical: string) {
  const properties = inputObj.properties;
  const requiredFields = inputObj.required || [];
  const transformedArray: FieldDescription[] = Object.keys(properties).map((key) => {
    const property = properties[key];
    if ((property.vertical === "non-vrm" && selectedVertical === 'hospitality') || (property.vertical === "vrm" && selectedVertical === 'ecommerce')) {
      return undefined;
    } 
    if (property.show_in_ui === undefined || property.show_in_ui === true) {
      const transformedObject: FieldDescription = {
        name: key,
        type: property.type == 'text' ? 'string' : property.type,
        label: property.title,
        required: requiredFields.includes(key),
      };

      if (property.allOf && property.allOf.length > 0) {
        const ref = property.allOf[0].$ref.split('/').pop();
        transformedObject.selectList = generateSurveyOptions(inputObj.definitions[ref].enum, inputObj.definitions[ref].display);
        transformedObject.type = 'select';
      }

      return transformedObject;
    }
    return undefined; // Return undefined for non-matching show_in_ui
  }).filter((field) => field !== undefined); // Remove undefined values from the array
  return transformedArray;
}

function generateSurveyOptions(enumValues, displayValues) {
  return enumValues.map((value, index) => {
    return {
      value: value,
      viewValue: displayValues[index]
    };
  });
}