import { Component } from '@angular/core';

import { FormService } from 'src/app/services/form.service';
import { GraphPickerService } from 'src/app/services/graph-picker.service';
import { MsgboxService } from 'src/app/services/msgbox.service';
import { SpinnerService } from 'src/app/services/spinner.service';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss']
})
export class ShellComponent {

  spinner: any = {};
  form: any = {};
  form2: any = {}; // silly, but we are not planning to stack more than 2 forms soon (this can be done still)
  msgbox: any = {};
  graphPicker: any = {};

  constructor(
    public spinnerService: SpinnerService,
    public formService: FormService,
    public graphPickerService: GraphPickerService,
    public msgboxService: MsgboxService
  ) {
    spinnerService.initRoot(this.spinner);
    formService.initRoot(this.form, this.form2);
    graphPickerService.initRoot(this.graphPicker);
    msgboxService.initRoot(this.msgbox);
  }

}
