<div class="darkDiv">

<div class="form-frame">
  <div class="form-title">Classes</div>

  <div class="form-container" [formGroup]="classesForm">
    <table mat-table [dataSource]="classes" class="mat-elevation-z8">

      <ng-container matColumnDef="className">
        <td mat-cell *matCellDef="let i = index">
          <mat-form-field>
            <input matInput name="not-a-login" [(ngModel)]="classes[i]" [ngModelOptions]="{standalone: true}">
          </mat-form-field>
        </td>
      </ng-container>

      <ng-container matColumnDef="insert">
        <td mat-cell *matCellDef="let i = index"> <button mat-flat-button color="accent" type="button" (click)="insert(i)">Insert</button>
        </td>
      </ng-container>

      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
  <div class="helpLabel" *ngIf="hasEmptyRows">Empty rows will be removed after saving</div>
  <div class="buttons">
    <button mat-raised-button color="primary" type="button" (click)=submitClasses()>Save</button>
    <button mat-flat-button type="button" (click)=cancelForm()>Cancel</button>
  </div>
</div>

</div>
