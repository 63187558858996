import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'template-card',
  templateUrl: './template-card.component.html',
  styleUrls: ['./template-card.component.scss']
})
export class TemplateCardComponent implements OnInit {

  @Input() title?: string;
  @Input() value?: string;

  ngOnInit(): void {
    // console.log(this.data);
  }
}
