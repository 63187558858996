import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BackendApiService } from './backend-api.service';

interface UserConfig {
  name?: string,
  phone_number?: string,
  job_function?: string,
  cid?: string,
  email?: string,
  role?: string
}

@Injectable({
  providedIn: 'root'
})
export class UserInfoService {

  private minerUrl = environment.minerURL;
  private customerSurveyEndpoint = `${this.minerUrl}/aidaptive_customer_survey/`;

  constructor(
    private http: HttpClient,
    private backendApi: BackendApiService,
  ) { }

  public async getCustomerSchema(): Promise<any> {
    return await this.backendApi.get(`/aidaptive_customer_survey/get_customer_survey_schema`, this.minerUrl);
  }

  public async addCustomerSurvey(body: any): Promise<any> {
    return await this.backendApi.post(`/aidaptive_customer_survey/add`, body, this.minerUrl);
  }

  public fetchCustomerSurveyInfo(cid: string): Promise<any> {
    return this.backendApi.get(`/aidaptive_customer_survey/get?cid=${cid}`, this.minerUrl);
  }

  public async getUserSchema(): Promise<any> {
    return await this.backendApi.get(`/user/get_aidaptive_user_update_schema`, this.minerUrl);
  }

  public async getUserAdminSchema(is_add: boolean): Promise<any> {
    return await this.backendApi.get(`/user/get_aidaptive_user_admin_schema?is_add=${is_add}`, this.minerUrl);
  }

  public async updateUserSurvey(body: any): Promise<any> {
    return await this.backendApi.post(`/user/update_user`, body, this.minerUrl);
  }

  public async addUser(userConfig: UserConfig): Promise<any> {
    return await this.backendApi.post(`/user/add_user`, userConfig, this.minerUrl);
  }

  public async adminUpdateUser(userConfig: UserConfig, uid: string): Promise<any> {
    return await this.backendApi.post(`/user/admin_update_user/${uid}`, userConfig, this.minerUrl);
  }

  public async deleteUser(uid: string): Promise<any> {
    return await this.backendApi.delete(`/user/admin_delete_user/${uid}`, this.minerUrl);
  }

  public async deleteUserByEmail(body): Promise<any> {
    return await this.backendApi.post(`/user/delete_user_by_email`, body, this.minerUrl);
  }
}
