<!-- 
    NB: text below is not supposed to be "read" by users; 
    it will only appear if something is broken or super slow to load,
    hence we prefer showing something that "makes sense"
-->
<div class="flashedText">
    <p>{{companyName}} aims to be most accessible and easy to use
        end to end machine learning platform.
    </p>

    <p>Get your machine learning idea off the ground in minutes and hours as
        opposed to weeks and months.
    </p>

    <p>{{companyName}} is fully managed machine learning platform as a service. </p>

    <p>
        It allows organizations to focus on their core product and
        start applying machine learning throughout their organizations
        and products.
    </p>
</div>
