import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SetupCompletedComponent } from './setup-completed.component';

const routes: Routes = [
  { path: '', component: SetupCompletedComponent },
];

  @NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
  })
export class SetupCompletedRoutingModule { }