<a class="block rounded-lg p-4 border-2 shadow-lg shadow-indigo-100">
    <div class="w-3/5 flex justify-center items-center mx-auto">
        <img alt="Home" [src]="product.product_image_url || product.main_image?.url || product.featured_image?.url" class="h-auto w-full rounded-md object-cover" />
    </div>

    <div class="mt-2">
        <div class="mt-1">
            <div>
                <dd class="font-semibold mt-1">{{ product.title || product.name }}</dd>
            </div>
            <div *ngIf="product.price">
                <dd class="text-sm text-gray-500">${{ price }}</dd>
            </div>
        </div>
        <div class="mt-2 flex items-center gap-8 text-xs justify-evenly">
            <div *ngIf="product.review_data?.average_rating"
                class="sm:inline-flex sm:shrink-0 sm:items-center sm:gap-2">
                <div class="mt-1.5 sm:mt-0">
                    <p class="text-gray-500">Average Rating</p>
                    <div class="flex flex-row"> <!-- Update ng-container to div -->
                        <svg *ngFor="let star of starsArray" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                            fill="currentColor" class="w-6 h-6"
                            [ngClass]="{'text-indigo-700': star <= product.review_data?.average_rating, 'text-gray-400': star > product.review_data?.average_rating}">
                            <path fill-rule="evenodd"
                                d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"
                                clip-rule="evenodd" />
                        </svg>
                    </div>
                </div>
            </div>
            <div *ngIf="product.review_data?.number_of_votes"
                class="sm:inline-flex sm:shrink-0 sm:items-center sm:gap-2">
                <div class="mt-1.5 sm:mt-0 text-center">
                    <p class="text-gray-500">No of Votes</p>
                    <div class="font-medium flex flex-row justify-center"> <svg xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24" fill="currentColor" class="w-5 h-5 text-indigo-700">
                            <path fill-rule="evenodd"
                                d="M10 2c-1.716 0-3.408.106-5.07.31C3.806 2.45 3 3.414 3 4.517V17.25a.75.75 0 001.075.676L10 15.082l5.925 2.844A.75.75 0 0017 17.25V4.517c0-1.103-.806-2.068-1.93-2.207A41.403 41.403 0 0010 2z"
                                clip-rule="evenodd" />
                        </svg>
                        <div>{{ product.review_data?.number_of_votes }}</div>
                    </div>
                </div>
            </div>
        </div>
        <a href={{product.landing_page_url||product.url}} target="_blank"
            class="w-full rounded-md mt-4 mx-2 cursor-pointer inline-block text-sm font-medium  text-white">
            <span class="block border rounded-md border-current bg-blue-600 px-8 py-2 font-semibold">
                Product Details
            </span>
        </a>
    </div>
</a>