import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription, fromEvent } from 'rxjs';

import { UserService } from 'src/app/services/user.service';
import { CustomerInList } from 'src/app/models/customer.model';
import { CustomerService } from 'src/app/services/customer.service';
import { CustomerSettingService } from 'src/app/services/customer-setting.service';
import { UserEntity } from 'src/app/models/user.model';
import { MenuActionDefinition } from '../menu-action/menu-action.component';
import { DeeplinkService } from 'src/app/services/deeplink.service';
import { SelectorItem } from '../generic-selector/generic-selector.component';
import { strCompare } from 'src/app/util/helpers';
import { TabName, TabPrefsEntity } from 'src/app/admin/tab-preferences/tab-preferences.component';

@Component({
  selector: 'navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss']
})
export class NavigationBarComponent implements OnInit, AfterViewInit, OnDestroy {

  user: UserEntity;
  public customerName: string;
  private allCustomers: CustomerInList[] = null;
  private customerPrefs: TabPrefsEntity;
  private isEnabled = false;
  private isSuperuser = false;
  private isJarvisDefaultUser = false;
  private isAdminOrOwner = false;
  public isScreenWideEnough = true;
  public isReady = false;
  private resizeSubscription: Subscription = null;
  public customerList: SelectorItem[] = null;
  public actions: MenuActionDefinition[];
  private merchandisingTabStatus: boolean;
  responsiveVisibleSubMenu: string;
  private showSearchSettings: boolean;

  private submenuDefinitions: { [id: string]: MenuActionDefinition[] } = {
    settings: [
      { id: 'journey', label: 'Journey', link: '/settings#tab#journey' },
      { id: 'connections', label: 'Connections', link: '/settings#tab#api-integrations' },
      { id: 'access', label: 'Access', link: '/settings#tab#accessControl' },
      { id: 'internal-settings', label: 'Internal Settings', link: '/settings#tab#internal-settings', condition: () => this.isSuperuser || this.isJarvisDefaultUser },
      { id: 'subscriptions', label: 'Subscriptions', link: '/settings#tab#subscriptions' },
      { id: 'search-settings', label: 'Search Settings', link: '/settings#tab#search-settings', condition: () => this.showSearchSettings},
      { id: 'logout', label: 'Logout', action: () => this.logout() },
    ],
    support: [
      { id: 'help-center', label: 'Help Center', externalLink: 'https://knowledge.aidaptive.com/' },
      { id: 'documentation', label: 'Documentation', link: '/documentation' },
      { id: 'contact', label: 'Contact', link: '/contact' },
    ],
    admin: [
      { id: 'admin', label: 'Admin', link: '/admin', condition: () => this.isSuperuser },
      { id: 'aidaptive-events', label: 'Aidaptive Events', link: '/aidaptive-events', condition: () => this.isSuperuser || this.isJarvisDefaultUser },
      { id: 'watchtower', label: 'Watchtower', link: '/watchtower', condition: () => this.isSuperuser || this.isJarvisDefaultUser },
      { id: 'spaces', label: 'Spaces', link: '/spaces', condition: () => this.isSuperuser },
      { id: 'superuser', label: 'Superuser', link: '/' },
      { id: 'edit-graphs', label: 'Edit Graphs', link: '/' },
      { id: 'graph-options', label: 'Graph Options', link: '/' },
    ]
  };

  private actionDefinitions: MenuActionDefinition[] = [
    { id: 'knowledgeGraph', label: 'Knowledge Graph', link: '/knowledge-graph', condition: this.showCondition('knowledgeGraph') },
    { id: 'merchandising', label: 'Merchandising', link: '/merchandising', condition: () => false },
    { id: 'audienceExplorer', label: 'Audience', link: '/audience-explorer', condition: this.showCondition('audiences') },
    { id: 'recs', label: 'Recommendations', link: '/dynamic-experience', condition: this.showCondition('recs') },
    // { id: 'searchandising', label: 'Searchandising', condition: () => this.isEnabled },
    { id: 'metrics', label: 'Performance Metrics', link: '/metrics', condition: this.showCondition('metrics') },
    { id: 'powerPricing', label: 'Power Pricing', link: '/power-pricing', condition: this.showCondition('powerPricing') },
    { id: 'settings', label: 'Settings', submenu: true, condition: this.showCondition('settings') },
    { id: 'support', label: 'Support', submenu: true, condition: this.showCondition('support') },
    { id: 'admin', label: 'Admin', submenu: true, condition: () => this.isSuperuser },
  ];

  private showCondition(key: TabName): () => boolean {
    return () => (this.isEnabled && this.isSuperuser) ? this.showInternally(key) : this.showExternally(key);
  }

  private showInternally(key: TabName): boolean {
    return this.customerPrefs?.[key]?.showInternally;
  }

  private showExternally(key: TabName): boolean {
    return this.customerPrefs?.[key]?.showExternally;
  }

  async logout() {
    await this.userService.signOut();
    this.deeplinkService.navigateToUrl("/login");
  }


  enabledActionCount = 0;

  constructor(
    private customerService: CustomerService,
    private deeplinkService: DeeplinkService,
    public userService: UserService,
    private customerSettingService: CustomerSettingService
  ) {
  }

  ngOnDestroy() {
    if (this.resizeSubscription) {
      this.resizeSubscription.unsubscribe();
    }
  }

  // ngOnInit is called after the constructor, initializing input properties, and the first call to ngOnChanges.
  async ngOnInit(): Promise<void> {
    // Like "shell" component, "navigation-bar" is one of the rare ones that "sticks around"
    // while most others are loaded/unloaded.
    // Hence, it wants to register to user state related changes and update itself with it.
    // E.g. at first user is not logged in, then he/she is, then user preferences might change, etc.
    this.userService.observeUser().subscribe(user => this.onUserChange(user));
    this.deeplinkService.observeDynamicCid().subscribe((/*cid*/) => this.onUserChange(this.user));
  }

  async onUserChange(user: UserEntity) {
    const welcomeScreens = location.pathname.includes("welcome") || location.pathname.includes("all-set");
    if (!user || welcomeScreens) {
      this.isReady = false;
      this.user = null;
      this.isEnabled = false;
      this.isSuperuser = false;
      this.isAdminOrOwner = false;
      this.showSearchSettings = false;
      return;
    }
    this.userService.dynamicRemap(user);
    this.user = user;
    this.isEnabled = user.enabled;
    this.isSuperuser = user.jarvisml_group === 'platform';
    this.isJarvisDefaultUser = user.jarvisml_group === 'default';
    this.isAdminOrOwner = this.userService.isUserGranted(user, 'admin');
    this.checkVertical();
    // NB: no need to set tab flag(s) if user is not enabled
    if (!user.enabled) {
      this.isReady = false;
      return;
    }

    this.fetchMerchandisingInfo(this.user.cid);
    await this.getAllCustomers();
    await this.showCustomTabs();

    this.checkReady();
  }

  menuOpened() {
    // Unexpand any previously expanded submenu
    for (const action of this.actions) {
      if (!action.submenu) continue;
      action.showSubmenu = false;
    }
    this.responsiveVisibleSubMenu = '';
  }

  showSubMenu(menuId: string): boolean {
    let found = false;
    for (const action of this.actions) {
      if (!action.submenu) continue;
      if (action.condition && !action.condition()) continue;
      if (action.id === menuId) {
        action.showSubmenu = true;
        found = true;
      } else {
        action.showSubmenu = false;
      }
    }
    return found;
  }

  hideSubMenu(menuId: string) {
    for (const action of this.actions) {
      if (!action.submenu) continue;
      if (action.id === menuId) {
        action.showSubmenu = false;
      }
    }
  }

  onMenuClick(event: Event, menuId: string) {
    if (menuId === this.responsiveVisibleSubMenu) {
      // User clicked on already opened submenu
      // "unexpand" the submenu
      this.hideSubMenu(menuId);
      this.responsiveVisibleSubMenu = '';

      event.stopPropagation(); // leave popup-menu open
    } else {
      // User clicked on a menu option; it can be a submenu OR a pure action
      const isSubMenu = this.showSubMenu(menuId);
      if (isSubMenu) {
        this.responsiveVisibleSubMenu = menuId;
        event.stopPropagation(); // leave popup-menu open
      }
    }
  }

  ngAfterViewInit() {
    this.resizeSubscription = fromEvent(window, 'resize').subscribe(() => {
      this.checkIfScreenWideEnough();
    });

    this.checkReady();
  }

  checkReady() {
    if (!this.resizeSubscription || !this.enabledActionCount) {
      return;
    }
    this.checkIfScreenWideEnough();
    this.isReady = true;
  }

  checkIfScreenWideEnough() {
    this.isScreenWideEnough = window.innerWidth > 1279;
  }

  async showCustomTabs(): Promise<void> {
    this.customerPrefs = await this.customerSettingService.getTabPreferences(this.user.cid);

    this.enabledActionCount = 0;
    this.actions = [];
    for (const action of this.actionDefinitions) {
      if (action.condition()) {
        if (action.submenu) {
          action.submenuActions = this.getSubMenuActions(action.id);
          if (action.submenuActions.length === 0) {
            continue;
          }
        }
        this.enabledActionCount++;
        this.actions.push(action);
      }
    }
  }

  private getSubMenuActions(menuId: string) {
    const submenu = this.submenuDefinitions[menuId];
    if (!submenu) return [];
    const actions: MenuActionDefinition[] = [];
    for (const action of submenu) {
      if (action.condition && !action.condition()) continue;
      actions.push(action);
    }
    return actions;
  }

  copyDeepLink() {
    this.deeplinkService.copyDeepLinkFromUrl();
  }

  async getAllCustomers() {
    const customers = await this.customerService.getAllCustomers(this.user);
    this.allCustomers = customers.sort((a, b) => strCompare(a.aidaptive_customer_name || a.cid, b.aidaptive_customer_name || b.cid, +1));
    const selectedCustomer = this.allCustomers.find(v => v.cid === this.user.cid);
    this.customerName = selectedCustomer?.aidaptive_customer_name || selectedCustomer?.cid;
  }

  async onClickCustomer() {
    this.customerList = this.allCustomers.map(c => ({ value: c.cid, display: c.aidaptive_customer_name || c.cid }));
  }

  async checkVertical() {
    const customer = await this.customerService.getCustomer(this.user.cid);
    this.showSearchSettings = (customer.shopify_name || customer?.verticalName === 'ecommerce') ? true : false;
  }

  onSelectCustomer(cid: string) {
    this.customerList = null; // close selector
    if (!cid) return; // cancelled by user
    console.log(cid);
    this.deeplinkService.switchCid(cid); // will call us back (see observeDynamicCid)
  }

  async fetchMerchandisingInfo(cid: string){
    const resp = await this.customerService.getCustomerInfo(cid);
    this.merchandisingTabStatus = resp?.internal_settings?.merchandising_enabled;
  }
}
