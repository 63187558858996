import { Component, OnChanges, Input } from '@angular/core';

@Component({
  selector: 'image-preview',
  templateUrl: './image-preview.component.html',
  styleUrls: ['./image-preview.component.scss']
})
export class ImagePreviewComponent implements OnChanges {

  @Input() imageUrl: string;

  loading = true;
  hasImage = false;

  ngOnChanges(): void {
    this.loading = true;
    this.hasImage = false;
  }

  onLoad() {
    this.hasImage = true;
    this.loading = false;
  }

  onClickOnImage() {
    this.imageUrl = null; // => hide
    this.loading = true; // for next image (if happens)
    this.hasImage = false;
  }
}
