import { Directive, HostListener, Output, EventEmitter } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';
type UserCredential = firebase.auth.UserCredential;

@Directive({
  selector: '[appGoogleSignin]'
})
export class GoogleSigninDirective {

  @Output() successEvent = new EventEmitter<UserCredential>();

  constructor(private afAuth: AngularFireAuth) {}

  @HostListener('click')
  async onclick() {
    try {
      const userCredential = await this.afAuth.signInWithPopup(new firebase.auth.GoogleAuthProvider());
      this.successEvent.emit(userCredential);
    } catch (error) {
      console.warn('Log in with Google failed:', error.message);
    }
  }
}
