import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatTable } from '@angular/material/table';

@Component({
  selector: 'edit-classes',
  templateUrl: './edit-classes.component.html',
  styleUrls: ['./edit-classes.component.scss']
})
export class EditClassesComponent implements OnInit {

  @Input() currentClasses: string[];
  @Output() editEvent = new EventEmitter<string[]>();
  @ViewChild(MatTable) table: MatTable<any>;
  classesForm: UntypedFormGroup;
  classes : string[] = [];
  displayedColumns : string[] = ["className", "insert"];
  hasEmptyRows = false;

  constructor(
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    if (this.currentClasses) {
      this.classes = this.currentClasses.concat();
    }
    if (!this.classes || !this.classes.length) {
      this.classes = ['','']; // 2 blank classes by default
      this.hasEmptyRows = true;
    }

    this.classesForm = this.formBuilder.group({
      name: [null, Validators.nullValidator]
    });
  }

  insert(index): void {
    this.hasEmptyRows = true;
    this.classes.splice(index, 0, '');
    this.table.renderRows();
  }

  async submitClasses() {
    const results: string[] = [];
    for (let i = 0; i < this.classes.length; i++) {
      const value = this.classes[i].trim();
      if (value === '') continue; // skip empty strings
      if (results.indexOf(value) !== -1) continue; // skip dupes
      results.push(value);
    }
    this.editEvent.emit(results);
  }

  cancelForm(): void {
    this.editEvent.emit(null);
  }
}
