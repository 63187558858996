import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'upload-button',
  templateUrl: './upload-button.component.html',
  styleUrls: ['./upload-button.component.scss']
})
export class UploadButtonComponent implements OnInit {

  @Output() uploadStartedEvent = new EventEmitter<File>();

  constructor() { }

  ngOnInit(): void {
  }

  onFileSelected(event) {
    const file = event.target.files[0];
    console.log(`Upload file started for ${file.name} (type ${file.type})...`);
    this.uploadStartedEvent.emit(file);
  }

}
