<div class="stats-container">
  <div *ngIf="!loading && showGraph && graphData" class="stats-graph">
    <plotly-plot 
      [data]="graphData"
      [config]="config"
      [layout]="layout">
    </plotly-plot>
  </div>
  <div *ngIf="loading" class="stats-value">
    {{ '--' }}
  </div>
  <div *ngIf="!loading && !showGraph" class="stats-value">
    {{(prefix ? prefix : "")}}{{ value || '--' }}{{(suffix ? suffix : "")}}
  </div>
  <div class="stats-title">{{ cardTitle || '--' }}</div>
</div>
