import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-password-rules',
  templateUrl: './app-password-rules.component.html',
  styleUrls: ['./app-password-rules.component.scss']
})
export class AppPasswordRulesComponent implements OnInit {
  @Input() password: string;

  constructor() { }

  ngOnInit(): void {
  }

  isLengthValid(): boolean {
    return this.password && this.password.length >= 8;
  }

  containsUppercase(): boolean {
    return this.password && /[A-Z]/.test(this.password);
  }

  containsLowercase(): boolean {
    return this.password && /[a-z]/.test(this.password);
  }

  containsNumber(): boolean {
    return this.password && /[0-9]/.test(this.password);
  }

  containsSpecialCharacter(): boolean {
    return this.password && /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(this.password);
  }

}
