<div class="darkDiv">

<div class="form-frame">
  <div class="form-title">Edit study</div>
  <div class="form-container">
    <form [formGroup]="studyForm" *ngIf="studyForm">
      <mat-form-field class="form-field" appearance="outline">
          <mat-label> Title </mat-label>
          <input matInput formControlName="title" required>
          <mat-error *ngIf="studyForm.controls.title.invalid">
              This field is mandatory.
          </mat-error>
      </mat-form-field>
      <mat-form-field class="form-field" appearance="outline">
          <mat-label> Description </mat-label>
          <textarea matInput formControlName="description" required></textarea>
          <mat-error *ngIf="studyForm.controls.description.invalid">
              This field is mandatory.
          </mat-error>
      </mat-form-field>
      <mat-form-field *ngIf="classes" class="form-field" appearance="outline">
        <mat-label> Classes </mat-label>
        <input matInput formControlName="classes" (click)="editClasses()" autocomplete="new-password" [readonly]="true">
        <button mat-icon-button type="button" class="editButton" (click)="editClasses()">
          <mat-icon>more_horiz</mat-icon>
        </button>
      </mat-form-field>
      <div class="submitButtons">
        <button mat-raised-button color="primary" type="button" (click)="submitStudy()">OK</button>
        <button mat-flat-button type="button" (click)=cancelForm()>Cancel</button>
      </div>
    </form>
    <hr>
    <div class="title">Danger Zone</div>
    <div class="deleteLabel">
      Delete this study. This cannot be undone. Please be certain.
      <button color="warn" mat-flat-button class="rightButton" (click)="deleteStudy()">Delete</button>
    </div>
  </div>
</div>

<edit-classes *ngIf="classesToEdit"
  [currentClasses]="classesToEdit"
  (editEvent)="refreshClasses($event)">
</edit-classes>

</div>
