
<div class="fixed inset-0 flex justify-center items-center z-50 p-4">
    <div class="bg-white h-4/5 w-3/4 border-2  overflow-y-auto p-8 shadow-2xl relative rounded-md">
      <button class="absolute top-5 right-5 text-gray-500 hover:text-gray-700" (click)="closeModal()">
        <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
        </svg>
      </button>
      <div *ngIf="loading" class="fixed inset-0 flex items-center justify-center z-50">
        <div class="animate-spin rounded-full h-8 w-8 border-t-4 border-blue-500"></div>
      </div>
      <div *ngIf="!loading">
        <div>
          <div class="text-lg align-left font-bold mb-4">Update Price for Unit ID : {{selectedData?.unit_id}}</div>
        </div>
        <div *ngIf="showAlert" [ngClass]="{'bg-green-200': alertType === 'success', 'bg-red-200': alertType === 'failure'}" class="p-3 mb-3 rounded-sm text-sm">
          {{ alertMessage }}
        </div>
        <table class="table-auto w-full">
          <thead>
            <tr>
              <th class="px-4 py-2">Check-In Date</th>
              <th class="px-4 py-2">Jarvis Suggested Price</th>
              <th class="px-4 py-2">Twiddy Suggested Price</th>
              <th class="px-4 py-2">Twiddy Overrriden Price</th>
              <th class="px-4 py-2">Update Price</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of unitData | async">
              <td class="border px-4 py-2">{{ data?.check_in }}</td>
              <td class="border px-4 py-2 text-center">{{ data?.jarvis_suggested_price }}</td>
              <td class="border px-4 py-2 text-center">{{ data?.twiddy_suggested_price }}</td>
              <td class="border px-4 py-2">
                <input type="number" [(ngModel)]="data.twiddy_overriden_price" class="border text-center  p-1">
              </td>
              <td class="border px-4 py-2">
                <button class="px-4 py-1 bg-blue-500 text-white w-full text-center" (click)="updateTwiddyPrice(data)">Update</button>
              </td>
            </tr>
            
          </tbody>
        </table>
        <div class="flex justify-end mt-4">
          <button class="px-4 py-1 bg-red-500 text-white  ml-4" (click)="closeModal()">Cancel</button>
        </div>
      </div>
      
    </div>
  </div>