import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { DeeplinkService } from 'src/app/services/deeplink.service';
import { MenuActionDefinition } from '../menu-action/menu-action.component';

@Component({
  selector: 'app-submenu',
  templateUrl: './submenu.component.html',
  styleUrls: ['./submenu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SubmenuComponent {
  @Input() submenuData: MenuActionDefinition[];
  @Input() isVisible = false;
  @Output() selectEvent = new EventEmitter<string>();

  constructor(
    private deeplinkService: DeeplinkService,
  ) { }

  navigate(simpleLink: string) {
    this.selectEvent.emit(simpleLink);
    return this.deeplinkService.navigateToUrl(simpleLink);
  }
}
