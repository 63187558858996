import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';

import { FirestoreDatasource } from './../../util/FirestoreDatasource';
import { FileEntity } from 'src/app/models/file.model';

@Component({
  selector: 'file-list',
  templateUrl: './file-list.component.html',
  styleUrls: ['./file-list.component.scss']
})
export class FileListComponent implements OnInit {
  @Input() datasource: FirestoreDatasource<FileEntity[]>;
  @Output() selectionChangeEvent = new EventEmitter();
  @Output() tapSelectedEvent = new EventEmitter();

  @ViewChild(MatPaginator) paginator: MatPaginator;

  shouldShow = false;
  displayedColumns : string[] = ["id", "filename", "status", "datetime"];
  selectedRowIndex: number;

  constructor() { }

  ngOnInit(): void {
    this.shouldShow = true;
  }

  highlight(row): void {
    if (this.selectedRowIndex !== row.id) {
      this.selectedRowIndex = row.id;
      this.selectionChangeEvent.emit(row);
    } else {
      // Click on already-selected row
      this.tapSelectedEvent.emit(row);
    }
  }

  onSortChange(sortEvent) {
    this.paginator.pageIndex = 0;
    this.datasource.setSortingAndRefresh(sortEvent.active, sortEvent.direction);
  }
}
