import { Injectable } from '@angular/core';
import { MsgboxService } from './msgbox.service';

// Before appearing, this spinner "waits" a certain time (during which extra spinners are simply counted)
const SPINNER_DELAY = 900; // ms

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  spinnerData: any;
  private pendingTimeout = null;

  constructor(
    private msgboxService: MsgboxService,
  ) { }

  initRoot(spinnerData: any) {
    if (this.spinnerData) {
      console.error('dupe spinner.initRoot'); // probably not a good thing
    }
    this.spinnerData = spinnerData;
    this.spinnerData.count = 0;
    this.spinnerData.pendingCount = 0;
  }

  addSpinner(message: string) {
    this.spinnerData.label = message;
    if (this.spinnerData.count === 0) {
      if (this.spinnerData.pendingCount === 0) {
        this.spinnerData.pendingCount = 1;
        this.pendingTimeout = setTimeout(() => {
          this.spinnerData.count += this.spinnerData.pendingCount;
          this.spinnerData.pendingCount = 0;
        }, SPINNER_DELAY);
      } else {
        this.spinnerData.pendingCount++;
      }
    } else {
      this.spinnerData.count++;
    }
  }

  removeSpinner() {
    if (this.spinnerData.pendingCount) {
      this.spinnerData.pendingCount--;
      if (this.spinnerData.pendingCount === 0) {
        clearTimeout(this.pendingTimeout);
      }
    } else {
      if (this.spinnerData.count === 0) return; // ignore extra "remove"
      this.spinnerData.count--;
    }
  }

  async doWithSpinner(message: string, action: () => Promise<any>): Promise<any> {
    try {
      this.addSpinner(message);
      const result = await action();
      this.removeSpinner();
      return result;
    } catch (error) {
      this.removeSpinner();
      this.msgboxService.showMsg(`Failed with error:\n${error.message}`, { class: 'preText' });
      return null;
    }
  }
}
