<div class="darkDiv">
    <div class="form-frame">
        <div class="form-header">
            <h3 *ngIf="!parent" class="text-2xl">Create Customer</h3>
            <h3 *ngIf="parent === 'external'" class="text-2xl">{{ formHeading }}</h3>
        </div>
        <!-- Breadcrumb -->
        <div class="breadcrumb" *ngIf="totalSteps > 1 && !nonAutomatedIntegrations.has(selectedIntegration)">
            <ul class="breadcrumb-progress" >
                <li [ngStyle]="{'width': stepWidth}" *ngFor="let stepName of stepNames; let i = index" 
                [ngClass]="{
                    'active': i === currentStep - 1 && !success,
                    'completed': i < currentStep - 1 || (i === currentStep - 1 && success)
                 }">{{ stepName }}</li>
            </ul>
        </div>
        <div *ngIf="isFormLoading" class="flex my-6 items-center justify-center">
            <div class="w-12 h-12 border-t-4 border-blue-500 border-solid rounded-full animate-spin mr-2"></div>
        </div>
        <form #newCustomerFormRef [formGroup]="newCustomerForm" *ngIf="newCustomerForm && !isFormLoading"  class="form-fields overflow-y-auto my-2">
            <div class="flex flex-col w-full items-center" *ngIf="activeStep === 1">
                <mat-form-field appearance="outline" class="w-11/12 mb-3" [class.mat-form-field-invalid]="blurredField.has('vertical') && newCustomerForm.controls.vertical.invalid">
                    <mat-label> Select Industry</mat-label>
                    <mat-select required formControlName="vertical" (focus)="onFocus('vertical')" (blur)="onBlur('vertical')">
                        <mat-option *ngFor="let vertical of verticalNameType" [value]="vertical.value">
                            {{vertical.display}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="w-11/12 mb-3" [class.mat-form-field-invalid]="blurredField.has('external_system') && newCustomerForm.controls.external_system.invalid">
                    <mat-label> Select Integration</mat-label>
                    <mat-select required [disabled]="!selectedVertical" formControlName="external_system" (focus)="onFocus('external_system')" (blur)="onBlur('external_system')">
                        <mat-option *ngFor="let externalSystem of externalSystemList[selectedVertical]" [value]="externalSystem.value">
                            {{externalSystem.display}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="w-11/12 mb-3" [class.mat-form-field-invalid]="blurredField.has('name') && newCustomerForm.controls.name.invalid">
                    <mat-label>Business Name </mat-label>
                    <input name="name" matInput formControlName="name" required (focus)="onFocus('name')" (blur)="onBlur('name')">
                </mat-form-field>
                <mat-form-field appearance="outline" class="w-11/12 mb-3" [class.mat-form-field-invalid]="blurredField.has('domain') && newCustomerForm.controls.domain.invalid">
                    <mat-label> Domain </mat-label>
                    <input matInput formControlName="domain" required (focus)="onFocus('domain')" (blur)="onBlur('domain')">
                    <mat-hint>Example: https://www.examplestore.com</mat-hint>
                    <mat-error *ngIf="blurredField.has('domain')">
                        <show-error [control]="newCustomerForm.controls.domain" [showRequiredError]="showRequiredError"></show-error>
                    </mat-error>
                </mat-form-field>
                <mat-form-field *ngIf="!externalIntegration && parent !== 'external'" appearance="outline" class="w-11/12 mb-3" [class.mat-form-field-invalid]="blurredField.has('google_account') && newCustomerForm.controls.google_account.invalid">
                    <mat-label>Google Account</mat-label>
                    <input name="google_account" matInput formControlName="google_account" (focus)="onFocus('google_account')" (blur)="onBlur('google_account')">
                </mat-form-field>
            </div>
            <div class="flex flex-col w-full" *ngIf="activeStep === 2 && !success && !nonAutomatedIntegrations.has(selectedIntegration)">
                <div class="text-lg my-4 ">{{ getIntegrationLabel(selectedIntegration, selectedVertical) }} Integration</div>
                <div *ngFor="let field of apiInfoFields" [ngClass]="{
                    'mb-4': field.example,
                    'flex items-center justify-between': true
                }">
                    <mat-form-field *ngIf="field.type !== 'boolean'" [ngSwitch]="field.type" appearance="outline" class="w-11/12 mb-3" [class.mat-form-field-invalid]="blurredField.has(field.name) && newCustomerForm.get(field.name).invalid">
                        <mat-label> {{ field.label }}</mat-label>
                            <input [required]="field.required" matInput *ngSwitchCase="'text'" [formControlName]="field?.name" (focus)="onFocus(field.name)" (blur)="onBlur(field.name)" />
                            <input [required]="field.required" matInput *ngSwitchCase="'number'" type="number" [formControlName]="field?.name" (focus)="onFocus(field.name)" (blur)="onBlur(field.name)" />
                            <mat-hint class="whitespace-normal break-words" *ngIf="field.example">Example: {{ field.example }}</mat-hint>
                            <mat-hint class="whitespace-normal break-words" *ngIf="field.hint">{{ field.hint }}</mat-hint>
                        <mat-error *ngIf="blurredField.has(field.name)">
                            <show-error [control]="newCustomerForm.get(field.name)"></show-error>
                        </mat-error>
                    </mat-form-field>
                    <mat-checkbox [required]="field.required" *ngIf="field.type === 'boolean'" class="pb-5" [checked]="checkbox" [formControlName]="field?.name" >
                        {{ field.label }}
                    </mat-checkbox>
                    <div *ngIf="field?.tooltip?.length > 0" class="helper-tooltip cursor-pointer relative" (mouseover)="toggleFieldHelper(field.name, true)" (mouseleave)="toggleFieldHelper(field.name, false)">
                        <mat-icon>help</mat-icon>
                        <div *ngIf="selectedTooltip == field.name" class="helper-tooltip-content fixed px-4 py-1 bg-black text-white bg-opacity-60" [innerHTML]="field.tooltip"></div>
                    </div>
                </div>
            </div>
            <div class="flex flex-col my-6" *ngIf="activeStep === 2 && nonAutomatedIntegrations.has(selectedIntegration)">
                <div *ngIf="selectedIntegration === 'shopify'">Download Aidaptive Shopify app here: <a class="underline my-4" target="_blank" href="https://apps.shopify.com/jarvis-ml-growth-engine">https://apps.shopify.com/jarvis-ml-growth-engine</a></div>
                <div *ngIf="selectedIntegration === 'custom'">Contact us at <a class="underline my-4" target="_blank" href="mailto:sales@aidaptive.com">sales&#64;aidaptive.com</a></div>
            </div>
            <!-- <app-user-info [formRef]="newCustomerForm"></app-user-info> -->
            <div *ngIf="isFormLoading" class="flex my-6 items-center justify-center">
                <div class="w-12 h-12 border-t-4 border-blue-500 border-solid rounded-full animate-spin mr-2"></div>
            </div>
            <div class="flex flex-col" *ngIf="(activeStep === 3 || activeStep === 4) && !isFormLoading">
                <div *ngFor="let field of surveyInfofields" [ngClass]="{
                    'mb-4': field.example
                }">
                    <mat-form-field *ngIf="field.type !== 'boolean'" [ngSwitch]="field.type" appearance="outline" class="w-11/12 mb-3" [class.mat-form-field-invalid]="blurredField.has(field.name) && newCustomerForm.get(field.name).invalid">
                        <mat-label> {{ field.label }}</mat-label>
                        <input [required]="field.required" matInput *ngSwitchCase="'string'" [formControlName]="field?.name" (focus)="onFocus(field.name)" (blur)="onBlur(field.name)" />
                        <input [required]="field.required" matInput *ngSwitchCase="'number'" type="number" [formControlName]="field?.name" (focus)="onFocus(field.name)" (blur)="onBlur(field.name)" />
                        <mat-select  [required]="field.required" [formControlName]="field?.name" *ngSwitchCase="'select'" (focus)="onFocus(field.name)" (blur)="onBlur(field.name)">
                            <mat-option *ngFor="let option of field.selectList" [value]="option.value">
                                {{option.viewValue}}
                            </mat-option>
                        </mat-select>
                        <mat-hint class="whitespace-normal break-words" *ngIf="field.example">Example: {{ field.example }}</mat-hint>
                        <mat-error *ngIf="blurredField.has(field.name)">
                            <show-error [control]="newCustomerForm.get(field.name)"></show-error>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <mat-error *ngIf="settingsErrorMsg?.length > 0">
                <div class="text-red">{{ settingsErrorMsg }}</div>
            </mat-error>
            <div *ngIf="success && parent !== 'external'" class="flex mt-4 items-center justify-center text-green-500">
                Congrats! Customer created successfully
            </div>
        </form>
        <div class="mb-2 text-gray-500 w-full flex justify-center" *ngIf="!isFormLoading">
            <div class="flex items-center justify-between w-11/12">
                <div class="relative" (click)="toggleSupportTooltip(true)" (mouseover)="toggleSupportTooltip(false, true)" (mouseleave)="toggleSupportTooltip(false, false)">
                    <mat-icon>help</mat-icon>
                    <div *ngIf="showContactTooltip" class="tooltip-detail absolute -left-4 -top-8 px-4 py-1 bg-black text-white bg-opacity-60">Need help? Contact us at <a class="cursor-pointer underline text-white" href="mailto:{{supportEmail}}">{{supportEmail}}</a></div>
                </div>
                <div class="flex">
                    <button type="button" class="text-gray-500 bg-white hover:bg-red-500 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-md border border-gray-300 w-24 text-sm font-medium px-5 py-2.5 focus:z-10" *ngIf="currentStep === 1 || success" (click)="closeAddCustomerForm()"> Close </button>
                    <button class="flex justify-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-md text-sm px-5 w-24 py-2.5 text-center ml-2" *ngIf="currentStep < totalSteps" type="button" (click)="handleFormSubmit(activeStep)">
                        <ng-container *ngIf="isLoading">
                            <div class="w-6 h-6 border-t-4 border-white-500 border-solid rounded-full animate-spin mr-2"></div>
                        </ng-container>
                        <ng-container *ngIf="!isLoading">
                            Next
                        </ng-container>
                    </button>
                    <button class="flex justify-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-md text-sm px-5 w-24 py-2.5 text-center ml-2" *ngIf="(currentStep === totalSteps && !success && !nonAutomatedIntegrations.has(selectedIntegration)) || shopifyFlow" type="button" type="submit" (click)="handleFormSubmit(activeStep)">
                        <ng-container *ngIf="isLoading">
                            <div class="w-6 h-6 border-t-4 border-white-500 border-solid rounded-full animate-spin mr-2"></div>
                        </ng-container>
                        <ng-container *ngIf="!isLoading">
                            Submit
                        </ng-container>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>