import { Component, ChangeDetectionStrategy, Input, ViewEncapsulation } from '@angular/core';

import { PageService } from 'src/app/services/page.service';


@Component({
  selector: 'page-index',
  templateUrl: './page-index.component.html',
  styleUrls: ['./page-index.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageIndexComponent {

  @Input() sections: any[];

  constructor(
    public pageService: PageService,
  ) { }

}
