import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'stats-card',
  templateUrl: './stats-card.component.html',
  styleUrls: ['./stats-card.component.scss']
})
export class StatsCardComponent implements OnInit {

  @Input() cardTitle?: string;
  @Input() prefix?: string;
  @Input() suffix?: string;
  @Input() value?: string;
  @Input() primaryTooltipText?: string;
  @Input() subtitle?: string;
  @Input() subtitle2?: string;
  @Input() secondaryValue?: string;
  @Input() secondaryTooltipText?: string;
  @Input() thirdValue?: string;
  @Input() thirdTooltipText?: string;
  @Input() showGraph: boolean;
  @Input() showSubtitle: boolean;
  @Input() loading: boolean;
  @Input() graphData: any[];
  @Input() showSubtitleNumberSign: boolean;
  
  layout = {
    height: 120,
    width: 100,
    showlegend: false,
    margin: {l:5, r:5, t:5, b:5},
    paper_bgcolor: "transparent"
  };

  config = {
    displayModeBar: false
  };

  constructor() {
    
  }

  ngOnInit(): void {
  }

  // TODO: Check if this can be done in html template
  getSecondaryValueStatusClass(value: string) {
    if (parseFloat(value.replace(/,/g, '')) > 0) {
      return 'stats-secondary-status-positive';
    }
    return 'stats-secondary-status-negative';
  }

  // TODO: Check if this can be done in html template
  getThirdValueStatusClass(value: string) {
    if (parseFloat(value.replace(/,/g, '')) > 0) {
      return 'stats-third-status-positive';
    }
    return 'stats-third-status-negative';
  }

  getNumberSign(value: string) {
    if (parseFloat(value.replace(/,/g, '')) > 0) {
      return '+';
    }
    return '-';
  }


}
