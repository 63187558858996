import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { shareReplay, catchError, retryWhen, scan, delay } from 'rxjs/operators';
import { EMPTY, Observable } from 'rxjs';

import { environment } from 'src/environments/environment'; // add ".local" for testing
import { UserService } from './user.service';


@Injectable({
  providedIn: 'root'
})
export class BackendDataService {

  private cache = {}; // see for example https://betterprogramming.pub/how-to-create-a-caching-service-for-angular-bfad6cbe82b0

  constructor(
    private userService: UserService,
    private http: HttpClient
  ) {
  }

  clearCache() {
    this.cache = {};
  }

  async fetchJsonData(apiUrl: string, useNewEndPoint = false, baseUrl?: string ): Promise<any[]> {
    const rawData = await this.fetchRawData(apiUrl, useNewEndPoint, baseUrl);
    const modifiedData = rawData.replace(/Infinity/g, '"Infinity"');
    const parsedData = JSON.parse(modifiedData, (_key, value) => {
      if (value === 'Infinity') {
        return Infinity;
      }
      return value;
    });
    return parsedData;
  }

  async fetchMultipleUrls(apiUrls: string[]): Promise<any[]> {
    const promises = [];

    apiUrls.forEach((apiUrl) => {
      promises.push(this.fetchJsonData(apiUrl));
    });
    return await Promise.all(promises);
  }

  async fetchImageData(apiUrl: string): Promise<string> {
    const rawData = await this.fetchRawData(apiUrl);
    if (!rawData) return ''; // means we do not have this data
    if (rawData[0] === '{') {
      try {
        const result = JSON.parse(rawData);
        if (result.status === 'no data') {
          return '';
        }
        console.error('Unexpected result:', JSON.stringify(result));
      } catch (err) {
        // ignore if not a valid JSON (just a "{" at beginning of raw data?)
      }
    }

    return `data:image/png;base64,${btoa(rawData)}`;
  }

  async fetchRawData(apiUrl: string, useNewEndPoint?, baseUrl?): Promise<any> {
    const accessToken = await this.userService.getIdToken();

    return new Promise((resolve, reject) => {
      let observable;
      const t0 = Date.now();

      if (this.cache[apiUrl]) {
        observable = this.cache[apiUrl];
      } else {
        observable = this.cache[apiUrl] = this.http.get(`${ baseUrl || (useNewEndPoint ? environment.newBackendAPI : environment.backendAPI)}${apiUrl}`, {
          headers: new HttpHeaders({
            'Content-Type': 'application/json', // not always true but seems to work
            'Authorization': `Bearer ${accessToken}`
          }),
          responseType: "arraybuffer"
        })
          .pipe(
            this.handleRetry,
            shareReplay(1),
            catchError(e => {
              delete this.cache[apiUrl];
              reject(this.handleError(e));
              return EMPTY;
            })
          );
      }
  
      observable.subscribe((response: ArrayBuffer) => {
        // NB: String.fromCharCode.apply(null, new Uint8Array(response)) is shorter
        // but cause the stack to blow because of parameter size. See for example https://stackoverflow.com/questions/38432611/converting-arraybuffer-to-string-maximum-call-stack-size-exceeded
        let binaryData = '';
        const bytes = new Uint8Array(response);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
          binaryData += String.fromCharCode(bytes[i]);
        }
        const duration = Date.now() - t0;
        const oneMegabyte = 1048576;
        if (duration > 5000 || len > oneMegabyte) {
          const size = len >= oneMegabyte
            ? `${(len / oneMegabyte).toFixed(1)}MB`
            : `${(len / 1024).toFixed(1)}KB`;
          console.warn(`fetchRawData ${Math.round(duration / 1000)}s for ${size} ${apiUrl}`);
        }
        resolve(binaryData);
      });  
    });
  }

  private handleRetry<T>(source: Observable<T>): Observable<T> {
    return source.pipe(retryWhen(e => e.pipe(scan((errorCount, error) => {
      // Do not retry unless 5xx
      if (String(error.status)[0] !== '5') {
        throw error;
      }
      if (errorCount >= 3) {
        throw error;
      }
      return errorCount + 1;
    }, 1),
    delay(1000)
    )));
  }

  private handleError(error: HttpErrorResponse): string {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.error(errorMessage);
    return errorMessage;
  }

}
