<div *ngIf="loading" role="status"
    class="w-full p-10 space-y-4 border border-gray-300 divide-y divide-gray-300 rounded shadow animate-pulse">
    <div class="flex items-center justify-between">
        <div>
            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
            <div class="w-32 h-2 bg-gray-300 rounded-full dark:bg-gray-700"></div>
        </div>
        <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-700 w-12"></div>
    </div>
    <div class="flex items-center justify-between pt-4">
        <div>
            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
            <div class="w-32 h-2 bg-gray-300 rounded-full dark:bg-gray-700"></div>
        </div>
        <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-700 w-12"></div>
    </div>
    <div class="flex items-center justify-between pt-4">
        <div>
            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
            <div class="w-32 h-2 bg-gray-300 rounded-full dark:bg-gray-700"></div>
        </div>
        <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-700 w-12"></div>
    </div>
    <div class="flex items-center justify-between pt-4">
        <div>
            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
            <div class="w-32 h-2 bg-gray-300 rounded-full dark:bg-gray-700"></div>
        </div>
        <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-700 w-12"></div>
    </div>
    <div class="flex items-center justify-between pt-4">
        <div>
            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
            <div class="w-32 h-2 bg-gray-300 rounded-full dark:bg-gray-700"></div>
        </div>
        <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-700 w-12"></div>
    </div>
</div>

<div *ngIf="errorFetchingData" class="flex justify-center items-center">
    <div class="bg-yellow-300 w-full p-10 text-center">
        Error Fetching Summary Data
    </div>
</div>

<div *ngIf="noData" class="flex justify-center items-center">
    <div class="bg-yellow-300 w-full p-10 text-center">
        No Summary Data
    </div>
</div>

<div *ngIf="!loading && !errorFetchingData && rawData?.length && isVisible()"
    class="container tracking-wider mx-auto px-2">
    <div class="bg-blue-600 text-white px-5 py-4 text-left font-extrabold text-lg rounded-t-md flex tracking-wide">
        {{config.title}}
        <div class="seperater" *ngIf="inputParams.startDate">|</div>
        <div *ngIf="inputParams.startDate">
            <div class="date">{{ inputParams.startDate | date }} - {{ inputParams.endDate | date }}</div>
        </div>
    </div>
    <div class="px-8 py-4 pb-6 lg:mt-0 rounded shadow-lg bg-white">
        <table class="stripe hover" style="width:100%;">
            <thead>
                <tr>
                    <th></th>
                    <th class="text-right p-1 underline">
                        Control Group
                    </th>
                    <th class="text-right p-1 underline">
                        Aidaptive
                    </th>
                    <th class="text-right p-1 underline">
                        Variance
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr class="border-b" *ngFor="let data of rawData">
                    <ng-container *ngIf="!growthPlan() || user.jarvisml_group; else showLimitedRows">
                        <ng-container>
                            <td class="text-left font-bold p-1">
                                {{ data.Metrics }}
                            </td>
                            <td class="text-right p-1">
                                {{
                                (data.Metrics === 'Revenue' || data.Metrics === 'Average Order Value' || data.Metrics
                                ===
                                'Revenue per Visitor')
                                ? formatMoney(data.Control, 'USD')
                                : (data.Metrics === 'Conversion Rate' || data.Metrics === 'Click Through Rate')
                                ? (data.Control | number: '1.2-2') + '%'
                                : (data.Metrics === 'Orders' || data.Metrics === 'Visitors' || data.Metrics ===
                                'Conversion')
                                ? formatValue(data.Control)
                                : ''
                                }}
                            </td>

                            <td class="text-right p-1">
                                {{
                                (data.Metrics === 'Revenue' || data.Metrics === 'Average Order Value' || data.Metrics
                                ===
                                'Revenue per Visitor')
                                ? formatMoney(data.Aidaptive, 'USD')
                                : (data.Metrics === 'Conversion Rate' || data.Metrics === 'Click Through Rate')
                                ? (data.Aidaptive | number: '1.2-2') + '%'
                                : (data.Metrics === 'Orders' || data.Metrics === 'Visitors' || data.Metrics ===
                                'Conversion')
                                ? formatValue(data.Aidaptive)
                                : ''
                                }}
                            </td>
                            <td class="p-1 font-bold">
                                <div class="flex justify-end items-center text-right"
                                    [ngClass]="{'greenColor': data.Aidaptive > data.Control, 'text-red-600': data.Aidaptive < data.Control}">
                                    <span><mat-icon *ngIf="data.Aidaptive > data.Control"
                                            class="text-base add-icon">add</mat-icon></span>
                                    <p>{{ handleInfinityVariance(data) }}</p>
                                </div>
                            </td>
                        </ng-container>
                    </ng-container>
                    <ng-template #showLimitedRows>
                        <ng-container *ngIf="!nonGrowthPlanRows.includes(data.Metrics)">
                            <td class="text-left font-bold p-1">
                                {{ data.Metrics }}
                            </td>
                            <td class="text-right p-1">
                                {{
                                (data.Metrics === 'Revenue' || data.Metrics === 'Average Order Value' || data.Metrics
                                ===
                                'Revenue per Visitor')
                                ? formatMoney(data.Control, 'USD')
                                : (data.Metrics === 'Conversion Rate' || data.Metrics === 'Click Through Rate')
                                ? (data.Control | number: '1.2-2') + '%'
                                : (data.Metrics === 'Orders' || data.Metrics === 'Visitors' || data.Metrics ===
                                'Conversion')
                                ? formatValue(data.Control)
                                : ''
                                }}
                            </td>

                            <td class="text-right p-1">
                                {{
                                (data.Metrics === 'Revenue' || data.Metrics === 'Average Order Value' || data.Metrics
                                ===
                                'Revenue per Visitor')
                                ? formatMoney(data.Aidaptive, 'USD')
                                : (data.Metrics === 'Conversion Rate' || data.Metrics === 'Click Through Rate')
                                ? (data.Aidaptive | number: '1.2-2') + '%'
                                : (data.Metrics === 'Orders' || data.Metrics === 'Visitors' || data.Metrics ===
                                'Conversion')
                                ? formatValue(data.Aidaptive)
                                : ''
                                }}
                            </td>
                            <td class="p-1 font-bold">
                                <div class="flex justify-end items-center text-right"
                                    [ngClass]="{'greenColor': data.Aidaptive > data.Control, 'text-red-600': data.Aidaptive < data.Control}">
                                    <span><mat-icon *ngIf="data.Aidaptive > data.Control"
                                            class="text-base add-icon">add</mat-icon></span>
                                    <p>{{ handleInfinityVariance(data) }}</p>
                                </div>
                            </td>
                        </ng-container>
                    </ng-template>
                </tr>
            </tbody>
        </table>
    </div>
</div>