import { Component, Input, OnInit } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

@Component({
  selector: 'show-error',
  templateUrl: './show-error.component.html',
  styleUrls: ['./show-error.component.scss']
})
export class ShowErrorComponent implements OnInit {
  @Input() control: ValidationErrors;
  @Input() showRequiredError: boolean;
  
  regexp;
  domainRegexp;
  
  ngOnInit(): void {
    this.regexp = new RegExp(/^(?!.*\/).*$/);

    const domainPattern = /^(?:(https?:\/\/)([\da-z.-]+)\.([a-z.]{2,6})[/\w .-]*\/?)|(?:^$)$/i;
    this.domainRegexp = new RegExp(domainPattern);
  }

}
