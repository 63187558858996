import { Component, OnInit } from '@angular/core';
import { DeeplinkService } from '../services/deeplink.service';
import { UserService } from '../services/user.service';
import { AidaptiveCustomerPageService } from '../services/aidaptive-customer-page.service';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {

  externalIntegration: string;
  redirectUrl: string;
  newClient = false;

  constructor(
    private userService: UserService,
    private deeplinkService: DeeplinkService,
    private aidaptiveCustomerService: AidaptiveCustomerPageService,
  ) { }

  async ngOnInit() {
    const params = this.deeplinkService.parseRoute("/welcome");
    this.externalIntegration = params.integrationName;
    this.redirectUrl = params.redirectTo;

    const user = await this.userService.getUser();

    if (user.cid && user.enabled && await this.aidaptiveCustomerService.isJourneyReady(user.cid)) {
      this.deeplinkService.navigateToUrl('/knowledge-graph');
    } else {
      this.newClient = true;
    }
  }

  closeForm(success: boolean) {
    if (success) {
      this.deeplinkService.navigateToUrl('/all-set');
    } else {
      this.deeplinkService.navigateToUrl('/signup');
    }
  }

}
