<div class="darkDiv">

<div class="msg-box">
  <div [className]="extraClass">

    <div class="message" [innerText]="message"></div>

    <div class="buttons">
      <button jarvis-button type="button" (click)="choice(0)" [innerText]="choices[0]" #focus></button>
      <button *ngIf="choices[1]" jarvis-button color="button-color-modest" type="button" (click)="choice(1)" [innerText]="choices[1]"></button>
      <button *ngIf="choices[2]" jarvis-button color="button-color-modest" type="button" (click)="choice(2)" [innerText]="choices[2]"></button>
    </div>
  
  </div>
</div>

</div>
