<div *ngIf="item" [ngClass]="selectedEntityDisplay ? 'selected-listing-config' : 'px-9 pb-7 pt-7 max-w-md'" class="bg-white rounded-lg relative shadow-lg h-full">
    <div class="rounded overflow-hidden flex items-center">
        <input [checked]="isListingChecked(item)" type="checkbox" class="checkbox-input absolute top-0 left-0 mt-4 ml-2 h-4 w-4" (change)="onCheckboxChange(item.id || item.listing_id, $event.target.checked)" />
    </div>
    <div *ngIf="entityImage" class="w-full h-auto rounded overflow-hidden flex items-center">
        <img [src]="entityImage" data-testid="productImage"
            class="w-full h-auto object-cover" />
    </div>

    <div class="flex flex-col justify-center">
        <div class="flex justify-between items-center mt-2 mx-auto">
            <div class="font-bold text-xl text-center">{{item?.title || item?.name || 'Name Not Available'}}</div>
        </div>
        <div *ngIf="!selectedEntityDisplay" class="flex justify-between items-center my-2 mx-auto text-center">
            <div class="text-sm line-clamp-3">{{ item?.desc || item?.description }}</div>
        </div>
    </div>
</div>