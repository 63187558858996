import { Component, OnInit, ChangeDetectionStrategy, ViewContainerRef } from '@angular/core';

@Component({
  selector: 'app-block',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BlockComponent implements OnInit {

  constructor(public vcRef: ViewContainerRef) { }

  ngOnInit(): void {
  }

}
