<div class="upload-new">
  <div class="frame">
    <div class="label">Upload file</div>
    <div class="upload-btn">
        <input type="file"
          id="file"
          (change)="onFileSelected($event)"
          name="upload"
          autocomplete="off"
        >
    </div>
  </div>
</div>
