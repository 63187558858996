import { Component, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { KeyboardService } from 'src/app/services/keyboard.service';

@Component({
  selector: 'msg-box',
  templateUrl: './msg-box.component.html',
  styleUrls: ['./msg-box.component.scss']
})
export class MsgBoxComponent {

  @Input() message: string;
  @Input() choices: string[] = ['OK'];
  @Input() extraClass = '';
  @Output() choiceEvent = new EventEmitter<number>();

  @ViewChild('focus', { read: ElementRef }) set dialogFocus(child: ElementRef) { this.keyboardService.setDialogFocus(child); }

  constructor(
    private keyboardService: KeyboardService,
  ) { }

  choice(index: number): void {
    this.choiceEvent.emit(index);
  }
}
