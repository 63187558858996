<div *ngIf="!loading && parent?.placeholderText && !parent?.placeholderIsError" [class]="parent.graphPlaceholderClass">
  <div class="text" [innerHTML]="parent.placeholderText"></div>
</div>
<div *ngIf="!loading && data" class="graphFrame">
  <div *ngIf="showFilters" class="graph-fields">
    <form [formGroup]="filtersForm">
      <!-- TODO: Handle filter configs in a generic way inside graph component -->
      <ng-container *ngIf="filterCollection">
        <mat-form-field *ngFor="let item of filterCollection | keyvalue" class="graph-filter-field" appearance="outline">
          <mat-label> {{formatLabel(item.key,true)}} </mat-label>
          <mat-select formControlName={{item.key}} (selectionChange)="filterChange($event,item.key)">
            <mat-option *ngFor="let childItem of item.value" [value]="childItem.value">
              {{childItem.viewValue}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>
    </form>
  </div>
  <plotly-plot [className]="'plotGraph'"
    [data]="data"
    [config]="plotlyConfig"
    [layout]="layout"
    [useResizeHandler]="true"
    [style]="{width: '100%' , height: '100%'}"
    (initialized)="onPlotInit()"
  >
  </plotly-plot>
  <div *ngIf="footerText" class="footer" [innerHTML]="footerText"></div>
</div>
