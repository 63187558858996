import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'company-links',
  templateUrl: './company-links.component.html',
  styleUrls: ['./company-links.component.scss']
})
export class CompanyLinksComponent implements OnInit {
  public currentYear: any;
  constructor() { }

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
  }

}
