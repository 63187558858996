import { FileEntity } from 'src/app/models/file.model';
import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/compat/firestore';
import { LabelsetEntity, LabelEntity } from '../models/labelset.model';
import { DatasetEntity } from 'src/app/models/dataset.model';
import { FileExEntity } from '../models/file.model';
import { DocumentData, Timestamp, serverTimestamp } from 'firebase/firestore';

@Injectable({
  providedIn: 'root'
})
export class LabelsetService {

  constructor(
    private firestore: AngularFirestore,
  ) { }

  async createLabelset(
    cid: string, dataset: DatasetEntity, classes: string[],
    userId: string
  ): Promise<string> {

    const labelsetEntity: LabelsetEntity = {
      datetime: <Timestamp>serverTimestamp(),
      created_by: userId,
      data_type: dataset.data_type,
      dataset: this.firestore.doc<DocumentData>(`Customers/${cid}/Datasets/${dataset.id}`).ref,
      classes: classes.concat()
    };

    const collection = this.firestore.collection(`Customers/${cid}/Labelsets`);

    const docRef = await collection.add(labelsetEntity);
    console.log(`Created labelset ${docRef.id}`);

    return docRef.id;
  }

  async catchUpEmptyLabels(cid: string, labelsetId: string, datasetId: string): Promise<number> {
    console.log(`Getting empty labels for labelset ${labelsetId} dataset ${datasetId}...`);

    const labelsetDoc = this.firestore.doc<LabelsetEntity>(`Customers/${cid}/Labelsets/${labelsetId}`);
    const docSnapshot = await labelsetDoc.get().toPromise();
    const data = docSnapshot.data();

    // Query on Dataset
    const collection = this.firestore.collection<FileEntity[]>(`Customers/${cid}/Datasets/${datasetId}/files`,
      data.caught_up_until
        ? ref => ref.where('datetime', '>', data.caught_up_until).orderBy('datetime').limit(10)
        : ref => ref.orderBy('datetime').limit(10)
    );
    const snapshot = await collection.get().toPromise();
    const fileEntities : FileEntity[] = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

    let count = 0;
    for (let i = 0; i < fileEntities.length; i++) {
      const fileEntity = fileEntities[i];
      const doc = this.firestore.doc(`Customers/${cid}/Labelsets/${labelsetId}/Labels/${fileEntity.id}`);
      const docSnapshot = await doc.get().toPromise();
      if (!docSnapshot.exists) {
        console.log(`Creating empty record for labelset ${labelsetId} dataset ${datasetId}, doc ${fileEntity.id}`);
        await doc.set({ labels: null }, { merge: true });
        count++;
      }
    }
    if (count === 0) {
      console.log(`Labelset ${labelsetId} seems to be up to date with dataset ${datasetId}`);
      return 0;
    }
    const lastFile = fileEntities[fileEntities.length - 1];
    const lastDate = lastFile.datetime;
    await labelsetDoc.update({ caught_up_until: lastDate });

    return count;
  }

  // TODO: review this - unused now - API
  async getLabels(cid: string, labelsetId: string, fileEntity: FileExEntity) {
    const path = `Customers/${cid}/Labelsets/${labelsetId}/Labels/${fileEntity.id}`;
    const doc = this.firestore.doc<FileExEntity>(path);
    const snapshot = await doc.get().toPromise();
    const data = snapshot.data();
    fileEntity.labels = data
      ? data.labels
      : null; // no labels set yet
  }

  async updateLabels(cid: string, labelsetId: string, fileId: string, labels: string[]) {
    const path = `Customers/${cid}/Labelsets/${labelsetId}/Labels/${fileId}`;
    const doc = this.firestore.doc(path);
    return await doc.set({ labels }, { merge: true });
  }

  async getLabeledSample(cid: string, labelsetId: string,
    labelFilterBy: string, sampleSize: number
  ): Promise<LabelEntity[]> {
    const path = `Customers/${cid}/Labelsets/${labelsetId}/Labels`;

    let queryFn: QueryFn;
    if (labelFilterBy === null) {
      queryFn = (ref) => ref.where('labels', '==', null).limit(sampleSize);
    } else if (labelFilterBy === '') {
      queryFn = (ref) => ref.where('labels', '==', []).limit(sampleSize);
    } else {
      queryFn = (ref) => ref.where('labels', 'array-contains', labelFilterBy).limit(sampleSize);
    }
    const collection = this.firestore.collection<LabelEntity>(path, queryFn);
    const snapshot = await collection.get().toPromise();
    const docs = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    return docs;
  }
}
