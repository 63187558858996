<div *ngIf="parent.placeholderText && !parent?.placeholderIsError" [class]="parent.graphPlaceholderClass">
  <div class="text" [innerHTML]="parent.placeholderText"></div>
</div>
<div *ngIf="!loading && imageData" class="imgGraph">
  <div class="frame">
    <div class="graphTitle">{{config.title}}</div>
    <div *ngIf="config.subtitle" class="subtitle">{{config.subtitle}}</div>
    <img src="{{ imageData }}" />  
  </div>
</div>
