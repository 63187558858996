import { Component, OnInit, Input, ViewEncapsulation, HostBinding, ViewChild } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { marked } from 'marked';
marked.setOptions({ async: false }); // anyway this is default but rather be explicit

import { TableGraphComponent } from '../table-graph/table-graph.component';
import { UserEntity } from 'src/app/models/user.model';
import { GraphSettings } from 'src/app/util/GraphSettings';
import { FormService } from 'src/app/services/form.service';
import { MsgboxService } from 'src/app/services/msgbox.service';
import { CustomerService } from 'src/app/services/customer.service';
import { MetricsDashboardService } from 'src/app/services/metrics-dashboard.service';
import { GraphPickerConfig } from 'src/app/shared/graph-picker/graph-picker.component';
import { GraphPickerService } from 'src/app/services/graph-picker.service';
import { DeeplinkService } from 'src/app/services/deeplink.service';
import { PageService } from 'src/app/services/page.service';


export interface GraphConfig {
  type: 'mygraph'|'table'|'image'|'chart'| 'summaryTable' | ' grid';
  id: string;
  url: string;              // URL to query data from backend
  title: string;
  subtitle?: string;
  settings?: GraphSettings;
  graphPicker?: GraphPickerConfig;
  hideHelpButton?: boolean;
  size?: string; // e.g. "small" or "regular"
  showPinButton?: boolean;
  showFavoriteButton?: boolean;
  pinnedConfig?: any;
  showExportDataBtn?: boolean;
  hasIssue?: boolean;
}

export interface InputParams {
  startDate?: Date;
  endDate?: Date;
  market?: string;
  client?: string;
  customerType?: string;
  featureTypes?: any;
  featureColumn?: string;
  granularity?: string;
  status?: string;
  pageType?: any;
  days?: string;
  filterObj?: Record<string, unknown>;
  analyticsVersion?: string;
}


@Component({
  selector: 'generic-graph',
  templateUrl: './generic-graph.component.html',
  styleUrls: ['./generic-graph.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GenericGraphComponent implements OnInit {

  @Input() config: GraphConfig;
  @Input() user: UserEntity;
  @Input() inputParams: InputParams;
  @Input() size: string; // TODO: remove this when always passed via GraphConfig.size

  @HostBinding('hidden') isHidden = false;
  @ViewChild(TableGraphComponent) tableGraph: TableGraphComponent;

  public placeholderText: string;
  public graphPlaceholderClass = 'graphPlaceholder';
  public placeholderIsError = false;
  loading: boolean;
  skeleton = [];
  public renderedBreakpoints: any; //table size based on screen size
  public renderedSize: string;

  constructor(
    private customerService: CustomerService,
    private graphPickerService: GraphPickerService,
    private genericDashboardService: MetricsDashboardService,
    private pageService: PageService,
    private formService: FormService,
    private msgboxService: MsgboxService,
    private deeplinkService: DeeplinkService,
    private router: Router,
    private bp: BreakpointObserver,
  ) {
  }

  async ngOnInit() {
    this.bp.observe([
      Breakpoints.XSmall, //(max-width: 599.98px)
      Breakpoints.Small, //(min-width: 600px) and (max-width: 959.98px)
      Breakpoints.Medium, // (min-width: 960px) and (max-width: 1279.98px)
      Breakpoints.WebLandscape,  //(min-width: 1920px) 
    ]).subscribe(async res => {
      await this.getGraphSizeByBp(res.breakpoints);
    });
    this.createSkeleton();

    this.config.title = this.config.settings?.title || this.config.title;
    this.config.subtitle = this.config.settings?.subtitle || this.config.subtitle;
    
    this.size = this.size || this.config.size;
    if (this.size === 'small') {
      this.graphPlaceholderClass = 'graphPlaceholderSmall';
    }

    this.showLoading();
    
  }

  private getGraphSizeByBp(bp):Promise<any>{
    return new Promise((resolve) => {
      this.renderedSize = null;
      if (bp[Breakpoints.XSmall]) {
        this.renderedSize = 'xs';
      } else if (bp[Breakpoints.Small]) {
        this.renderedSize = 's';
      } else if (bp[Breakpoints.Medium]) {
        this.renderedSize = 'm';
      } else if (bp[Breakpoints.WebLandscape]) {
        this.renderedSize = 'l';
      }
      resolve(this.renderedSize);
    });
  }

  createSkeleton(){
    const count = (this.renderedSize == 'xs'|| this.renderedSize == 's' || this.renderedSize == 'm') ? 9 : 11;
    const maxHeight = (this.renderedSize == 'xs' || this.renderedSize == 's') ? 200 : this.renderedSize == 'm' ? 300 : 400;

    for (let i = 0; i < count; i++) {
      if (this.config.type == 'table'){
        this.skeleton[i] = {
          height: '25px',
          margin: '10px'
        };
      } else if (this.config.type == 'mygraph'){
        this.skeleton[i] = {
          width: '25px',
          'height.px': Math.floor((Math.random() * (maxHeight - 100)) + 100),
          margin: '10px'
        };
      }
    }
  }

  canExport() {
    return this.user?.enabled || this.user?.jarvisml_group;
  }

  exportTableData(){
    this.tableGraph.exportUngroupedData();
  }

  isVisible() {
    return this.config?.settings && !this.config.settings.hidden;
  }

  canEdit() {
    return this.user.jarvisml_group ||
      (this.config && this.config.settings.getUserSettingList().length > 0);
  }

  async editAction() {
    if (this.user.jarvisml_group) {
      const customer = await this.customerService.getCustomer();

      const fields = this.config.settings.getCustomerSettingList(this.user.jarvisml_group);

      if (this.user.jarvisml_group && this.config.graphPicker) {
        fields.push({
          name: 'graphPicker', label: this.config.graphPicker.buttonLabel(this.config), type: 'button',
          init: () => { this.formService.hideForm(); this.graphPickerService.showPicker(this.config); }
        });
      }
  
      const options = await this.formService.showForm(`Graph Options for ALL in ${customer.name}`, fields);
      if (options) {
        this.config.settings.multiChangeForCustomer(options);
      }
    } else { // external (non-Aidaptive) user
      const options = await this.formService.showForm('Graph Options',
        this.config.settings.getUserSettingList()
      );
      if (options) {
        this.config.settings.multiChangeForUser(options);
      }
    }
  }

  async onPinClick() {
    // Save pinned config
    if (!this.user.cid || !this.config.pinnedConfig) {
      console.error("Error saving pinned config");
    }
    this.customerService.savePinnedConfig(this.user.cid, this.config);
  }

  async onUserFavorited() {
    // Save pinned config to user's customized dashboard
    if (!this.user.cid || !this.config.pinnedConfig) {
      console.error("Error saving pinned config for the user");
    }
    this.genericDashboardService.saveUserFavoritedGraph(this.user, this.config);
  }

  async onRemovePinClick() {
    if (!this.user.cid) {
      console.error("Error removing pin");
    }
    this.customerService.removePin(this.user.cid, this.config);
  }

  hasHelp() {
    // When applicable we can add help for other graph types
    return this.config.type === 'mygraph' && !this.config.hideHelpButton;
  }

  showHelp() {
    this.msgboxService.showMsg(
      '- Click and drag on the graph to select an area to zoom in on\n' +
      '- To zoom out and reset, double-click the graph\n' +
      '- Click and drag the X or Y axis to move along forward or backward',
      { class: 'preText' }
    );
  }

  hasDeepLink() {
    return false;
  }
  copyDeepLink() {
    this.deeplinkService.copyDeepLinkToItem(this.config.id);
  }

  getIntroHtml(): string {
    return this.formatMd(this.config.settings.intro);
  }

  getOutroHtml(): string {
    return this.formatMd(this.config.settings.outro);
  }

  private formatMd(mdText) {
    if (!mdText) return '';
    
    return marked.parse(mdText) as string;
  }

  showLoading() {
    this.loading = true;
    this.placeholderText = `Loading "${this.config.title}"...`;
  }

  showData() {
    this.loading = false;
    this.placeholderText = '';
  }

  showNoData() {
    this.loading = false;
    this.showIssue(`${this.config.title}<br><br>No data`);
  }

  showFailure(error) {
    this.loading = false;
    this.showIssue(`${this.config.title}<br><br>Failed to retrieve data`);
    console.error(error);
  }

  // Show an issue - depending who the user is, we show it differently
  private showIssue(message: string) {
    if (this.user.jarvisml_group) {
      this.placeholderText = message;
      this.placeholderIsError = true;
      this.config.hasIssue = true;
      this.pageService.forceRefreshIndex();
    } else {
      this.isHidden = true;
      this.pageService.hideBrokenGraphFromIndex(this.config.id);
      console.warn(message);
    }
  }

}
