<div class="shell">
  <navigation-bar></navigation-bar>

  <div *ngIf="graphPicker.config" class="graphPicker">
    <graph-picker [parentConfig]="graphPicker.config"></graph-picker>
  </div>
  
  <generic-form *ngIf="form.title"
    [title]="form.title"
    [fields]="form.fields"
    [options]="form.options"
    (closeEvent)="form.close($event)">
  </generic-form>
  <generic-form *ngIf="form2.title"
    [title]="form2.title"
    [fields]="form2.fields"
    [options]="form2.options"
    (closeEvent)="form2.close($event)">
  </generic-form>

  <msg-box *ngIf="msgbox.question"
    [extraClass]="msgbox.extraClass"
    [message]="msgbox.question"
    [choices]="msgbox.choices"
    (choiceEvent)="msgbox.close($event)">
  </msg-box>

  <app-spinner *ngIf="spinner.pendingCount || spinner.count"
    [pendingCount]="spinner.pendingCount"
    [label]="spinner.label">
  </app-spinner>

  <mat-sidenav-container>
    <mat-sidenav-content>
      <ng-content>
      </ng-content>
    </mat-sidenav-content>
  </mat-sidenav-container>  
</div>

