<div *ngIf="control && control.errors && (control.dirty || control.touched)" class="error-msg">
    <div *ngIf="control.errors.required && showRequiredError">This field is required</div>
    <div *ngIf="control.errors.minlength">
        Min required field is {{control.errors.minlength.requiredLength}}. Current length is {{control.errors.minlength.actualLength}}.
    </div>
    <div *ngIf="control.errors.maxlength">Max required length is {{control.errors.maxlength.requiredLength}}.
        Current length is {{control.errors.maxlength.actualLength}}.
    </div>
    <div *ngIf="control.errors.email">Not a valid email address</div>
    <div *ngIf="control.errors.pattern?.requiredPattern == regexp"> must not contain "/"</div>
    <div *ngIf="control.errors.confirmedValidator">Passwords do not match</div>
    <div *ngIf="control.errors.pattern?.requiredPattern == domainRegexp">Error: Invalid domain</div>
</div>