import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { DeeplinkService } from '../services/deeplink.service';
import { SnackService } from '../services/snack.service';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {
  
  getLevel(): string { return 'viewer'; }

  constructor(
    private deeplinkService: DeeplinkService,
    private userService: UserService,
    private snack: SnackService
  ) {}

  async canActivate(
    _next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    try {
      const user = await this.userService.getUser();
      if (this.userService.isUserGranted(user, this.getLevel())) {
        return true;
      } else {
        this.deeplinkService.navigateToUrl('/not-authorized');
        return false;
      }
    } catch (error) {
      console.log("User is not logged in");
      this.snack.showError('You must be logged in!');
      this.deeplinkService.navigateBeforeLogin(state.url);
      return false;  
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class AdminAuthGuard extends AuthGuard {
  getLevel(): string { return 'admin'; }
}
