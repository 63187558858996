import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, AbstractControl } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { KeyboardService } from 'src/app/services/keyboard.service';
import { SnackService } from 'src/app/services/snack.service';

export interface FieldDescription {
  name?: string; // type "button" and "label" do not need a name 
  label: string;
  type: 'boolean'|'button'|'label'|'multiline'|'string'|'integer'|'select'|'list'|'link'|'date';
  init?: string|boolean|number|Date|string[]|((data?:any)=>void); // for a button, this is the function/action
  selectList?: SelectList; // list of {value/label} for type "select"; use makeSelectList to create it
  autoComplete?: string[]; // list of values proposed by auto-complete for type "string"
  regexp?: RegExp;
  disabled?: boolean;
  isHidden?: boolean;
  required?: boolean;
  data?: any; // anything you want - use it together with returnsChanges==true below
  classNames?: string;
  value?: string; //default value of the field
}

export interface GenericFormOptions {
  returnsChanges?: boolean; // if true changed values are returned (instead of all values)
  showFilter?: 'auto' | 'no' | 'yes'; // default is "auto" (=> filter if a certain number of fields)
  validateFn?: (info: any) => string; // validation function returns an error msg OR empty string if all is valid
}

export interface GenericFormChange {
  value: any;
  field: FieldDescription;
}

export function buildForm(formBuilder: UntypedFormBuilder, fields: FieldDescription[]): UntypedFormGroup {
  const fieldMap = {};
  for (const f of fields) {
    if (!f.name || !HAS_VALUE[f.type]) continue;

    fieldMap[f.name] = [{ value: f.init, disabled: f.disabled }];
    if (f.required) {
      fieldMap[f.name].push([Validators.required]);
    }
    if (f.regexp) {
      // Add ^ and $ around the reg exp if they have been forgotten
      const re = f.regexp.toString().slice(1, -1);
      let newRe = re;
      if (!newRe.startsWith('^')) newRe = '^' + newRe;
      if (!newRe.endsWith('$')) newRe += '$';
      if (newRe !== re) {
        f.regexp = new RegExp(newRe);
      }
    } else if (f.type === 'integer') {
      f.regexp = /^(-|\+)?[0-9]+$/;
    }
  }
  return formBuilder.group(fieldMap);
}

export function checkForm(
  formGroup: UntypedFormGroup,
  fields: FieldDescription[],
  options: GenericFormOptions
): { [id: string]: GenericFormChange|any } {
  if (!formGroup.valid) {
    // workaround to force-show untouched invalid fields (see https://github.com/angular/components/issues/9788)
    Object.keys(formGroup.controls).forEach(fieldName => {
      // NB: we don't use formGroup.get(fieldName) since fieldName may contain "."
      formGroup.controls[fieldName].markAsTouched({ onlySelf: true });
    });
    return null;
  }
  const result = {};
  for (const f of fields) {
    if (!HAS_VALUE[f.type]) continue;
    const value = f.disabled // NB: Angular returns undefined for the disabled ones...
      ? f.init
      : formGroup.value[f.name];

    if (options?.returnsChanges) {
      if (value === f.init) continue; // value is unchanged
      result[f.name] = <GenericFormChange>{ value, field: f };
    } else {
      result[f.name] = value;
    }
  }
  
  if (options?.validateFn) {
    const errorMsg = options.validateFn(result);
    if (errorMsg) {
      return { _errorMsg: errorMsg };
    }
  }
  return result;
}

export interface SelectListItem {
  value: string;
  viewValue: string;
}
export type SelectList = SelectListItem[];

export function makeSelectList(valueMap: any[] | { [key: string]: any }): SelectList {
  if (valueMap.length) {
    return valueMap.map(e => ({ value: e, viewValue: e }));
  } else {
    return Object.entries(valueMap).map(e => ({ value: e[0], viewValue: e[1] }));
  }
}

const HAS_VALUE = {
  'boolean': true,
  'multiline': true,
  'string': true,
  'integer': true,
  'select': true,
  'list': true,
  'date': true,
};

@Component({
  selector: 'generic-form',
  templateUrl: './generic-form.component.html',
  styleUrls: ['./generic-form.component.scss']
})
export class GenericFormComponent implements OnInit {

  @Input() fields: FieldDescription[];
  @Input() options: GenericFormOptions;
  @Input() title: string;
  @Output() closeEvent = new EventEmitter<any>();

  public editForm: UntypedFormGroup;
  enteredSearchVal = '';

  @ViewChild('focus', { read: ElementRef }) set dialogFocus(child: ElementRef) { this.keyboardService.setDialogFocus(child); }
  @ViewChildren('datePicker') datePickerRefs: QueryList<MatDatepicker<unknown>>;

  constructor(
    private keyboardService: KeyboardService,
    private snackService: SnackService,
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    this.editForm = buildForm(this.formBuilder, this.fields);
  }

  getField(name: string): AbstractControl {
    return this.editForm.controls[name];
  }

  trackSelectListItem(_index: number, item: SelectListItem) {
    return item.value;
  }
  trackField(_index: number, item: FieldDescription) {
    return item.name;
  }

  getDatePicker(name: string) {
    if (!this.datePickerRefs) return undefined;
    const datePicker = this.datePickerRefs.find((p) => {
      return p['_viewContainerRef'].element.nativeElement.id === name;
    });
    return datePicker;
  }

  onKeyup(event: KeyboardEvent) {
    if (event.code === 'Escape') {
      this.cancelForm();
    }
  }

  isFilterActive() {
    const value = this.options?.showFilter || 'auto';
    if (value === 'auto') {
      return this.fields.length > 7; // 7 is not exactly random (https://thebrain.mcgill.ca/flash/capsules/experience_jaune03.html)
    } else {
      return value === 'yes';
    }
  }

  isFilteredIn(field: FieldDescription): boolean {
    if (field.isHidden) return false;
    if (!field.name || !HAS_VALUE[field.type]) {
      return true; // leave "neutral" fields visible
    }
    if (!this.enteredSearchVal) return true;
    return field.label.toLowerCase().includes(this.enteredSearchVal.toLowerCase());
  }

  onAutoCompleteChange(field: FieldDescription) {
    const filterValue = this.editForm.value[field.name].toLowerCase();
    field.data = field.autoComplete.filter(item => {
      return item.toLowerCase().indexOf(filterValue) >= 0;
    });
  }

  buttonClick(field: FieldDescription) {
    const action: (form: GenericFormComponent) => any = field.init as any;

    const result = action(this);

    if (typeof result === 'string') {
      this.submit(result);
    }
  }

  cancelForm() {
    this.closing(null);
  }

  submit(code: string) {
    const result = checkForm(this.editForm, this.fields, this.options);
    if (!result) return;
    if (result._errorMsg) {
      this.snackService.showError(result._errorMsg);
      return;
    }
    if (code !== 'OK') {
      result._code = code;
    }
    this.closing(result);
  }

  private closing(result: any) {
    this.closeEvent.emit(result);
  }
}
