import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'stats-card-small',
  templateUrl: './stats-card-small.component.html',
  styleUrls: ['./stats-card-small.component.scss']
})
export class StatsCardSmallComponent implements OnInit {

  @Input() cardTitle?: string;
  @Input() prefix?: string;
  @Input() suffix?: string;
  @Input() value?: string;
  @Input() showGraph: boolean;
  @Input() loading: boolean;
  @Input() graphData: any[];
  
  layout = {
    height: 120,
    width: 120,
    showlegend: false,
    margin: {l:5, r:5, t:5, b:5},
    paper_bgcolor: "transparent",
    title: {
      font: {
        size: 100
      },
    },
  };

  config = {
    displayModeBar: false
  };

  constructor() {
    
  }

  ngOnInit(): void {
    // console.log(this.data);
  }
}
