import { Injectable } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { UserEntity } from '../models/user.model';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  private user: UserEntity;

  constructor(
    private analytics: AngularFireAnalytics,
    public userService: UserService,
  ) {
    this.userService.observeUser().subscribe(user => this.onUserChange(user));
  }

  async onUserChange(user: UserEntity) {
    if (!user || user.jarvisml_group) {
      this.user = null;
      return;
    }
    this.user = user;
    
    this.analytics.setUserId(user.id);

    this.analytics.setUserProperties({
      cid: user.cid,
      userRole: user.role
    });
  }

  public logEvent(eventName: string, eventParams: { [key: string]: any }) {
    if (!this.user) return; // user not set or ignored user

    this.analytics.logEvent(eventName, eventParams);
  }

}
