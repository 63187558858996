import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { StudyService } from '../../services/study.service';
import { StudyEntity } from 'src/app/models/study.model';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import { MsgboxService } from 'src/app/services/msgbox.service';

@Component({
  selector: 'study-form-edit',
  templateUrl: './study-form-edit.component.html',
  styleUrls: ['./study-form-edit.component.scss']
})
export class StudyFormEditComponent implements OnInit {

  @Input() studyEntity: StudyEntity;
  @Output() closeEvent = new EventEmitter<StudyEntity>();

  userId: string;
  customerId: string;
  studyForm: UntypedFormGroup;
  studyId: string;
  classes: string[] = null;
  mustSaveClasses = false;

  classesToEdit: string[] = null;

  constructor(
    private studyService: StudyService,
    private userService: UserService,
    private formBuilder: UntypedFormBuilder,
    private msgBoxService: MsgboxService,
    private router: Router
  ) { }

  async ngOnInit() {
    if (this.studyEntity) {
      this.studyId = this.studyEntity.id;
      this.classes = this.studyEntity.classes;
    }
    const asString = this.classes ? this.classes.join(', ') : null;

    this.studyForm = this.formBuilder.group({
      title: [this.studyEntity ? this.studyEntity.title : '', [Validators.required]],
      description: [this.studyEntity ? this.studyEntity.description : '', [Validators.required]],
      classes: [asString, [Validators.nullValidator]]
    });

    const user = await this.userService.getUser();
    if (user) {
      this.userId = user.id;
      this.customerId = user.cid;
    }
  }

  async submitStudy() {
    if (!this.studyForm.valid) {
      return;
    }
    const studyEntity: StudyEntity = {
      title: this.studyEntity ? this.studyEntity.title : '',
      description: this.studyForm && this.studyForm.value ? this.studyForm.value.description : ''
    };
    if (this.mustSaveClasses) {
      studyEntity.classes = this.classes;
      this.studyEntity.classes = this.classes;
    }
    this.studyService.modifyStudy( // we do not wait here
      this.customerId,
      this.studyId,
      studyEntity
    );
    this.studyEntity.title = this.studyForm ? this.studyForm.value.title : '';
    this.studyEntity.description = this.studyForm.value.description;
    this.closeEvent.emit(this.studyEntity);
  }

  cancelForm() {
    this.closeEvent.emit(null);
  }

  editClasses(): void {
    this.classesToEdit = this.classes;
  }
  // NB: classes is null if no change was made
  refreshClasses(classes): void {
    this.classesToEdit = null; // destroys dialog
    if (classes !== null) {
      this.studyForm.patchValue({ classes: classes.join(', ') });
      this.classes = classes;
      this.mustSaveClasses = true;
    }
  }

  async deleteStudy() {
    const choice = await this.msgBoxService.showQuestion('Permanently delete this study?', ['No', 'Yes']);
    if (choice !== 1) return;

    this.studyService.deleteStudy(this.customerId, this.studyId, this.userId);
    // NB: not waiting for confirmation it was deleted - what would we do anyway?

    this.router.navigateByUrl("/studies");
  }
}
