<div class="darkDiv">

<div class="form-frame">
    <div class="form-title">Start new study</div>
    <div class="subtitle">Dataset: {{datasetEntity.title}}</div>
    <div class="form-container">
        <form [formGroup]="studyForm">
        <!--mat-radio-group *ngIf="!type" aria-label="Type" formControlName="type">
          <mat-radio-button class="form-radio" value="Image">Image</mat-radio-button>
          <mat-radio-button class="form-radio" value="Timeseries">Timeseries</mat-radio-button>
        </mat-radio-group-->

        <mat-form-field class="form-field" appearance="outline">
            <mat-label> Title </mat-label>
            <input matInput formControlName="title" required autocomplete="new-password">
            <mat-error *ngIf="studyForm.controls.title.invalid">
              This field is mandatory.
            </mat-error>
        </mat-form-field>
        <mat-form-field class="form-field" appearance="outline">
            <mat-label> Description </mat-label>
            <textarea matInput formControlName="description" required></textarea>
            <mat-error *ngIf="studyForm.controls.description.invalid">
                This field is mandatory.
            </mat-error>
        </mat-form-field>
        <mat-form-field *ngIf="classes" class="form-field" appearance="outline">
          <mat-label> Classes </mat-label>
          <input matInput formControlName="classes" (click)="editClasses()" autocomplete="new-password" [readonly]="true">
          <button mat-icon-button type="button" class="editButton" (click)="editClasses()">
            <mat-icon>more_horiz</mat-icon>
          </button>
        </mat-form-field>
        <mat-checkbox *ngIf="classes" formControlName="isMultilabel">Multilabel</mat-checkbox>

        <div class="submitButtons">
          <button mat-raised-button color="primary" type="button" (click)=submitStudy()>Create</button>
          <button mat-flat-button type="button" (click)=cancelForm()>Cancel</button>
        </div>
      </form>
    </div>
</div>

</div>

<edit-classes *ngIf="classesToEdit"
  [currentClasses]="classesToEdit"
  (editEvent)="refreshClasses($event)">
</edit-classes>
