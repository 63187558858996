import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { TabPrefsEntity, DEFAULT_TABS } from '../admin/tab-preferences/tab-preferences.component';

export const startUrl = '/knowledge-graph';


@Injectable({
  providedIn: 'root'
})
export class CustomerSettingService {

  constructor(
    private firestore: AngularFirestore,
  ) { }

  async getSettings(cid: string, settingId: string): Promise<any> {
    const doc = this.firestore.doc<any>(`Customers/${cid}/Settings/${settingId}`);
    const snapshot = await doc.get().toPromise();
    return snapshot.data() || {};
  }

  async modifySettings(cid: string, settingId: string, subId: string, value: any): Promise<void> {
    const doc = this.firestore.doc<any>(`Customers/${cid}/Settings/${settingId}`);
    const data = { [subId]: value };
    return doc.set(data, { merge: true });
  }

  async getPreferences(cid: string): Promise<any> {
    const doc = this.firestore.doc<any>(`Customers/${cid}/Settings/preferences`);
    const snapshot = await doc.get().toPromise();
    return snapshot.data() || {};
  }

  async modifyPreferences(cid: string, settingsId: string, value: any): Promise<void> {
    const doc = this.firestore.doc<any>(`Customers/${cid}/Settings/${settingsId}`);
    return doc.set(value, { merge: true });
  }

  async modifyTabPreferences(cid: string, preferences: any): Promise<void> {
    const preferencesRef = this.firestore.doc<any>(`Customers/${cid}/Settings/tabPrefs`)
    return preferencesRef.set(preferences, { merge: true });
  }

  async getTabPreferences(cid: string): Promise<TabPrefsEntity> {
    const doc = this.firestore.doc<any>(`Customers/${cid}/Settings/tabPrefs`);
    const snapshot = await doc.get().toPromise();
    return snapshot.data() || DEFAULT_TABS;
  }

}
