<div *ngIf="config">
    <div class="p-4 w-full border-2 mb-4 m text-sm text-blue-800 bg-blue-50 dark:bg-gray-800 dark:text-blue-400 flex justify-center"
        *ngIf="loading">
        <div class="loader"></div>
        <div>Loading Chart for {{config.title}}</div>
    </div>
    <div *ngIf="!loading && errorFetchingData" style="display: flex; justify-content: center; align-items: center;">
        <div class="p-4 w-full mb-4 text-sm text-red-800 bg-red-100 dark:bg-gray-800 dark:text-red-400" role="alert">
            Error Fetching Data for {{config?.title}}
        </div>
    </div>
    <div *ngIf="!loading && !rawData?.length && !errorFetchingData"
        style="display: flex; justify-content: center; align-items: center;">
        <div class="p-4 w-full mb-4 text-sm bg-yellow-200 dark:bg-gray-800 dark:text-yellow-300" role="alert">
            No Data Available for {{config?.title}}
        </div>
    </div>
</div>


<div *ngIf="config">
    <div style="height:450px;">
        <div role="status" *ngIf="loading"
            class="max-w p-4 border border-gray-200 rounded shadow animate-pulse md:p-6 dark:border-gray-700">
            <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32 mb-2.5"></div>
            <div class="w-48 h-2 mb-10 bg-gray-200 rounded-full dark:bg-gray-700"></div>
            <div class="flex items-baseline mt-4 space-x-6">
                <div class="w-full bg-gray-200 rounded-t-lg h-72 dark:bg-gray-700"></div>
                <div class="w-full h-56 bg-gray-200 rounded-t-lg dark:bg-gray-700"></div>
                <div class="w-full bg-gray-200 rounded-t-lg h-72 dark:bg-gray-700"></div>
                <div class="w-full h-64 bg-gray-200 rounded-t-lg dark:bg-gray-700"></div>
                <div class="w-full bg-gray-200 rounded-t-lg h-80 dark:bg-gray-700"></div>
                <div class="w-full bg-gray-200 rounded-t-lg h-72 dark:bg-gray-700"></div>
                <div class="w-full bg-gray-200 rounded-t-lg h-80 dark:bg-gray-700"></div>
            </div>
        </div>
        <div *ngIf="!loading && rawData?.length && !errorFetchingData" class="text-center font-semibold my-3">
            {{config?.title}}
        </div>
        <canvas class="mb-10" [id]="config?.id"></canvas>
    </div>
</div>