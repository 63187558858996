import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UserPreferencesEntity } from '../models/user-preferences.model';

@Injectable({
  providedIn: 'root'
})
export class UserPreferencesService {

  constructor(
    private firestore: AngularFirestore
  ) {
  }

  async getPreferences(userId: string): Promise<UserPreferencesEntity> {
    const doc = this.firestore.doc<UserPreferencesEntity>(`UserPreferences/${userId}`);
    const snapshot = await doc.get().toPromise();
    return { id: snapshot.id, ...snapshot.data() };
  }

  async modifyPreferences(userId: string, userPref: UserPreferencesEntity): Promise<void> {
    const doc = this.firestore.doc(`UserPreferences/${userId}`);
    return doc.update(userPref);
  }

  async getSettings(userId: string, settingId: string): Promise<any> {
    const doc = this.firestore.doc<any>(`UserPreferences/${userId}/Settings/${settingId}`);
    const snapshot = await doc.get().toPromise();
    return snapshot.data() || {};
  }

  async modifySettings(userId: string, settingId: string, subId: string, value: any): Promise<void> {
    const doc = this.firestore.doc<any>(`UserPreferences/${userId}/Settings/${settingId}`);
    const data = {[subId]: value};
    return doc.set(data, { merge: true });
  }

}
