<div *ngIf="filterType=='dateRange'" class="oneFilterBox">
  <form [formGroup]="dateRangeForm">
    <div class="filter-fields">
      <mat-form-field appearance="outline">
        <mat-label> Date range </mat-label>
        <mat-select formControlName="dateRange" required #dateRange (selectionChange)="dateRangeChanged($event)">
          <mat-option *ngFor="let item of dateRangeValues" [value]="item.value">
            {{item.viewValue}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field *ngIf="dateRange.value=='custom'" appearance="outline">
        <mat-label>From</mat-label>
        <input matInput [min]="startDateRange" [max]="endDateRange" [matDatepicker]="startPicker" (dateChange)="startDateChanged($event)">
        <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
        <mat-datepicker #startPicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field *ngIf="dateRange.value=='custom'" appearance="outline">
        <mat-label>To</mat-label>
        <input matInput [min]="startDateRange" [max]="endDateRange" [matDatepicker]="endPicker" (dateChange)="endDateChanged($event)">
        <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
        <mat-datepicker #endPicker></mat-datepicker>
      </mat-form-field>      
    </div>
  </form>
</div>

<div *ngIf="filterType=='pageType'" class="oneFilterBox">
  <form [formGroup]="pageTypeForm">
    <mat-form-field appearance="outline" class="sidenav-form-field">
      <mat-label>Page Type</mat-label>
      <mat-select formControlName="pageType" required #pageType (selectionChange)="pageTypeChanged($event)">
        <mat-option *ngFor="let item of values" [value]="item.value">{{item.viewValue}}</mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</div>

<div *ngIf="filterType=='numberOfDays'" class="oneFilterBox">
  <form [formGroup]="daysSelectionForm">
    <mat-form-field appearance="outline" class="sidenav-form-field">
      <mat-label>Days Selected</mat-label>
      <mat-select formControlName="numberOfDays" required  (selectionChange)="numberOfDaysChanged($event)">
        <mat-option *ngFor="let item of values" [value]="item.value">{{item.viewValue}}</mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</div>

<div *ngIf="filterType=='customerType'" class="oneFilterBox">
  <form [formGroup]="customerTypeForm">
    <mat-form-field appearance="outline" class="sidenav-form-field">
      <mat-label>Selected Customer Type</mat-label>
      <mat-select formControlName="customerType" required  (selectionChange)="customerTypeChanged($event)">
        <mat-option *ngFor="let item of values" [value]="item.value">{{item.viewValue}}</mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</div>

<div *ngIf="filterType=='analyticsVersion'" class="oneFilterBox">
  <form [formGroup]="analyticsVersionForm">
    <mat-form-field appearance="outline" class="sidenav-form-field">
      <mat-label>Analytics Version</mat-label>
      <mat-select formControlName="analyticsVersion" required  (selectionChange)="analyticsVersionChanged($event)">
        <mat-option *ngFor="let item of values" [value]="item.value">{{item.viewValue}}</mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</div>