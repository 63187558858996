import { Injectable } from '@angular/core';
import { GraphConfig } from '../shared/generic-graph/generic-graph.component';

@Injectable({
  providedIn: 'root'
})
export class GraphPickerService {

  pickerData: any;

  constructor() { }

  initRoot(pickerData: any) {
    if (this.pickerData) {
      console.error('dupe graphPicker.initRoot'); // probably not a good thing
    }
    this.pickerData = pickerData;  
  }

  showPicker(config: GraphConfig) {
    this.pickerData.config = config;
  }

  hidePicker() {
    this.pickerData.config = null;
  }

}
