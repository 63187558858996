import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { DeeplinkService } from '../services/deeplink.service';
import { startUrl } from '../services/customer-setting.service';
import { COMPANY_NAME } from '../util/helpers';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {

  // This page is just the "/" root of our site; we just check if user is logged in
  // and redirect appropriately.

  public companyName = COMPANY_NAME;

  constructor(
    public userService: UserService,
    private deeplinkService: DeeplinkService,
  ) {
    this.deeplinkService.parseRoute('/');
  }

  async ngOnInit() {
    try {
      const user = await this.userService.getUser();
      if (user.enabled) {
        this.deeplinkService.navigateToUrl(startUrl);
      } else {
        this.deeplinkService.navigateToUrl('/login');
      }
    } catch(reason) {
      this.deeplinkService.navigateToUrl('/login');
    }
  }
}
