import { ElementRef, Injectable } from '@angular/core';


interface DialogStack {
  name: string;
  focusChild: ElementRef;
  previousFocus: HTMLElement;
}

@Injectable({
  providedIn: 'root'
})
export class KeyboardService {

  private dialogStack: DialogStack[] = [];

  public enterDialog(name: string) {
    this.dialogStack.push({
      name: name,
      previousFocus: document.activeElement as HTMLElement,
      focusChild: null,
    });
  }

  public leaveDialog(name: string) {
    const dialog = this.dialogStack.pop();
    if (dialog?.name !== name) {
      console.error(`leaveDialog(${name} != ${dialog?.name})`);
      return;
    }
    if (dialog.previousFocus instanceof HTMLElement) {
      dialog.previousFocus.focus();
    }
  }

  public setDialogFocus(child: ElementRef) {
    if (!child) return;
    const dialog = this.dialogStack[this.dialogStack.length - 1];
    if (!dialog.focusChild) { // we can be called more than once but only want the first one
      dialog.focusChild = child;
      this.setFocus(child);
    }
  }

  private setFocus(elementRef: ElementRef) {
    // NB: MatCheckBox refuses to listen if we call nativeElement.focus(); it only accepts checkbox.focus()
    // TODO: find a workaround for this issue, or file an issue with Angular?
    elementRef.nativeElement.focus();
  }
}
