import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent {

  @Input() product: any
  public showMore  = false
  starsArray: number[];
  price: string;
  
  constructor() {
    this.starsArray = Array(5).fill(0).map((_, index) => index + 1);
  }

  ngOnInit(): void {
    if (this.product.price && this.product.price.value) {
      this.price = this.product.price.value;
    } else {
      this.price = this.product.price;
    }
  }

  onClickShowMore(){
    this.showMore = !this.showMore
  }

}
