import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss']
})
export class ChipComponent implements OnInit {

  @Input() data: string[];

  constructor() { }

  ngOnInit(): void {
    console.log(this.data);
  }

  removeDataItem(_item) {
    // TODO: Remove item
  }
}
