<div *ngIf="rawData" class="bg-white border-2 rounded-md shadow-lg">
    <div class="p-3 w-full bg-blue-600 text-white text-left rounded-t-md font-bold">{{config.title}}</div>
    <div class="flex flex-wrap gridContainer pt-2 pb-2">
        <div *ngFor="let item of rawData"
            class="flex-row bg-white border border-gray-200 rounded-lg shadow w-64 text-center m-2">
            <a target="_blank" [href]="item.product_url" class="flex justify-center items-center">
                <img class="w-32 h-auto" [src]="item.image_url" alt="" />
            </a>
            <div class="m-2">
                <div>
                    <div class="mb-1 text-xl font-semibold tracking-tight text-gray-900 dark:text-white">
                        {{item.product_name}}</div>
                </div>
                <div class="mb-3 ">Impressions - {{item.impression_count}}</div>
            </div>
        </div>
    </div>
</div>