import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackService {
  constructor(
    private snackBar: MatSnackBar,
  ) {}

  // NB: not good for "critical" errors with complex message
  showError(message: string) {
    const durationMs = 5000 + 50 * message.length;
    this.snackBar.open(message, 'OK', { duration: durationMs, panelClass: 'snackBarPreText' });
  }
}
