import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Observable, from } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BackendApiService } from 'src/app/services/backend-api.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-update-price-modal',
  templateUrl: './update-price-modal.component.html',
})
export class UpdatePriceModalComponent implements OnInit {
  @Input() selectedData: any;
  @Output() closeModalResponse: EventEmitter<any> = new EventEmitter<any>();
  unitData: Observable<any[]>;
  loading = true;
  user

  showAlert = false;
  alertMessage = '';
  alertType: 'success' | 'failure' = 'success'; 

  constructor(
    private backendApi: BackendApiService,
    private userService: UserService,) { }

  async ngOnInit(): Promise<void> {
    this.user = await this.userService.getUser();
    this.fetchData(); // Fetch data when the modal is opened
  }


  fetchData() {
    this.unitData = this.getAllPrices({ "unit_id": this.selectedData?.unit_id });
    this.unitData.subscribe(
      (_response) => {
        this.loading = false;
      },
      (error) => {
        console.error('Error:', error);
        this.loading = false; // Also hide the loader in case of error
      }
    );
  }

  getAllPrices(body): Observable<any[]> {
    return from(this.backendApi.get(`/hospitality/rtp_override/unit_id_current_rtp?cid=B002&&unit_id=${body.unit_id}`, environment.newBackendAPI));
  }

  savePrice() {
    this.closeModalResponse.emit(); // Emit an event to notify the parent component to close the modal
  }

  closeModal(){
    this.closeModalResponse.emit(); 
  }

  async updateSuggestedPrice(body) {
    try {
      await this.backendApi.post(`/hospitality/rtp_override/current_rtp_response?cid=B002`, body, environment.newBackendAPI);
      this.showAlertMessage('Success: Suggested Price updated successfully!', 'success');
    } catch (error) {
      console.error('Error updating price:', error);
      this.showAlertMessage('Error Updating Suggested Price.', 'failure');
    }
  }

  showAlertMessage(message: string, type: 'success' | 'failure') {
    this.alertMessage = message;
    this.alertType = type;
    this.showAlert = true;
    setTimeout(() => {
      this.showAlert = false;
      window.location.reload()
    }, 3000); // Hide the alert after 3seconds
  }

  
  updateTwiddyPrice(data: any) {
    const bodyResponse = {
      ...data,
      user_id: this.user.email,
      updated_at: new Date
    }

    this.updateSuggestedPrice(bodyResponse)

  }
}