import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DeeplinkService } from '../services/deeplink.service';
import { startUrl } from '../services/customer-setting.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotFoundComponent {
  constructor(
    private deeplinkService: DeeplinkService,
  ) {
  }

  public gotoTopPage() {
    this.deeplinkService.navigateToUrl(startUrl);
  }
}
