import { Component, OnInit, Input } from '@angular/core';
import { FeedbackService } from 'src/app/services/feedback.service';

@Component({
  selector: 'feedback-button',
  templateUrl: './feedback-button.component.html',
  styleUrls: ['./feedback-button.component.scss']
})
export class FeedbackButtonComponent implements OnInit {

  @Input() interfaceId: string;

  constructor(
    private feedbackService: FeedbackService,
  ) {
  }

  ngOnInit(): void {
  }

  async showFeedbackForm() {
    return this.feedbackService.showFeedbackForm(this.interfaceId);
  }
}
