<div *ngIf="def && def.condition()" class="menuAction">
  <ng-container *ngIf="!def.link && !def.action">
    <a>{{def.label}}</a>
    <div *ngIf="isResponsive" class="expandIcon">
      <mat-icon *ngIf="!isDeployed">keyboard_arrow_down</mat-icon>
      <mat-icon *ngIf="isDeployed">keyboard_arrow_up</mat-icon>
    </div>
  </ng-container>
  <ng-container *ngIf="def.link">
    <a routerLink="{{def.link}}" (click)="navigate(def.link)" routerLinkActive="active-tab">{{def.label}}</a>
  </ng-container>
  <ng-container *ngIf="def.action">
    <a (click)="def.action()">{{def.label}}</a>
  </ng-container>
</div>
